"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivacyPolicySetting = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const PrivacyPolicySetting = () => {
    const { __ } = (0, core_1.useIntl)();
    const { navigate } = (0, core_1.useNavigation)();
    const goToPrivacyPolicy = (0, react_1.useCallback)(() => {
        navigate('PrivacyPolicy');
    }, [navigate]);
    return (react_1.default.createElement(components_1.List.Item, { left: react_1.default.createElement(components_1.List.Icon, { name: "shield-check" }), title: __('Privacy Policy'), onPress: goToPrivacyPolicy }));
};
exports.PrivacyPolicySetting = PrivacyPolicySetting;
