"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ur = void 0;
const ur = (messages) => (Object.assign(Object.assign({}, messages), { Allow: 'اجازت دیں', 
    // tslint:disable-next-line: max-line-length
    'A network error occurred.This may be because of your network connection, or a server error.Please try again later.': `ایک نیٹ ورک میں خامی پیش آگئی۔ یہ آپ کے نیٹ ورک کنکشن ، یا سرور کی خرابی کی وجہ سے ہوسکتی ہے۔
براہ کرم کچھ دیر بعد کوشش کریں.`, Camera: 'کیمرہ۔', 'Camera Access': 'کیمرے تک رسائی', Cancel: 'منسوخ کریں', Deny: 'انکار کریں۔', 'Do you want to grant access of this resource to this app?': 'کیا آپ اس ایپ تک اس وسائل تک رسائی دینا چاہتے ہیں؟', 'Enter location coordinates to in the fields below.': 'ذیل کے شعبوں میں مقام کے نقاط کو داخل کریں۔', Forbidden: 'ممنوعہ', Gallery: 'گیلری۔', 'Grant Access': 'رسائی کی اجازت دیں۔', 'Input Location Coordinates': 'ان پٹ لوکیشن کوآرڈینیٹ۔', Latitude: 'طول', 'Load More': 'مزید لوڈ کریں', Loading: 'لوڈ ہو رہا ہے۔', 'Locate Me': 'مجھے تلاش کریں۔', Location: 'مقام۔', 'Location Access': 'مقام تک رسائی۔', Longitude: 'طول البلد', 'Look, we need access to your Camera.': 'دیکھو ، ہمیں آپ کے کیمرے تک رسائی کی ضرورت ہے۔', 'Look, we need access to your location so we can spy on you...kidding!': 'دیکھو ، ہمیں آپ کے مقام تک رسائی کی ضرورت ہے تاکہ ہم آپ کی جاسوسی کرسکیں ... مذاق!', 'Looks like we do not have permission to access this resource.': 'ایسا لگتا ہے کہ ہمارے پاس اس وسائل تک رسائی کی اجازت نہیں ہے۔', 'Looks like we do not have permission to access your camera. It is ok! We are not offended, but make it right, ok?': 'ایسا لگتا ہے کہ ہمارے پاس آپ کے کیمرا تک رسائی کی اجازت نہیں ہے۔' +
        'ٹھیک ہے! ہم ناراض نہیں ہیں ، لیکن اسے ٹھیک کردیں ، ٹھیک ہے؟', 'Looks like we do not have permission to access your location. It is ok! We are not offended, but make it right, ok?': 'ایسا لگتا ہے کہ ہمارے پاس آپ کے مقام تک رسائی کی اجازت نہیں ہے۔' +
        'ٹھیک ہے! ہم ناراض نہیں ہیں ، لیکن اسے ٹھیک کردیں ، ٹھیک ہے؟', 'Network Error': 'نیٹ ورک کی خرابی۔', Permission: 'اجازت۔', 'Permission Required': 'اجازت ضروری ہے۔', Refresh: 'ریفریش', Retry: 'دوبارہ کوشش کریں۔', Save: 'محفوظ کریں', 'Select Permission': 'اجازت منتخب کریں۔', 'Select from Camera': 'کیمرہ سے منتخب کریں۔', 'Select from Library': 'لائبریری سے منتخب کریں۔', 'This app needs camera permission': 'اس ایپ کو کیمرے کی اجازت درکار ہے۔', 'This app needs permission to access your Gallery': 'اس ایپ کو آپ کی گیلری تک رسائی حاصل کرنے کے لئے اجازت درکار ہے۔', Unauthenticated: 'غیر تصدیق شدہ', 'We need access permission to proceed': 'ہمیں آگے بڑھنے کے لئے رسائی کی ضرورت ہے۔', 'We need the permission to access your Camera': 'ہمیں آپ کی کیمرے تک پہنچنے کی اجازت کی ضرورت ہے', 'We need the permission to access your Location': 'میں آپ کے مقام تک رسائی کے ل. اجازت کی ضرورت ہے۔', 'We need your permission to access this resource': 'اس وسیلہ تک رسائی کے ل ہمیں آپ کی اجازت درکار ہے۔', 'You do not have the permission to access this resource': 'آپ کو اس وسائل تک رسائی کی اجازت نہیں ہے۔', 'You must be logged in to access this resource': 'اس وسیلہ تک رسائی حاصل کرنے کے ل آپ کو لاگ ان کرنا ہوگا', submit: 'جمع' }));
exports.ur = ur;
exports.default = exports.ur;
