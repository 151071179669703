"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAttributeGetter = void 0;
const core_1 = require("@bluebase/core");
const device_controller_1 = require("@mevris/device-controller");
const useDevice_1 = require("../useDevice");
function useAttributeGetter() {
    const BB = (0, core_1.useBlueBase)();
    const { device, id } = (0, useDevice_1.useDevice)();
    async function getAttributes(options) {
        if (!device) {
            const result = {
                status: device_controller_1.RESPONSE_STATUS_ERROR,
                error: new Error('Could not get attribute. Reason: No device found.'),
            };
            BB.Logger.error(result);
            return result;
        }
        try {
            const attribute = await device.requestAttributes(options);
            return attribute;
        }
        catch (error) {
            BB.Logger.error('Device getAttributes error', {
                error,
                id,
            });
            return {
                status: device_controller_1.RESPONSE_STATUS_ERROR,
                error,
            };
        }
    }
    return getAttributes;
}
exports.useAttributeGetter = useAttributeGetter;
