"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataItemValueForm = void 0;
const tslib_1 = require("tslib");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const helpers_1 = require("../../../helpers");
const JsonForm = (0, core_1.getComponent)('JsonForm');
const DataItemValueForm = (props) => {
    const { items = [], EmptyState } = props;
    const stateFields = items
        // Convert data items into form fields
        .map(s => (0, helpers_1.getDataItemFormFieldProps)(s))
        // Remove any nil values
        .filter(x => x !== undefined)
        // Map all field required to true
        .map(x => (Object.assign(Object.assign({}, x), { required: true })));
    // Generate initial values
    const initialValues = {};
    items.forEach(s => { initialValues[s.key] = s.value; });
    // On Change Callback
    const onChange = (0, react_1.useCallback)((current) => {
        const flattened = (0, helpers_1.flattenObject)(current.values);
        if (props.onChange) {
            props.onChange(flattened);
        }
    }, [props.onChange]);
    // On Submit Callback
    const onSubmit = (0, react_1.useCallback)((values, helpers) => {
        const flattened = (0, helpers_1.flattenObject)(values);
        if (props.onSubmit) {
            props.onSubmit(flattened, helpers);
        }
    }, [props.onSubmit]);
    if (stateFields.length === 0) {
        return EmptyState ? react_1.default.createElement(EmptyState, null) : null;
    }
    return (react_1.default.createElement(JsonForm, { schema: {
            validateOnBlur: false,
            validateOnChange: false,
            initialValues: (0, helpers_1.unflattenObject)(initialValues),
            onSubmit: onSubmit,
            onChange: onChange,
            fields: [
                {
                    name: 'states',
                    type: 'group',
                    label: 'States',
                    dividers: true,
                    fields: stateFields,
                },
                props.onSubmit ?
                    {
                        direction: 'right',
                        name: 'form-actions2',
                        type: 'inline',
                        fields: [
                            {
                                name: 'submit',
                                type: 'submit',
                                title: 'Save',
                                color: 'success',
                                icon: { type: 'icon', name: 'content-save' },
                            },
                        ],
                    }
                    : undefined,
            ],
        } }));
};
exports.DataItemValueForm = DataItemValueForm;
exports.DataItemValueForm.defaultProps = {};
exports.DataItemValueForm.displayName = 'DataItemValueForm';
