"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformAndValidate = void 0;
const exceptions_1 = require("./exceptions");
const transform_1 = require("./transform");
const validate_1 = require("./validate");
/**
 * Transform and validate a value
 * @param value
 * @param schema
 */
function transformAndValidate(value, schema) {
    const transformedValue = (0, transform_1.transform)(value, schema);
    const isValid = (0, validate_1.validate)(transformedValue, schema);
    if (!isValid) {
        throw new exceptions_1.ValidationError('Invalid value against DataSchema.');
    }
    else {
        return transformedValue;
    }
}
exports.transformAndValidate = transformAndValidate;
