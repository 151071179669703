"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeviceAttributes = void 0;
const tslib_1 = require("tslib");
const client_1 = require("@apollo/client");
const client_graphql_1 = require("@mevris/client-graphql");
const useDevice_1 = require("../useDevice");
const useAttributeGetter_1 = require("./useAttributeGetter");
const useAttributeSetter_1 = require("./useAttributeSetter");
/**
 * Query Device Attribute from cache.
 * We'll let device controllers manage real time attribute through
 * their own subcription/polling mechanism.
 */
const useDeviceAttributes = () => {
    var _a;
    const { id, entity } = (0, useDevice_1.useDevice)();
    // Run Graphql query
    const _b = (0, client_1.useQuery)(client_graphql_1.EntityQuery, {
        fetchPolicy: 'cache-first',
        variables: { id },
    }), { loading, error, data } = _b, rest = tslib_1.__rest(_b, ["loading", "error", "data"]);
    const setAttributes = (0, useAttributeSetter_1.useAttributeSetter)();
    const getAttributes = (0, useAttributeGetter_1.useAttributeGetter)();
    const attributesMap = ((_a = data === null || data === void 0 ? void 0 : data.entity) === null || _a === void 0 ? void 0 : _a.attributesMap) || {};
    return Object.assign({ id,
        entity,
        attributesMap,
        setAttributes,
        getAttributes,
        error,
        loading }, rest);
};
exports.useDeviceAttributes = useDeviceAttributes;
