"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMimeTypeFromFilePath = exports.getMimeTypeFromBase64Uri = exports.fetchImage = void 0;
async function fetchImage(uri, mimeType) {
    return await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            const blob = new Blob([xhr.response], {
                type: mimeType,
                name: `${Date.now()}.${mimeType === null || mimeType === void 0 ? void 0 : mimeType.split('/')[1]}`
            });
            resolve(blob);
            // resolve(xhr.response);
        };
        xhr.onerror = function (_e) {
            reject(new TypeError('Network request failed'));
        };
        xhr.responseType = 'blob';
        xhr.open('GET', uri, true);
        xhr.send(null);
    });
}
exports.fetchImage = fetchImage;
function getMimeTypeFromBase64Uri(dataUri) {
    const regex = /^data:(.+?);base64,/;
    const match = dataUri.match(regex);
    return match ? match[1] : undefined;
}
exports.getMimeTypeFromBase64Uri = getMimeTypeFromBase64Uri;
function getMimeTypeFromFilePath(filePath) {
    var _a;
    const extensionToMimeTypeMap = {
        'png': 'image/png',
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'gif': 'image/gif',
        // Add more mappings as needed
    };
    const extension = (_a = filePath.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    return extension ? extensionToMimeTypeMap[extension] : undefined;
}
exports.getMimeTypeFromFilePath = getMimeTypeFromFilePath;
