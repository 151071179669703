"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorControlUIView = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const parts_1 = require("../../parts");
const defaultStyles = (theme) => ({
    root: {
        flex: 1,
    },
    hueSliderContainer: {
        justifyContent: 'center',
        flex: 1,
        padding: theme.spacing.unit * 2,
    },
    switchContainer: {
        alignSelf: 'center',
        position: 'absolute',
    },
    saturationSliderContainer: {
        height: 40,
    },
    quickActionListContainer: {
        alignContent: 'center',
    },
});
function useTempSliderValue(item) {
    const [tempValue, setTempValue] = (0, react_1.useState)(item.value);
    const [sliding, setSliding] = (0, react_1.useState)(false);
    const onSlidingStart = (0, react_1.useCallback)(() => setSliding(true), [sliding, setSliding]);
    const onSlidingComplete = (0, react_1.useCallback)(() => setSliding(false), [sliding, setSliding]);
    const value = sliding ? tempValue : item.value;
    return { value, setTempValue, onSlidingStart, onSlidingComplete };
}
const ColorControlUIView = (props) => {
    var _a, _b;
    const { styles: _s, style, data, onStateChange, width } = props, rest = tslib_1.__rest(props, ["styles", "style", "data", "onStateChange", "width"]);
    const styles = (0, core_1.useStyles)('ColorControlUIView', props, defaultStyles);
    const on = data.on.props || {};
    const onValueChange = (dataItem) => (val) => onStateChange({ [dataItem.key]: val });
    const onValue = on.value;
    // Hue value
    const hue = (((_a = data['color.hue']) === null || _a === void 0 ? void 0 : _a.props) || {});
    const { onSlidingComplete: hueSlidingComplete, onSlidingStart: hueSlidingStart, setTempValue: setHueTempValue, value: hueValue, } = useTempSliderValue(hue);
    // Saturation value
    const saturation = (((_b = data['color.saturation']) === null || _b === void 0 ? void 0 : _b.props) || {});
    const { onSlidingComplete: saturationSlidingComplete, onSlidingStart: saturationSlidingStart, setTempValue: setSaturationTempValue, value: saturationValue, } = useTempSliderValue(saturation);
    return (react_1.default.createElement(components_1.View, Object.assign({}, rest, { style: [styles.root, style] }),
        react_1.default.createElement(components_1.View, { style: [styles.hueSliderContainer, width ? { width, height: width } : {}], testID: "slider-container" },
            onValue ? (react_1.default.createElement(parts_1.SliderManager, { Slider: parts_1.HueCircularSlider, data: hue, onValueChange: onValueChange(hue), onValueChangeInternal: setHueTempValue, onSlidingStart: hueSlidingStart, onSlidingComplete: hueSlidingComplete, startAngle: 0, endAngle: 360, trackWidth: 20 })) : null,
            react_1.default.createElement(components_1.View, { style: styles.switchContainer },
                react_1.default.createElement(parts_1.CircularSwitchButton, { value: on.value, onValueChange: onValueChange(on), size: width * 0.3, onColor: `hsl(${hueValue}, ${saturationValue * 100}%, 50%)` }))),
        react_1.default.createElement(components_1.View, { style: styles.saturationSliderContainer }, onValue ? (react_1.default.createElement(parts_1.HorizontalSliderWithIcon, { data: saturation, onValueChange: onValueChange(saturation), icon: "gradient", onValueChangeInternal: setSaturationTempValue, onSlidingStart: saturationSlidingStart, onSlidingComplete: saturationSlidingComplete })) : null)));
};
exports.ColorControlUIView = ColorControlUIView;
exports.ColorControlUIView.displayName = 'ColorControlUIView';
exports.ColorControlUIView.defaultProps = {};
