"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ur = void 0;
const ur = (messages) => (Object.assign(Object.assign({}, messages), { BlueBase: 'بلیو بیس', 
    // /////////////
    // Components //
    // /////////////
    // PrivacyPolicy
    'Please add privacy Policies here': 'براہ کرم یہاں رازداری کی پالیسیاں شامل کریں۔', 
    // TermsOfService
    'Please add terms and conditions here': 'براہ کرم یہاں شرائط و ضوابط شامل کریں۔', 
    // ///////////
    // Settings //
    // ///////////
    Settings: 'ترتیبات', About: 'بمتعلق', General: 'جنرل', Appearance: 'ظہور', 'All your theme related settings reside here.': 'تمام تھیمز کے بارے میں ترتیبات یہاں ہیں', 'Change your language settings here': 'یہاں اپنی زبان کی ترتیبات کو تبدیل کریں', Information: 'معلومات', Legal: 'قانونی', Support: 'حمایت', 
    // CallSupportSetting
    Call: 'کال کریں۔', 'Call Now': 'ابھی کال کریں۔', Closed: 'بند', 
    // DarkModeSetting
    Mode: 'موڈ', Dark: 'ڈارک', Light: 'لائٹ', 'Dark Mode': 'ڈارک موڈ', 'Light Mode': 'لائٹ موڈ', 
    // DeveloperSetting
    'Developed by': 'تیار کردہ', 'BlueEast (Pvt.) Ltd.': 'بلیو ایسٹ پرائیویٹ لمیٹڈ', 
    // EmailSupportSetting
    Email: 'ای میل', 
    // LanguageSetting
    Language: 'زبان', 
    // PrivacyPolicySetting
    'Privacy Policy': 'رازداری کی پالیسی', 
    // TermsOfServiceSetting
    'Terms of Service': 'سروس کی شرائط', 
    // TextDirectionSetting
    'Text Direction': 'سمت ٕ متن', Auto: 'آٹو', 'Text Direction will automatically changed based on selected language.': ' متن کی سمت منتخب کردہ زبان کی نویت سے خودبخودتبدیل ہوتی رہے گی', 'Left to Right': 'بائیں سے دائیں', 'Text will be displayed from Left to Right': 'متن بائیں سے دائیں سمت میں دکھایا جائے گا', 'Right to Left': 'دائیں سے بائیں', 'Text will be displayed from Right to Left': 'متن دائیں سے بائیں سمت میں دکھایا جائے گا', 
    // ThemeSelectionSetting
    Themes: 'تھیمز', 'Available Themes': 'موجودہ تھیمز', 'BlueBase Theme': 'بلیو بیس تھیم', 
    // VersionSetting
    Version: 'ورژن' }));
exports.ur = ur;
exports.default = exports.ur;
