"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unflattenObject = exports.flattenObject = exports.ReturnTrue = exports.ReturnFalse = exports.noop = void 0;
const noop = () => { };
exports.noop = noop;
const ReturnFalse = () => false;
exports.ReturnFalse = ReturnFalse;
const ReturnTrue = () => true;
exports.ReturnTrue = ReturnTrue;
// A function to flatten object
function flattenObject(ob) {
    const toReturn = {};
    for (const i in ob) {
        // eslint-disable-next-line no-prototype-builtins
        if (!ob.hasOwnProperty(i))
            continue;
        if (typeof ob[i] === 'object' && ob[i] !== null) {
            const flatObject = flattenObject(ob[i]);
            for (const x in flatObject) {
                // eslint-disable-next-line no-prototype-builtins
                if (!flatObject.hasOwnProperty(x))
                    continue;
                toReturn[`${i}.${x}`] = flatObject[x];
            }
        }
        else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}
exports.flattenObject = flattenObject;
function unflattenObject(data) {
    if (Object(data) !== data || Array.isArray(data))
        return data;
    // eslint-disable-next-line no-useless-escape
    const regex = /\.?([^.\[\]]+)|\[(\d+)\]/g, resultholder = {};
    for (const p in data) {
        let cur = resultholder, prop = '', m;
        // eslint-disable-next-line no-cond-assign
        while ((m = regex.exec(p))) {
            cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
            prop = m[2] || m[1];
        }
        cur[prop] = data[p];
    }
    return resultholder[''] || resultholder;
}
exports.unflattenObject = unflattenObject;
