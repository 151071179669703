"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateStore = void 0;
const client_graphql_1 = require("@mevris/client-graphql");
/**
 * When we get a new state from device controller, we persist it to the apollo store,
 * as state may be a result of non graphql susbcriptions
 * @param options
 */
function updateStore(options) {
    var _a, _b;
    const { apollo, id, stateMap, attributesMap, BB } = options;
    try {
        const data = apollo.readQuery({ query: client_graphql_1.EntityQuery, variables: { id } });
        const newData = Object.assign(Object.assign({}, data), { entity: Object.assign(Object.assign({ id }, data === null || data === void 0 ? void 0 : data.entity), { stateMap: Object.assign(Object.assign({}, (_a = data === null || data === void 0 ? void 0 : data.entity) === null || _a === void 0 ? void 0 : _a.stateMap), stateMap), attributesMap: Object.assign(Object.assign({}, (_b = data === null || data === void 0 ? void 0 : data.entity) === null || _b === void 0 ? void 0 : _b.attributesMap), attributesMap) }) });
        // console.info('[DeviceBuilder] Updating store with new state', newData);
        apollo.writeQuery({
            data: newData,
            query: client_graphql_1.EntityQuery,
            variables: { id },
        });
    }
    catch (error) {
        BB.Logger.error(`Error: Could not update apollo store with state of entity with ID: ${id}.`);
    }
}
exports.updateStore = updateStore;
