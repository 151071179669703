"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSettingsRoutes = void 0;
const core_1 = require("@bluebase/core");
const createDesktopRoutes_1 = require("./createDesktopRoutes");
const createMobileRoutes_1 = require("./createMobileRoutes");
const createSettingsRoutes = ({ mainRoute, pages, filter = 'bluebase.plugin.setting-app', }) => {
    return (0, core_1.isMobile)()
        ? (0, createMobileRoutes_1.createMobileRoutes)({ mainRoute, pages, filter })
        : (0, createDesktopRoutes_1.createDesktopRoutes)({ mainRoute, pages, filter });
};
exports.createSettingsRoutes = createSettingsRoutes;
