"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pages = void 0;
exports.pages = [
    {
        name: 'GeneralSettingsPage',
        path: 'general',
        options: {
            drawerIcon: { type: 'icon', name: 'cog' },
            title: 'General',
        },
        items: [
            {
                component: 'AppearanceSettingList',
                description: 'All your theme related settings reside here.',
                name: 'appearance',
                title: 'Appearance',
            },
            {
                component: 'LanguageSettingList',
                description: 'Change your language settings here',
                name: 'language',
                title: 'Language',
            },
        ],
    },
    {
        name: 'AboutSettingsPage',
        path: 'about',
        options: {
            drawerIcon: { type: 'icon', name: 'information' },
            title: 'About',
        },
        items: [
            {
                component: 'SupportSettingList',
                name: 'support',
                title: 'Support',
            },
            {
                component: 'InformationSettingList',
                name: 'information',
                title: 'Information',
            },
            {
                component: 'LegalSettingList',
                name: 'legal',
                title: 'Legal',
            },
        ],
    },
];
