"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityProductQuery = void 0;
const client_1 = require("@apollo/client");
exports.EntityProductQuery = client_1.gql `
	query EntityProductQuery($id: ID!, $avatarSize: Int) {
		entity(id: $id) {
			id
			secondaryId
			name

			product {
				id
				handle
				name
				avatar(width: $avatarSize, height: $avatarSize)
				thumbnail: avatar(width: 5, height: 5)

				brand {
					id
					handle
					name
				}
			}
		}
	}
`;
