"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Track = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const react_native_svg_1 = tslib_1.__importStar(require("react-native-svg"));
const circularGeometry_1 = require("./circularGeometry");
const svgPaths_1 = require("./svgPaths");
exports.Track = (0, react_1.memo)((props) => {
    const { size, width, height, startAngle, endAngle, direction, axis, maximumTrackTintColor, trackWidth, trackProps, padding, onPress, } = props;
    return (react_1.default.createElement(react_native_1.TouchableWithoutFeedback, { onPressIn: onPress },
        react_1.default.createElement(react_native_svg_1.default, { width: width || size, height: height || size },
            react_1.default.createElement(react_native_svg_1.Path, Object.assign({ d: (0, svgPaths_1.createArcPath)({
                    axis,
                    direction,
                    endAngle,
                    innerRadius: (0, circularGeometry_1.innerRadius)({ padding, size, trackWidth }),
                    startAngle,
                    svgSize: size,
                    thickness: trackWidth,
                }), fill: "transparent", stroke: maximumTrackTintColor, strokeWidth: trackWidth }, trackProps)))));
});
exports.Track.displayName = 'Track';
