"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeUndefinedProperties = void 0;
function removeUndefinedProperties(inputProps) {
    const filteredProps = Object.keys(inputProps).reduce((acc, key) => {
        if (inputProps[key] !== undefined) {
            acc[key] = inputProps[key];
        }
        return acc;
    }, {});
    return filteredProps;
}
exports.removeUndefinedProperties = removeUndefinedProperties;
