"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CircularSwitchButton = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const sounds_1 = require("../../../sounds");
const defaultStyles = (theme, props) => ({
    root: Object.assign({ overflow: 'hidden', alignItems: 'stretch', borderColor: theme.palette.background.card, borderRadius: props.size / 2, borderWidth: theme.spacing.unit / 2, flexDirection: 'column', justifyContent: 'center', height: props.size, width: props.size }, theme.elevation(4)),
    button: {
        alignItems: 'center',
        backgroundColor: props.value ? props.onColor : props.offColor,
        borderRadius: props.size / 2,
        justifyContent: 'center',
        flexGrow: 1,
    },
});
const CircularSwitchButton = (props) => {
    const styles = (0, core_1.useStyles)('Switch', props, defaultStyles);
    const playSwitchSound = (0, sounds_1.useSwitchSound)();
    const { onValueChange, value, onIcon, offIcon } = props;
    const icon = value ? onIcon : offIcon;
    const onPressHandler = (0, react_1.useCallback)(() => {
        playSwitchSound(!value);
        onValueChange(!value);
    }, [onValueChange, value]);
    return (react_1.default.createElement(components_1.View, { style: styles.root },
        react_1.default.createElement(react_native_1.Pressable, { onPress: onPressHandler, style: styles.button },
            react_1.default.createElement(components_1.Icon, { name: icon, size: 36, color: "white" }))));
};
exports.CircularSwitchButton = CircularSwitchButton;
exports.CircularSwitchButton.defaultProps = {
    size: 100,
    onIcon: 'power',
    offIcon: 'power',
    onColor: '#52D938',
    offColor: '#919EAB',
};
