"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionSheetDesktop = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const ActionSheetDesktop = (props) => {
    const { theme } = (0, core_1.useTheme)();
    const { options, title, message, callback, destructiveButtonIndex, titleTextStyle, messageTextStyle, showSeparators, separatorStyle, textStyle, tintColor, icons = [], visible, onDismiss, dismissable, } = props;
    function createButton(option, index) {
        const cb = (0, react_1.useCallback)(() => callback(index), []);
        const color = destructiveButtonIndex === index ? theme.palette.error.main : tintColor;
        return (react_1.default.createElement(components_1.List.Item, { key: `${option}-${index}`, title: react_1.default.createElement(components_1.Body1, { style: Object.assign({ color }, textStyle) }, option), onPress: cb, left: icons[index] }));
    }
    function renderOptions() {
        let buttons = options.map((option, index) => createButton(option, index));
        if (showSeparators) {
            buttons = buttons.map((btn, index) => (react_1.default.createElement(react_1.default.Fragment, { key: index },
                react_1.default.createElement(components_1.Divider, { style: separatorStyle }),
                btn)));
        }
        return buttons;
    }
    return (react_1.default.createElement(components_1.Dialog, { visible: visible, onDismiss: onDismiss, dismissable: dismissable },
        title ? (react_1.default.createElement(components_1.H6, { style: Object.assign({ color: theme.palette.text.secondary, padding: theme.spacing.unit * 2 }, titleTextStyle) }, title)) : null,
        message ? (react_1.default.createElement(components_1.Body2, { style: Object.assign({ color: theme.palette.text.secondary, paddingBottom: theme.spacing.unit * 2, paddingHorizontal: theme.spacing.unit * 2 }, messageTextStyle) }, message)) : null,
        renderOptions()));
};
exports.ActionSheetDesktop = ActionSheetDesktop;
exports.ActionSheetDesktop.displayName = 'ActionSheetDesktop';
