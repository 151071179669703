"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNumberInRange = void 0;
function getNumberInRange(value, min, max) {
    if (min !== undefined && value < min) {
        return min;
    }
    if (max !== undefined && value > max) {
        return max;
    }
    return value;
}
exports.getNumberInRange = getNumberInRange;
