"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeviceSyncOnAppStateUpdates = void 0;
const react_1 = require("react");
const react_native_1 = require("react-native");
/**
 * Updates device state whenever app becomes active from inactive or background state
 * @param device
 */
function useDeviceSyncOnAppStateUpdates(device) {
    const appState = (0, react_1.useRef)(react_native_1.AppState.currentState);
    const [, setAppStateVisible] = (0, react_1.useState)(appState.current);
    (0, react_1.useEffect)(() => {
        const subscription = react_native_1.AppState.addEventListener('change', _handleAppStateChange);
        return () => {
            subscription.remove();
        };
    }, []);
    const _handleAppStateChange = (nextAppState) => {
        if (appState.current.match(/inactive|background/) && nextAppState === 'active' && device) {
            // App has come to the foreground!
            device.requestState();
        }
        appState.current = nextAppState;
        setAppStateVisible(appState.current);
    };
}
exports.useDeviceSyncOnAppStateUpdates = useDeviceSyncOnAppStateUpdates;
