"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwitchControlItem = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const sounds_1 = require("../../../sounds");
const SwitchControlItem = (props) => {
    const { title, value, style } = props;
    const { theme } = (0, core_1.useTheme)();
    const playSwitchSound = (0, sounds_1.useSwitchSound)();
    const checked = value === true;
    const onValueChange = (0, react_1.useCallback)(() => {
        playSwitchSound(!checked);
        props.onValueChange(!checked);
    }, [checked, props.onValueChange]);
    return (react_1.default.createElement(components_1.View, { style: Object.assign({ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: theme.spacing.unit * 2, paddingVertical: theme.spacing.unit }, style) },
        react_1.default.createElement(components_1.Body1, { style: { color: theme.palette.text.secondary } }, title),
        react_1.default.createElement(components_1.Switch, { checked: value === true, onValueChange: onValueChange })));
};
exports.SwitchControlItem = SwitchControlItem;
exports.SwitchControlItem.displayName = 'SwitchControlItem';
