"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PositiveInteger = void 0;
const types_1 = require("../types");
exports.PositiveInteger = {
    key: 'PositiveInteger',
    extends: 'Number',
    precision: 0,
    validators: [
        {
            type: types_1.DataSchemaValidatorType.RULE,
            value: {
                if: [{ '<': [{ var: 'value' }, 0] }, false, true],
            },
        },
    ],
};
