"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceTypeAvatarSetting = void 0;
const tslib_1 = require("tslib");
const client_1 = require("@apollo/client");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const client_plugin_ui_1 = require("@blueeast/client-plugin-ui");
const client_graphql_1 = require("@mevris/client-graphql");
const expo_image_picker_1 = require("expo-image-picker");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const imports_1 = require("../../imports");
const AvatarSetting_1 = require("../AvatarSetting");
const imagePickerOptions = {
    allowsEditing: false,
    allowsMultipleSelection: false,
    aspect: [1, 1],
    base64: true,
    exif: false,
    mediaTypes: expo_image_picker_1.MediaTypeOptions.Images,
    quality: 0.8,
};
const LoadingComponent = () => {
    return react_1.default.createElement(imports_1.PlaceholderListItem, { avatar: true, description: false });
};
const DeviceTypeAvatarSetting = (_a) => {
    var { deviceTypeId } = _a, rest = tslib_1.__rest(_a, ["deviceTypeId"]);
    const { alert } = (0, client_plugin_ui_1.useAlert)();
    // *********** //
    // * Graphql * //
    // *********** //
    const { data, error, loading } = (0, client_1.useQuery)(client_graphql_1.DeviceTypeQuery, {
        variables: { id: deviceTypeId },
    });
    const [upload, { 
    // error: mutationError,
    loading: mutationLoading }] = (0, client_1.useMutation)(client_graphql_1.DeviceTypeUploadAvatarMutation);
    // ************* //
    // * Variables * //
    // ************* //
    const uri = data === null || data === void 0 ? void 0 : data.deviceType.avatar;
    const source = uri ? { uri } : 'ThingPlaceholder';
    const BB = (0, core_1.useBlueBase)();
    const showImagePicker = (0, AvatarSetting_1.useImagePicker)();
    const [customSource, setCustomSource] = (0, react_1.useState)(source);
    const [isCustomSource, setIsCustomSource] = (0, react_1.useState)(false);
    // ****************** //
    // * Event Handlers * //
    // ****************** //
    const onError = (0, react_1.useCallback)((error) => {
        setIsCustomSource(false);
        alert('Error', 'An error occured while uploading image, please try again later.');
        BB.Logger.error('Error while uploading Image', error);
    }, []);
    const onSelectImage = (0, react_1.useCallback)(async (result) => {
        var _a;
        if (result.canceled === true) {
            return;
        }
        const file = result.assets[0];
        // const uri = file.uri;
        const uri = react_native_1.Platform.OS === 'web'
            ? file.uri
            : `data:image/jpeg;base64,${result.assets[0].base64}`; // Seems compressed
        let mimeType;
        if (uri.startsWith('data:')) {
            mimeType = (0, AvatarSetting_1.getMimeTypeFromBase64Uri)(uri);
        }
        else {
            mimeType = (0, AvatarSetting_1.getMimeTypeFromFilePath)(uri);
        }
        try {
            const blob = react_native_1.Platform.OS === 'web'
                ? await (0, AvatarSetting_1.fetchImage)(uri, mimeType)
                : {
                    uri,
                    name: file.fileName,
                    type: mimeType,
                };
            setIsCustomSource(true);
            setCustomSource(file);
            await upload({
                variables: {
                    deviceTypeId,
                    file: blob,
                },
                context: {
                    headers: {
                        'Apollo-Require-Preflight': 'true',
                    }
                },
                onError,
                update: (cache, { data }) => {
                    var _a;
                    const cacheDeviceType = cache.readQuery({
                        query: client_graphql_1.DeviceTypeQuery,
                        variables: { id: deviceTypeId },
                    });
                    const deviceType = cacheDeviceType === null || cacheDeviceType === void 0 ? void 0 : cacheDeviceType.deviceType;
                    const newData = (_a = data === null || data === void 0 ? void 0 : data.deviceType) === null || _a === void 0 ? void 0 : _a.uploadAvatar;
                    if (deviceType) {
                        cache.writeQuery({
                            query: client_graphql_1.DeviceTypeQuery,
                            variables: { id: deviceTypeId },
                            data: Object.assign(Object.assign({}, deviceType), newData),
                        });
                    }
                }
            });
            (_a = blob === null || blob === void 0 ? void 0 : blob.close) === null || _a === void 0 ? void 0 : _a.call(blob);
        }
        catch (error) {
            onError(error);
        }
    }, [deviceTypeId]);
    const showPicker = (0, react_1.useCallback)(() => {
        showImagePicker(onSelectImage, {
            cameraOptions: imagePickerOptions,
            libraryOptions: imagePickerOptions,
        });
    }, []);
    // ********** //
    // * Render * //
    // ********** //
    return (react_1.default.createElement(components_1.StatefulComponent, { data: data, loading: loading, loadingComponent: LoadingComponent, error: error },
        react_1.default.createElement(AvatarSetting_1.AvatarSettingView, Object.assign({}, rest, { onPress: showPicker, loading: mutationLoading, source: isCustomSource ? customSource : source }))));
};
exports.DeviceTypeAvatarSetting = DeviceTypeAvatarSetting;
exports.DeviceTypeAvatarSetting.displayName = 'DeviceTypeAvatarSetting';
