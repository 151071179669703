"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertAndPrint = void 0;
const print_1 = require("./print");
/**
 * Convert and Print a value
 * @param value
 * @param schema
 */
function convertAndPrint(value, schema, context = {}) {
    console.warn('convertAndPrint is deprecated, use convert and print instead');
    const output = (0, print_1.print)(value, schema, context);
    return output;
}
exports.convertAndPrint = convertAndPrint;
