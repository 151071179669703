"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThermostatSetpointValue = void 0;
const helpers_1 = require("../helpers");
const types_1 = require("../types");
exports.ThermostatSetpointValue = {
    key: 'ThermostatSetpointValue',
    extends: 'Number',
    minimumValue: 16,
    maximumValue: 32,
    step: 1,
    unit: 'C',
    unitPosition: 'after',
    availableUnits: (0, helpers_1.convert)().possibilities('temperature'),
    converters: [
        {
            type: types_1.DataSchemaConverterType.RULE,
            value: {
                if: [
                    // if custom unit is given
                    { '!!': [{ var: 'attributes.thermostat.temperatureUnit' }] },
                    // Convert value to custom unit
                    {
                        'convert.to': [
                            { var: 'value' },
                            { var: 'schema.unit' },
                            { var: 'attributes.thermostat.temperatureUnit' },
                        ],
                    },
                    // else return as is
                    { var: 'value' },
                ],
            },
        },
    ],
    printer: {
        type: types_1.DataSchemaPrinterType.RULE,
        value: {
            if: [
                // if custom unit is given
                { '!!': [{ var: 'attributes.thermostat.temperatureUnit' }] },
                // Convert value to custom unit
                {
                    cat: [
                        { var: 'value' },
                        ' ',
                        { var: 'attributes.thermostat.temperatureUnit' },
                    ],
                },
                // else render as is with the default Unit
                { cat: [{ var: 'value' }, ' C'] },
            ],
        },
    },
};
