"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPreset = void 0;
const Acceleration_1 = require("./Acceleration");
const Angle_1 = require("./Angle");
const ApparentPower_1 = require("./ApparentPower");
const Area_1 = require("./Area");
const Boolean_1 = require("./Boolean");
const Charge_1 = require("./Charge");
const Connection_1 = require("./Connection");
const Currency_1 = require("./Currency");
const Current_1 = require("./Current");
const Date_1 = require("./Date");
const Digital_1 = require("./Digital");
const Energy_1 = require("./Energy");
const Force_1 = require("./Force");
const Frequency_1 = require("./Frequency");
const Illuminance_1 = require("./Illuminance");
const Integer_1 = require("./Integer");
const IntegerPercent_1 = require("./IntegerPercent");
const IPv4_1 = require("./IPv4");
const Iso8601Date_1 = require("./Iso8601Date");
const Length_1 = require("./Length");
const MacId_1 = require("./MacId");
const Mass_1 = require("./Mass");
const Number_1 = require("./Number");
const OnOff_1 = require("./OnOff");
const Pace_1 = require("./Pace");
const Parts_Per_1 = require("./Parts-Per");
const Percent_1 = require("./Percent");
const PositiveInteger_1 = require("./PositiveInteger");
const Power_1 = require("./Power");
const Pressure_1 = require("./Pressure");
const ReactiveEnergy_1 = require("./ReactiveEnergy");
const ReactivePower_1 = require("./ReactivePower");
const Speed_1 = require("./Speed");
const String_1 = require("./String");
const Temperature_1 = require("./Temperature");
const ThermostatSetpointValue_1 = require("./ThermostatSetpointValue");
const Time_1 = require("./Time");
const Url_1 = require("./Url");
const Voltage_1 = require("./Voltage");
const Volume_1 = require("./Volume");
const VolumeFlowRate_1 = require("./VolumeFlowRate");
const presets = {
    Acceleration: Acceleration_1.Acceleration,
    Angle: Angle_1.Angle,
    ApparentPower: ApparentPower_1.ApparentPower,
    Area: Area_1.Area,
    Boolean: Boolean_1.Boolean,
    Charge: Charge_1.Charge,
    Connection: Connection_1.Connection,
    Currency: Currency_1.Currency,
    Current: Current_1.Current,
    Date: Date_1.Date,
    Digital: Digital_1.Digital,
    Energy: Energy_1.Energy,
    Force: Force_1.Force,
    Frequency: Frequency_1.Frequency,
    Illuminance: Illuminance_1.Illuminance,
    Integer: Integer_1.Integer,
    IntegerPercent: IntegerPercent_1.IntegerPercent,
    IPv4: IPv4_1.IPv4,
    Iso8601Date: Iso8601Date_1.Iso8601Date,
    Length: Length_1.Length,
    MacId: MacId_1.MacId,
    Mass: Mass_1.Mass,
    Number: Number_1.Number,
    OnOff: OnOff_1.OnOff,
    Pace: Pace_1.Pace,
    PartsPer: Parts_Per_1.PartsPer,
    Percent: Percent_1.Percent,
    PositiveInteger: PositiveInteger_1.PositiveInteger,
    Power: Power_1.Power,
    Pressure: Pressure_1.Pressure,
    ReactiveEnergy: ReactiveEnergy_1.ReactiveEnergy,
    ReactivePower: ReactivePower_1.ReactivePower,
    Speed: Speed_1.Speed,
    String: String_1.String,
    Temperature: Temperature_1.Temperature,
    ThermostatSetpointValue: ThermostatSetpointValue_1.ThermostatSetpointValue,
    Time: Time_1.Time,
    Url: Url_1.Url,
    Voltage: Voltage_1.Voltage,
    Volume: Volume_1.Volume,
    VolumeFlowRate: VolumeFlowRate_1.VolumeFlowRate,
};
exports.default = presets;
function setPreset(key, schema) {
    presets[key] = schema;
}
exports.setPreset = setPreset;
