"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const circularGeometry_1 = require("./circularGeometry");
const ThumbTouchArea = (0, react_1.memo)((props) => {
    const { size, startAngle, endAngle, direction, axis, maximumValue, minimumValue, trackWidth, padding, value, thumbProps, thumbTouchSize, } = props;
    const handle1Angle = (0, react_1.useMemo)(() => (0, circularGeometry_1.valueToAngle)({
        endAngle,
        maximumValue,
        minimumValue,
        startAngle,
        value,
    }), [endAngle, maximumValue, minimumValue, startAngle, value]);
    const { x, y } = (0, react_1.useMemo)(() => (0, circularGeometry_1.angleToPosition)({
        axis,
        degree: handle1Angle,
        direction,
        radius: (0, circularGeometry_1.innerRadius)({ size, trackWidth, padding }),
        svgSize: size,
    }), [axis, handle1Angle, direction, size, trackWidth, padding, size]);
    return (react_1.default.createElement(react_native_1.TouchableWithoutFeedback, null,
        react_1.default.createElement(react_native_1.View, Object.assign({}, thumbProps, { style: {
                // left: x !== undefined ? x - thumbSize! / 2 : undefined,
                // top: y !== undefined ? y - thumbSize! / 2 : undefined,
                left: x - thumbTouchSize.width / 2,
                top: y - thumbTouchSize.height / 2,
                height: thumbTouchSize.height,
                position: 'absolute',
                width: thumbTouchSize.width,
                // backgroundColor: 'rgba(255,0,0,1)',
            } }))));
});
ThumbTouchArea.displayName = 'ThumbTouchArea';
exports.default = ThumbTouchArea;
