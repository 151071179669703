"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findValuesItem = void 0;
function findValuesItem(value, schema) {
    const result = {};
    if (schema.values) {
        const exists = schema.values.find((v) => v.value === value);
        result.description = exists ? exists.description : undefined;
        result.label = exists ? exists.label : undefined;
    }
    return result;
}
exports.findValuesItem = findValuesItem;
