"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegerPercent = void 0;
const types_1 = require("../types");
exports.IntegerPercent = {
    key: 'IntegerPercent',
    extends: 'Number',
    precision: 0,
    // Example: 26 => 26%
    printer: {
        type: types_1.DataSchemaPrinterType.TEMPLATE,
        value: '{{ value }}%',
    },
};
