"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HorizontalSliderWithIcon = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const universal_data_schema_1 = require("@mevris/universal-data-schema");
const react_1 = tslib_1.__importStar(require("react"));
const hooks_1 = require("../../../hooks");
const __1 = require("..");
const defaultStyles = (theme) => ({
    root: {
        alignItems: 'center',
        flexDirection: 'row',
        paddingHorizontal: theme.spacing.unit,
    },
    iconContainer: {
        alignItems: 'center',
        paddingVertical: theme.spacing.unit,
        paddingHorizontal: theme.spacing.unit,
        width: 50,
        userSelect: 'none',
    },
    icon: {
        color: theme.palette.text.icon,
    },
    sliderContainer: {
        flex: 1,
        marginHorizontal: theme.spacing.unit,
    },
    labelContainer: {
        alignItems: 'center',
        paddingVertical: theme.spacing.unit,
        paddingHorizontal: theme.spacing.unit,
        width: 50,
        userSelect: 'none',
    },
    label: {
        color: theme.palette.text.secondary,
    },
});
const HorizontalSliderWithIcon = (props) => {
    const { icon, styles: _s, style, data, onValueChange, onSlidingStart, onSlidingComplete, onValueChangeInternal } = props, rest = tslib_1.__rest(props, ["icon", "styles", "style", "data", "onValueChange", "onSlidingStart", "onSlidingComplete", "onValueChangeInternal"]);
    const { theme } = (0, core_1.useTheme)();
    const styles = (0, core_1.useStyles)('HorizontalSliderWithIcon', props, defaultStyles);
    const [value, setValue] = (0, react_1.useState)(data.value);
    const [sliding, setSliding] = (0, react_1.useState)(false);
    const { entity } = (0, hooks_1.useDevice)();
    const state = Object.assign({}, entity === null || entity === void 0 ? void 0 : entity.stateMap);
    const attributes = Object.assign({}, entity === null || entity === void 0 ? void 0 : entity.attributesMap);
    const slidingStart = (0, react_1.useCallback)((val) => {
        setSliding(true);
        if (onSlidingStart) {
            onSlidingStart(val);
        }
    }, [sliding, setSliding, onSlidingStart]);
    const slidingEnd = (0, react_1.useCallback)((val) => {
        setSliding(false);
        if (onSlidingComplete) {
            onSlidingComplete(val);
        }
    }, [sliding, setSliding, onSlidingComplete]);
    const valueChangeInternal = (0, react_1.useCallback)((val) => {
        setValue(val);
        if (onValueChangeInternal) {
            onValueChangeInternal(val);
        }
    }, [value, setValue, onValueChangeInternal]);
    return (react_1.default.createElement(components_1.View, Object.assign({}, rest, { style: [styles.root, style] }),
        react_1.default.createElement(components_1.View, { style: styles.iconContainer },
            react_1.default.createElement(components_1.Icon, { name: icon, style: styles.icon })),
        react_1.default.createElement(components_1.View, { style: [styles.sliderContainer, { marginTop: (0, core_1.isMobile)() ? theme.spacing.unit + 5 : 0 }] },
            react_1.default.createElement(__1.SliderManager, { Slider: __1.Slider, data: data, step: 0, hitSlop: {
                    top: theme.spacing.unit + 13,
                    bottom: theme.spacing.unit + 13,
                    left: theme.spacing.unit + 5,
                    right: theme.spacing.unit + 5,
                }, onValueChange: onValueChange, onValueChangeInternal: valueChangeInternal, onSlidingStart: slidingStart, onSlidingComplete: slidingEnd })),
        react_1.default.createElement(components_1.View, { style: styles.labelContainer },
            react_1.default.createElement(components_1.Body1, { style: styles.label }, (0, universal_data_schema_1.print)(sliding ? value : data.value, data.schema, { entity, state, attributes })))));
};
exports.HorizontalSliderWithIcon = HorizontalSliderWithIcon;
exports.HorizontalSliderWithIcon.displayName = 'HorizontalSliderWithIcon';
exports.HorizontalSliderWithIcon.defaultProps = {};
