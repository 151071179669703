"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivacyPolicy = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const PrivacyPolicy = () => {
    const { __ } = (0, core_1.useIntl)();
    const { setOptions } = (0, core_1.useNavigation)();
    const title = __('Privacy Policy');
    (0, react_1.useLayoutEffect)(() => {
        setOptions({ title });
    }, [title]);
    return react_1.default.createElement(components_1.Text, null, __('Please add privacy Policies here'));
};
exports.PrivacyPolicy = PrivacyPolicy;
