"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormFields = exports.isHidden = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const formik_1 = require("formik");
const react_1 = tslib_1.__importStar(require("react"));
const getFormField_1 = require("./getFormField");
/**
 * Safely retrieves the value at a nested path within an object.
 *
 * @param obj The object from which to retrieve the value.
 * @param path A dot-notated string representing the path to the value.
 * @returns The value at the specified path, or undefined if the path is not found.
 */
function getNestedValue(obj, path) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}
/**
* Determines if a field should be hidden based on the provided display options.
* This function supports both simple and nested object paths in the `values` and `displayOptions`.
*
* @param values An object containing the current values of fields, where keys represent field names.
* Can include nested objects.
* @param displayOptions An optional object containing `hide` and `show` properties, each an object.
* The keys of these objects are strings representing the field name or nested path (dot notation) to check,
* and their values are arrays of values indicating when to hide or show the field.
* @returns A boolean indicating whether the field should be hidden. Returns true if conditions to hide are met,
* false otherwise.
*/
function isHidden(values = {}, displayOptions) {
    const hide = displayOptions === null || displayOptions === void 0 ? void 0 : displayOptions.hide;
    const show = displayOptions === null || displayOptions === void 0 ? void 0 : displayOptions.show;
    const checkCondition = (condition, key) => {
        const valueAtPath = getNestedValue(values, key);
        return condition[key].findIndex((searchValue) => valueAtPath === searchValue) > -1;
    };
    if (hide) {
        return Object.keys(hide).every(key => checkCondition(hide, key));
    }
    if (show) {
        return !Object.keys(show).every(key => checkCondition(show, key));
    }
    return false;
}
exports.isHidden = isHidden;
/**
 * A component that takes care of field resolution and rendering logic.
 * This component follows the render prop pattern and gives a renderField
 * function as a param.
 */
const FormFields = (props) => {
    const { FieldWrapper, children, fields = [], fieldTypes, dividers } = props, rest = tslib_1.__rest(props, ["FieldWrapper", "children", "fields", "fieldTypes", "dividers"]);
    const BB = (0, core_1.useBlueBase)();
    const { __ } = (0, core_1.useIntl)();
    const { values } = (0, formik_1.useFormikContext)();
    const renderField = (0, react_1.useCallback)((fieldInput, index) => {
        const { displayOptions } = fieldInput, fieldOpts = tslib_1.__rest(fieldInput, ["displayOptions"]);
        const field = Object.assign(Object.assign(Object.assign({}, rest), fieldInput), { name: fieldInput.name || `${index}-${fieldInput.type}`, 
            // Translate
            helperText: fieldInput.helperText ? __(fieldOpts.helperText) : fieldOpts.helperText, label: fieldOpts.label ? __(fieldOpts.label) : fieldOpts.label, placeholder: fieldOpts.placeholder ? __(fieldOpts.placeholder) : fieldOpts.placeholder, title: fieldOpts.title ? __(fieldOpts.title) : fieldOpts.title });
        const key = field.name;
        const Component = BB.Components.resolveFromCache(...(0, getFormField_1.getFormField)(field.type, fieldTypes));
        Component.displayName = `FormFields.${field.type}`;
        const fieldNode = react_1.default.createElement(Component, Object.assign({ key: key }, field, { fieldTypes: fieldTypes }));
        if (isHidden(values, field.displayOptions)) {
            return null;
        }
        const node = FieldWrapper ? (react_1.default.createElement(FieldWrapper, { key: key, field: field, parent: props }, fieldNode)) : (fieldNode);
        if (dividers && fields.length > 0 && index < fields.length - 1) {
            return [node, react_1.default.createElement(components_1.Divider, { key: `${key}-divider` })];
        }
        return node;
    }, [props, fieldTypes, values, dividers, FieldWrapper]);
    if (children) {
        return children(renderField);
    }
    return fields.map(renderField);
};
exports.FormFields = FormFields;
exports.FormFields.displayName = 'FormFields';
