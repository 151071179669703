"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallSupportSetting = void 0;
const tslib_1 = require("tslib");
// tslint:disable: radix
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const isSupportOpen_1 = require("./isSupportOpen");
const LinkingSettingItem = (0, core_1.getComponent)('LinkingSettingItem');
const defaultStyles = (theme) => ({
    callNowText: {
        color: theme.palette.success.main,
        textTransform: 'uppercase',
    },
    closedText: {
        color: theme.palette.error.main,
        textTransform: 'uppercase',
    },
});
const CallSupportSetting = (props) => {
    const styles = (0, core_1.useStyles)('CallSupportSetting', props, defaultStyles);
    const { __ } = (0, core_1.useIntl)();
    const [days] = (0, core_1.useConfig)('plugin.settings-app.support.call.days');
    const [opens] = (0, core_1.useConfig)('plugin.settings-app.support.call.opens');
    const [closes] = (0, core_1.useConfig)('plugin.settings-app.support.call.closes');
    const [phoneNumber] = (0, core_1.useConfig)('plugin.settings-app.support.call.number');
    const checkCanCall = () => (0, isSupportOpen_1.isSupportOpen)(new Date(), days, parseInt(opens), parseInt(closes));
    const [canCall, setCanCall] = (0, react_1.useState)(checkCanCall());
    // Update every minute
    (0, react_1.useEffect)(() => {
        const id = setInterval(() => {
            setCanCall(checkCanCall());
        }, 60000);
        return () => clearInterval(id);
    });
    return (react_1.default.createElement(LinkingSettingItem, { url: `tel:${phoneNumber}`, left: react_1.default.createElement(components_1.List.Icon, { name: "phone" }), right: canCall ? (react_1.default.createElement(components_1.Body2, { style: styles.callNowText, testID: "call-status" }, __('Call Now'))) : (react_1.default.createElement(components_1.Body2, { style: styles.closedText, testID: "call-status" }, __('Closed'))), title: __('Call'), description: phoneNumber, disabled: !canCall }));
};
exports.CallSupportSetting = CallSupportSetting;
