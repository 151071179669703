"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileView = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const client_graphql_1 = require("@mevris/client-graphql");
const react_1 = tslib_1.__importStar(require("react"));
const hooks_1 = require("../../../../hooks");
const parts_1 = require("../../../parts");
const schemas_1 = require("../../../schemas");
const AutoPilotStateView_1 = require("../views/AutoPilotStateView");
const ExtraOptionsWizard_1 = require("../views/ExtraOptionsWizard");
const SmartCleanStateView_1 = require("../views/SmartCleanStateView");
const MobileView = (props) => {
    const { stateMap, setState, entity } = (0, hooks_1.useDeviceState)();
    const { items } = (0, hooks_1.useMevrisUI)(client_graphql_1.TraitUiType.Controls);
    const prediction = (0, hooks_1.useThermostatEtaPrediction)();
    const data = {};
    items.forEach((item) => {
        const key = item.props.key;
        data[key] = item;
    });
    // Dialog
    const [dialogVisible, setDialogVisibility] = (0, react_1.useState)(false);
    const toggleDialogVisibility = (0, react_1.useCallback)(() => setDialogVisibility(!dialogVisible), [
        dialogVisible,
        setDialogVisibility,
    ]);
    // Find required nodes by key
    function getJsonNode(key) {
        return items.find((i) => i.props.key === key);
    }
    // Find required data itemss by key
    function getDataItem(key) {
        const result = getJsonNode(key);
        return result === null || result === void 0 ? void 0 : result.props;
    }
    const onItem = getDataItem('on');
    const autoCleanItem = getDataItem('cleaning');
    const autoPilotItem = getDataItem('airconditioner.autopilot');
    let node;
    // If there is no state, or on or setpoint states then stop here
    if (!stateMap || !entity || !getJsonNode('on') || !getJsonNode('thermostat.setpoint')) {
        node = null;
    }
    // If auto clean is active, show that state
    else if (autoCleanItem && autoCleanItem.value) {
        node = react_1.default.createElement(SmartCleanStateView_1.SmartCleanStateView, { data: data, onStateChange: setState });
    }
    // If auto pilot is active, show that state
    else if (autoPilotItem && autoPilotItem.value) {
        node = react_1.default.createElement(AutoPilotStateView_1.AutoPilotStateView, { data: data, onStateChange: setState, entity: entity });
    }
    // If the device is off, show off state
    else if (onItem && !onItem.value) {
        node = react_1.default.createElement(parts_1.OffStateView, { on: onItem, onStateChange: setState });
    }
    // Render main controls
    else {
        node = (react_1.default.createElement(schemas_1.AirConditionerControlUIView, Object.assign({ onStateChange: setState, data: {
                on: onItem,
                'fanSpeed.mode': getDataItem('fanSpeed.mode'),
                'thermostat.mode': getDataItem('thermostat.mode'),
                'thermostat.setpoint': Object.assign(Object.assign({}, getDataItem('thermostat.setpoint')), { description: prediction }),
            }, extraActions: [
                {
                    icon: { name: 'dots-horizontal', type: 'icon' },
                    title: 'More',
                    testID: 'more-actions-button',
                    onPress: toggleDialogVisibility,
                },
            ] }, props)));
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        node,
        react_1.default.createElement(components_1.Dialog, { testID: "extra-options-dialog", visible: dialogVisible, dismissable: true, onDismiss: toggleDialogVisibility },
            react_1.default.createElement(components_1.View, { style: { minHeight: 480 } },
                react_1.default.createElement(ExtraOptionsWizard_1.ExtraOptionsWizard, { entity: entity, onStateChange: setState, data: data, dismiss: toggleDialogVisibility })))));
};
exports.MobileView = MobileView;
exports.MobileView.displayName = 'MobileView';
