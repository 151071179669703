"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorTrack = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const react_native_svg_1 = tslib_1.__importStar(require("react-native-svg"));
const CircularSlider_1 = require("../CircularSlider");
const getSegmentAngles = (startAngle, endAngle, segmentCount) => (endAngle - startAngle) / segmentCount;
/**
 * Creates Gradient defs for each segment
 */
function getGradients(props) {
    const { colors, size, startAngle, endAngle, direction, axis, trackWidth, padding } = props;
    const segmentCount = colors.length - 1;
    const segmentAngles = getSegmentAngles(startAngle, endAngle, segmentCount);
    const gradients = Array.from(Array(segmentCount).keys()).map((index) => {
        const startColor = colors[index];
        const endColor = colors[index + 1];
        const segmentStartAngle = startAngle + segmentAngles * index;
        const segmentEndAngle = segmentStartAngle + segmentAngles;
        const { x: fromX, y: fromY } = CircularSlider_1.CircularGeometry.angleToPosition({
            axis,
            degree: segmentStartAngle,
            direction,
            radius: CircularSlider_1.CircularGeometry.innerRadius({ padding, size, trackWidth }),
            svgSize: size,
        });
        const { x: toX, y: toY } = CircularSlider_1.CircularGeometry.angleToPosition({
            axis,
            degree: segmentEndAngle,
            direction,
            radius: CircularSlider_1.CircularGeometry.innerRadius({ padding, size, trackWidth }),
            svgSize: size,
        });
        return (react_1.default.createElement(react_native_svg_1.LinearGradient, { key: index, id: `gradient${index}`, x1: fromX.toFixed(2), y1: fromY.toFixed(2), x2: toX.toFixed(2), y2: toY.toFixed(2), gradientUnits: "userSpaceOnUse" },
            react_1.default.createElement(react_native_svg_1.Stop, { offset: "0%", stopColor: startColor }),
            react_1.default.createElement(react_native_svg_1.Stop, { offset: "1", stopColor: endColor })));
    });
    return gradients;
}
/**
 * Creates extra end caps for the arc, to have rounded ends
 */
function getEndCaps(props) {
    const { colors, size, startAngle, endAngle, direction, axis, trackWidth, padding } = props;
    const { x: c1X, y: c1Y } = CircularSlider_1.CircularGeometry.angleToPosition({
        axis,
        degree: startAngle,
        direction,
        radius: CircularSlider_1.CircularGeometry.innerRadius({ padding, size, trackWidth }),
        svgSize: size,
    });
    const { x: c2X, y: c2Y } = CircularSlider_1.CircularGeometry.angleToPosition({
        axis,
        degree: endAngle,
        direction,
        radius: CircularSlider_1.CircularGeometry.innerRadius({ padding, size, trackWidth }),
        svgSize: size,
    });
    return [
        react_1.default.createElement(react_native_svg_1.Circle, { key: "c1", r: trackWidth / 2, cx: c1X, cy: c1Y, fill: colors[0] }),
        react_1.default.createElement(react_native_svg_1.Circle, { key: "c2", r: trackWidth / 2, cx: c2X, cy: c2Y, fill: colors[colors.length - 1] }),
    ];
}
/**
 * Creates segments of the path
 */
function getPaths(props) {
    const { colors, size, startAngle, endAngle, direction, axis, trackWidth, padding } = props;
    const segmentCount = colors.length - 1;
    const segmentAngles = getSegmentAngles(startAngle, endAngle, segmentCount);
    return Array.from(Array(segmentCount).keys()).map((index) => {
        const segmentStartAngle = startAngle + segmentAngles * index;
        const segmentEndAngle = segmentStartAngle + segmentAngles;
        return (react_1.default.createElement(react_native_svg_1.Path, { d: (0, CircularSlider_1.createArcPath)({
                axis,
                direction,
                endAngle: segmentEndAngle,
                innerRadius: CircularSlider_1.CircularGeometry.innerRadius({ padding, size, trackWidth }),
                startAngle: segmentStartAngle,
                svgSize: size,
                thickness: trackWidth,
            }), key: index, fill: "transparent", stroke: `url(#gradient${index})`, strokeWidth: trackWidth, strokeLinecap: "butt" }));
    });
}
exports.ColorTrack = (0, react_1.memo)((props) => {
    const { size, width, height } = props;
    const gradients = getGradients(props);
    const caps = getEndCaps(props);
    const paths = getPaths(props);
    return (react_1.default.createElement(react_native_1.TouchableWithoutFeedback, { onPressIn: props.onPress },
        react_1.default.createElement(react_native_svg_1.default, { width: width || size, height: height || size },
            react_1.default.createElement(react_native_svg_1.Defs, null, gradients),
            react_1.default.createElement(react_native_svg_1.G, null,
                caps,
                paths))));
});
exports.ColorTrack.displayName = 'ColorTrack';
