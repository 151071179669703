"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Percent = void 0;
const types_1 = require("../types");
exports.Percent = {
    key: 'Percent',
    extends: 'Number',
    precision: 2,
    // 0.15 => 15%
    printer: {
        type: types_1.DataSchemaPrinterType.TEMPLATE,
        value: '{{ (value*100).toFixed() }}%',
    },
};
