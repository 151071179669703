"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.navigation = void 0;
const core_1 = require("@bluebase/core");
// Note: This implementation is for "React Router" library
exports.navigation = {
    'bluebase.navigator.main': [
        {
            key: 'bluebase-navigator-main-privacy-and-terms',
            priority: 20,
            value: async (inputNavigator, opts) => {
                const { intl } = opts;
                return Object.assign(Object.assign({}, inputNavigator), { routes: [
                        {
                            name: 'PrivacyPolicy',
                            screen: 'PrivacyPolicy',
                            path: '/privacy',
                            exact: true,
                            options: {
                                contentStyle: {
                                    backgroundColor: 'black',
                                },
                                presentation: 'modal',
                                title: intl.__('Privacy Policy'),
                            },
                        },
                        {
                            name: 'TermsOfService',
                            screen: 'TermsOfService',
                            path: '/terms',
                            exact: true,
                            options: {
                                contentStyle: {
                                    backgroundColor: 'black',
                                },
                                presentation: 'modal',
                                title: intl.__('Terms of Service'),
                            },
                        },
                        ...(0, core_1.resolveThunk)(inputNavigator.routes, opts),
                    ] });
            },
        },
    ],
};
