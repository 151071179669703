"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeviceBuilder = void 0;
const useDeviceInitialization_1 = require("./useDeviceInitialization");
const useDeviceSyncOnAppStateUpdates_1 = require("./useDeviceSyncOnAppStateUpdates");
/**
 * Creates, registers & initializes a universal device controller
 * @param id
 */
function useDeviceBuilder(id) {
    // Initialize device
    const response = (0, useDeviceInitialization_1.useDeviceInitialization)(id);
    // Refetch device state whenever app state changes
    (0, useDeviceSyncOnAppStateUpdates_1.useDeviceSyncOnAppStateUpdates)(response.device);
    return response;
}
exports.useDeviceBuilder = useDeviceBuilder;
