"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Connection = void 0;
exports.Connection = {
    key: 'Connection',
    extends: 'Boolean',
    values: [
        {
            label: 'Online',
            value: true,
        },
        {
            label: 'Offline',
            value: false,
        },
    ],
};
