"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapUISchemaToJsonComponentNodes = void 0;
/* eslint-disable no-prototype-builtins */
const component_mapper_1 = require("@bluebase/component-mapper");
const client_graphql_1 = require("@mevris/client-graphql");
/**
 * Converts Mevris UI schema items to JsonSchemaNodes
 *
 * @param uiItems
 * @param stateMap
 */
function mapUISchemaToJsonComponentNodes(
/**
 * UI Items
 */
uiItems = [], 
/**
 * Entity data
 */
entity, 
/**
 * Mapper options
 */
options = {}) {
    const { ignoreItemsWithoutValue, componentMap, propMap, propMapperOptions, } = Object.assign({ componentMap: {}, ignoreItemsWithoutValue: false, propMap: {}, propMapperOptions: { rest: true } }, options);
    const { stateMap = {}, attributesMap = {} } = entity || {};
    return uiItems
        .map((item) => {
        const targetType = item.targetType;
        // If required, prepend prefix in the stateKey
        const key = item.targetKey;
        // If this key doesn't exist in the state object
        if (ignoreItemsWithoutValue === true) {
            if (targetType === client_graphql_1.TraitUiTargetType.State && !stateMap.hasOwnProperty(key)) {
                return;
            }
            if (targetType === client_graphql_1.TraitUiTargetType.Attribute && !attributesMap.hasOwnProperty(key)) {
                return;
            }
        }
        // Extract state value
        let value;
        if (targetType === client_graphql_1.TraitUiTargetType.State) {
            value = stateMap[key];
        }
        if (targetType === client_graphql_1.TraitUiTargetType.Attribute) {
            value = attributesMap[key];
        }
        // Data Item
        const dataItem = item.definition;
        const schemaComponent = item.component;
        const componentName = schemaComponent === null || schemaComponent === void 0 ? void 0 : schemaComponent.component;
        const children = (schemaComponent === null || schemaComponent === void 0 ? void 0 : schemaComponent.children) ? schemaComponent.children : undefined;
        const component = componentName && componentMap.hasOwnProperty(componentName)
            ? componentMap[componentName]
            : componentName;
        if (!component) {
            return;
        }
        const props = (0, component_mapper_1.objectMapper)(Object.assign(Object.assign(Object.assign({}, schemaComponent === null || schemaComponent === void 0 ? void 0 : schemaComponent.props), dataItem), { targetType,
            key, title: dataItem.name, value }), propMap, propMapperOptions);
        // Build a new object for JsonLayout
        return { children, component, props };
    })
        .filter((x) => !!x);
}
exports.mapUISchemaToJsonComponentNodes = mapUISchemaToJsonComponentNodes;
