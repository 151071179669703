"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSchemaValidatorType = void 0;
var DataSchemaValidatorType;
(function (DataSchemaValidatorType) {
    DataSchemaValidatorType["REGEX"] = "REGEX";
    DataSchemaValidatorType["FUNCTION"] = "FUNCTION";
    DataSchemaValidatorType["TEMPLATE"] = "TEMPLATE";
    DataSchemaValidatorType["RULE"] = "RULE";
})(DataSchemaValidatorType = exports.DataSchemaValidatorType || (exports.DataSchemaValidatorType = {}));
