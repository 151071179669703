"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useActionSheet = void 0;
const react_native_action_sheet_1 = require("@expo/react-native-action-sheet");
const react_1 = require("react");
const react_native_1 = require("react-native");
const withActionSheetProvider_1 = require("./withActionSheetProvider");
function useActionSheet() {
    const { showActionSheetWithOptions } = (0, react_native_action_sheet_1.useActionSheet)();
    const { showActionSheetOrDialogWithOptions: _showActionSheetOrDialogWithOptions } = (0, react_1.useContext)(withActionSheetProvider_1.ActionSheetDesktopContext);
    const showActionSheetOrDialogWithOptions = react_native_1.Platform.OS !== 'web'
        ? showActionSheetWithOptions
        : _showActionSheetOrDialogWithOptions;
    return { showActionSheetOrDialogWithOptions, showActionSheetWithOptions };
}
exports.useActionSheet = useActionSheet;
