"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Url = void 0;
const types_1 = require("../types");
exports.Url = {
    key: 'Url',
    extends: 'String',
    validators: [
        {
            type: types_1.DataSchemaValidatorType.REGEX,
            value: '^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port
                '(\\?[;&amp;a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$',
        },
    ],
};
