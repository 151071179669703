"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteIconButton = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const Alert_1 = require("../Alert");
const DeleteIconButton = (_a) => {
    var { title, description, onDelete } = _a, rest = tslib_1.__rest(_a, ["title", "description", "onDelete"]);
    const { alert } = (0, Alert_1.useAlert)();
    const { theme } = (0, core_1.useTheme)();
    const { __ } = (0, core_1.useIntl)();
    const onPress = (0, react_1.useCallback)(() => alert(__(title !== null && title !== void 0 ? title : 'Delete'), __(description), [
        { text: __('Cancel'), style: 'cancel' },
        { text: __('Delete'), style: 'destructive', onPress: onDelete },
    ]), []);
    return (react_1.default.createElement(components_1.IconButton, Object.assign({ name: "delete", color: theme.palette.text.secondary }, rest, { onPress: onPress })));
};
exports.DeleteIconButton = DeleteIconButton;
exports.DeleteIconButton.defaultProps = {
    description: 'Are you sure you want to delete this data? This action cannot be undone.',
};
exports.DeleteIconButton.displayName = 'DeleteIconButton';
