"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultConfigs = void 0;
exports.defaultConfigs = {
    'plugin.settings-app.group-sort-order': ['General', 'Appearance', 'Account', 'Advanced', 'About'],
    /** Support email. If set, Email option is show in Support section of About page */
    'plugin.settings-app.support.email': null,
    /**
     * A Number, from 0 to 6, representing the day of the week.
     * Specifies the days support is available. Defaults to Mon-Fri.
     */
    'plugin.settings-app.support.call.days': [1, 2, 3, 4, 5],
    /**
     * Call support opening hour in HH format UTC.
     */
    'plugin.settings-app.support.call.opens': null,
    /**
     * Call support closing hour in HH format UTC.
     */
    'plugin.settings-app.support.call.closes': null,
    /**
     * Phone number for call center
     */
    'plugin.settings-app.support.call.number': null,
    /** Adds developer on about page  */
    developer: 'BlueEast (Pvt.) Ltd.',
    /** Adds developer  redirect url  */
    developerUrl: 'https://www.blueeast.com/',
};
