"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
/* eslint-disable camelcase */
const core_1 = require("@bluebase/core");
const components_1 = require("./components");
const exports_1 = require("./components/exports");
const lang_1 = require("./lang");
const version_1 = require("./version");
tslib_1.__exportStar(require("./exports"), exports);
exports.default = (0, core_1.createPlugin)({
    description: 'BlueEast Client Plugin UI',
    key: 'blueeast-client-plugin-ui',
    name: 'BlueEast UI',
    version: version_1.VERSION,
    assets: {
    // Assets have been moved to the client projects
    // // Placeholders
    // PersonPlaceholder: require('../assets/person-placeholder.png'),
    // PersonPlaceholder_dark: require('../assets/person-placeholder-dark.png'),
    // // Errors
    // Error400: require('../assets/Error400.png'),
    // Error400_dark: require('../assets/Error400_dark.png'),
    // Error401: require('../assets/Error401.png'),
    // Error401_dark: require('../assets/Error401_dark.png'),
    // Error403: require('../assets/Error403.png'),
    // Error403_dark: require('../assets/Error403_dark.png'),
    // Error404: require('../assets/Error404.png'),
    // Error404_dark: require('../assets/Error404_dark.png'),
    // Error429: require('../assets/Error429.png'),
    // Error429_dark: require('../assets/Error429_dark.png'),
    // Error500: require('../assets/Error500.png'),
    // Error500_dark: require('../assets/Error500_dark.png'),
    // Error503: require('../assets/Error503.png'),
    // Error503_dark: require('../assets/Error503_dark.png'),
    // Error504: require('../assets/Error504.png'),
    // Error504_dark: require('../assets/Error504_dark.png'),
    // // General
    // Alone: require('../assets/Alone.png'),
    // Alone_dark: require('../assets/Alone_dark.png'),
    // Empty: require('../assets/Empty.png'),
    // Empty_dark: require('../assets/Empty_dark.png'),
    // Error: require('../assets/Error.png'),
    // Error_dark: require('../assets/Error_dark.png'),
    // FeelingAngry: require('../assets/FeelingAngry.png'),
    // FeelingAngry_dark: require('../assets/FeelingAngry_dark.png'),
    // FeelingSorry: require('../assets/FeelingSorry.png'),
    // FeelingSorry_dark: require('../assets/FeelingSorry_dark.png'),
    // NoData: require('../assets/NoData.png'),
    // NoData_dark: require('../assets/NoData_dark.png'),
    // Thoughts: require('../assets/Thoughts.png'),
    // Thoughts_dark: require('../assets/Thoughts_dark.png'),
    },
    components: Object.assign({}, components_1.components),
    filters: Object.assign(Object.assign({}, lang_1.lang), { 'bluebase.boot.end': (bootOptions, _ctx, BB) => {
            BB.Components.addHocs('BlueBaseContent', exports_1.withActionSheetProvider);
            BB.Components.addHocs('BlueBaseContent', exports_1.withAlertUI);
            return bootOptions;
        } }),
});
