"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NUMBER = void 0;
exports.NUMBER = {
    transform: (inputValue, schema) => {
        let value = inputValue;
        if (value === undefined || value === null) {
            return value;
        }
        // If a number is sent as string, would parse it into float or int type
        if (typeof value === 'string') {
            // Remove commans from values like "1,000"
            const simplified = value.replace(',', '');
            /**
             * Checking if string is in float representation
             * Would pass if string contains only numbers
             */
            if (/^-?[0-9]\d*(\.\d+)?$/.test(simplified)) {
                value = parseFloat(simplified);
            }
        }
        if ('precision' in schema) {
            try {
                value = parseFloat(value.toFixed(schema.precision));
            }
            catch (e) {
                //
            }
        }
        // We return value as is, expecting it to fail in validation step
        return value;
    },
    convert: (inputValue, schema) => {
        let value = inputValue;
        if ('precision' in schema) {
            try {
                value = parseFloat(value.toFixed(schema.precision));
            }
            catch (e) {
                //
            }
        }
        // We return value as is, expecting it to fail in validation step
        return value;
    },
    validate: (value, schema) => {
        if (typeof value !== 'number') {
            return false;
        }
        if (schema.maximumValue !== undefined && value > schema.maximumValue) {
            return false;
        }
        if (schema.minimumValue !== undefined && value < schema.minimumValue) {
            return false;
        }
        if (schema.values) {
            const exists = schema.values.find((v) => v.value === value);
            if (!exists) {
                return false;
            }
        }
        return true;
    },
};
