"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSettingsRoutes = void 0;
const tslib_1 = require("tslib");
const core_1 = require("@bluebase/core");
const components_1 = require("./components");
const configs_1 = require("./configs");
const lang_1 = require("./lang");
const layouts_1 = require("./layouts");
const SettingsPageItem_1 = require("./layouts/SettingsPageItem");
const lib_1 = require("./lib");
Object.defineProperty(exports, "createSettingsRoutes", { enumerable: true, get: function () { return lib_1.createSettingsRoutes; } });
const navigation_1 = require("./navigation");
const routes_1 = require("./routes");
const settings_1 = require("./settings");
const version_1 = require("./version");
tslib_1.__exportStar(require("./components/exports"), exports);
tslib_1.__exportStar(require("./layouts/SettingsPageItem"), exports);
tslib_1.__exportStar(require("./layouts/SettingsScreen"), exports);
exports.default = (0, core_1.createPlugin)({
    description: 'A settings app for the BlueBase framework!',
    key: 'settings',
    name: 'Settings',
    version: version_1.VERSION,
    icon: {
        component: 'SettingsAppIcon',
        type: 'component',
    },
    defaultConfigs: configs_1.defaultConfigs,
    components: Object.assign(Object.assign(Object.assign(Object.assign({}, components_1.components), settings_1.settings), layouts_1.layouts), { SettingsPageItem: SettingsPageItem_1.SettingsPageItem }),
    indexRoute: 'SettingsApp',
    routes: routes_1.routes,
    filters: Object.assign(Object.assign({}, lang_1.lang), navigation_1.navigation),
});
