"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeviceInitialization = void 0;
const tslib_1 = require("tslib");
const client_1 = require("@apollo/client");
const core_1 = require("@bluebase/core");
const client_graphql_1 = require("@mevris/client-graphql");
const react_1 = require("react");
const updateStore_1 = require("./updateStore");
function useDeviceInitialization(id) {
    var _a;
    // BlueBase Context
    const BB = (0, react_1.useContext)(core_1.BlueBaseContext);
    // Status states
    const [loadingDeviceCreate, setLoadingDeviceCreate] = (0, react_1.useState)(false);
    const [errorDeviceCreate, setErrorDeviceCreate] = (0, react_1.useState)();
    // Get device object
    const device = BB.Devices.getValue(id);
    // Query Device Controllers configs
    const _b = (0, client_1.useQuery)(client_graphql_1.EntityQuery, { variables: { id } }), { loading: loadingQuery, error: errorQuery, data, client: apollo } = _b, rest = tslib_1.__rest(_b, ["loading", "error", "data", "client"]);
    // Combined status states
    const loading = loadingQuery || loadingDeviceCreate;
    const error = errorQuery || errorDeviceCreate;
    // Middleware
    async function middleware({ req, res }) {
        // If there has been a new state fetched from local controller, write it to
        // graphql store, so all ui can reflect it
        // FIXME: but why only local?
        if (req.action === 'requestState' && res.controllerType === 'local' && !res.error) {
            (0, updateStore_1.updateStore)({ BB, stateMap: res.data, id, apollo });
        }
    }
    // We need to fetch device configs from there server
    (0, react_1.useEffect)(() => {
        // // Keep a check if the component was unmounted
        // let didCancel = false;
        async function resolveControllers() {
            var _a;
            // Extract controller details from graphql data
            const localController = (_a = data === null || data === void 0 ? void 0 : data.entity.schema) === null || _a === void 0 ? void 0 : _a.localDeviceController;
            if (loading === true || error) {
                return;
            }
            if (device) {
                // Subscribe to changes
                BB.Devices.attach(id, (stateMap) => (0, updateStore_1.updateStore)({ BB, stateMap, id, apollo }));
                return;
            }
            try {
                // Set loading state
                setLoadingDeviceCreate(true);
                // Create device in the registry
                await BB.Devices.create(id, {
                    localController,
                    remoteController: { key: 'MevrisRemoteDeviceController' },
                }, { apollo, entity: data === null || data === void 0 ? void 0 : data.entity }, [middleware]);
                // Ignore if we started fetching something else
                // if (didCancel) {
                // 	return;
                // }
                // Subscribe to changes
                BB.Devices.attach(id, (stateMap) => (0, updateStore_1.updateStore)({ BB, stateMap, id, apollo }));
                // Set loading to false
                setLoadingDeviceCreate(false);
            }
            catch (error) {
                // if (didCancel) {
                // 	// Ignore if we started fetching something else
                // 	return;
                // }
                setLoadingDeviceCreate(false);
                setErrorDeviceCreate(error);
            }
        }
        resolveControllers();
        return () => {
            // didCancel = true;
            // Unsubscribe on cancel
            BB.Devices.detach(id);
        };
    }, [id, loading, error, (_a = data === null || data === void 0 ? void 0 : data.entity) === null || _a === void 0 ? void 0 : _a.id, device, BB, apollo]);
    return Object.assign({ id,
        device,
        error,
        loading,
        data, entity: data === null || data === void 0 ? void 0 : data.entity, client: apollo }, rest);
}
exports.useDeviceInitialization = useDeviceInitialization;
