"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IPv4 = void 0;
/* eslint-disable max-len */
const types_1 = require("../types");
exports.IPv4 = {
    key: 'IPv4',
    extends: 'String',
    validators: [
        {
            type: types_1.DataSchemaValidatorType.REGEX,
            // eslint-disable-next-line no-useless-escape
            value: '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)',
        },
    ],
};
