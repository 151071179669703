"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MacId = void 0;
const types_1 = require("../types");
exports.MacId = {
    key: 'MacId',
    extends: 'String',
    transformers: [
        {
            type: types_1.DataSchemaTransformerType.FUNCTION,
            value: (value, _schema) => {
                return String(value).trim().toUpperCase().replace(/[- ]/g, ':');
            },
        },
    ],
    validators: [
        {
            type: types_1.DataSchemaValidatorType.REGEX,
            value: '(?:[0-9A-F]{2}[:]){5}(?:[0-9A-F]{2})',
        },
    ],
};
