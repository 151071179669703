"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoPilotStateView = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const universal_data_schema_1 = require("@mevris/universal-data-schema");
const react_1 = tslib_1.__importStar(require("react"));
const parts_1 = require("../../../parts");
const AutoPilotSettingsView_1 = require("./AutoPilotSettingsView");
const defaultStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    infoPanel: {
        borderBottomColor: theme.palette.divider,
        borderBottomWidth: 1,
    },
    quickActionListContainer: {},
});
/**
 * This view is displayed when Air Conditioner's auto pilot mode is active
 * @param props
 */
const AutoPilotStateView = (props) => {
    var _a, _b, _c, _d;
    const { data, onStateChange, entity, style } = props;
    const { __ } = (0, core_1.useIntl)();
    const styles = (0, core_1.useStyles)('AutoPilotStateView', props, defaultStyles);
    // Extract data items
    const autoPilot = (_a = data['airConditioner.autoPilot']) === null || _a === void 0 ? void 0 : _a.props;
    const autoPilotMode = (_b = data['airConditioner.autoPilot.mode']) === null || _b === void 0 ? void 0 : _b.props;
    const autoPilotTarget = (_c = data['airConditioner.autoPilot.temperature']) === null || _c === void 0 ? void 0 : _c.props;
    const thermostatSetpoint = data['thermostat.setpoint'].props;
    const temperature = (_d = data.temperature) === null || _d === void 0 ? void 0 : _d.props;
    // Description Dialog
    const [descDialogVisible, setDescDialogVisible] = (0, react_1.useState)(false);
    const toggleDescDialog = (0, react_1.useCallback)(() => setDescDialogVisible(!descDialogVisible), [
        descDialogVisible,
    ]);
    // Settings Dialog
    const [settingsDialogVisible, setSettingsDialogVisible] = (0, react_1.useState)(false);
    const toggleSettingsDialog = (0, react_1.useCallback)(() => setSettingsDialogVisible(!settingsDialogVisible), [
        settingsDialogVisible,
    ]);
    // On state change
    const onValueChange = (0, react_1.useCallback)(() => onStateChange({ [(autoPilot === null || autoPilot === void 0 ? void 0 : autoPilot.key) || 'nil']: false }), [autoPilot]);
    // Finding selected auto-pilot-mode
    const currentModeSchema = (0, universal_data_schema_1.resolveDataSchema)((autoPilotMode === null || autoPilotMode === void 0 ? void 0 : autoPilotMode.schema) || {});
    const currentMode = (currentModeSchema.values || []).find((m) => m.value === (autoPilotMode === null || autoPilotMode === void 0 ? void 0 : autoPilotMode.value));
    const currentModeLabel = (currentMode === null || currentMode === void 0 ? void 0 : currentMode.label) || 'unknown';
    const currentModeDescription = currentMode === null || currentMode === void 0 ? void 0 : currentMode.description;
    // Build Info panel
    const infoItems = [];
    if (autoPilotTarget && autoPilotTarget.value !== undefined) {
        infoItems.push({
            label: __('Target'),
            value: (0, universal_data_schema_1.print)(autoPilotTarget.value, autoPilotTarget.schema),
            testID: 'target-info',
        });
    }
    if (thermostatSetpoint && thermostatSetpoint.value !== undefined) {
        infoItems.push({
            label: __('Thermostat'),
            value: (0, universal_data_schema_1.print)(thermostatSetpoint.value, thermostatSetpoint.schema),
            testID: 'thermostat-info',
        });
    }
    if (temperature && temperature.value !== undefined) {
        infoItems.push({
            label: __('Temperature'),
            value: (0, universal_data_schema_1.print)(temperature.value, temperature.schema),
            testID: 'temperature-info',
        });
    }
    return (react_1.default.createElement(components_1.View, { style: [styles.root, style] },
        react_1.default.createElement(components_1.ComponentState, { image: react_1.default.createElement(components_1.H1, null, "\u2708\uFE0F"), title: __('Cruising on Auto Pilot'), description: __(`Running Auto Pilot using the ${currentModeLabel} method.`), actionTitle: currentModeDescription ? __('Learn More') : undefined, actionProps: { size: 'small', variant: 'text', testID: 'learn-more-button' }, actionOnPress: toggleDescDialog }),
        react_1.default.createElement(components_1.Dialog, { testID: "description-dialog", visible: descDialogVisible, dismissable: true, onDismiss: toggleDescDialog },
            react_1.default.createElement(components_1.Dialog.Title, null, currentModeLabel),
            react_1.default.createElement(components_1.Dialog.Content, { style: { paddingBottom: 16 } }, currentModeDescription)),
        react_1.default.createElement(parts_1.InfoPanel, { style: styles.infoPanel, items: infoItems }),
        (0, core_1.isMobile)() ? (react_1.default.createElement(components_1.View, { style: styles.quickActionListContainer },
            react_1.default.createElement(parts_1.QuickActionList, { data: [
                    {
                        icon: { name: 'power', type: 'icon' },
                        variant: 'outlined',
                        size: 'small',
                        title: __('Disable'),
                        onPress: onValueChange,
                        testID: 'disable-button',
                    },
                    {
                        icon: { name: 'airplane', type: 'icon' },
                        variant: 'outlined',
                        size: 'small',
                        title: __('Configure'),
                        onPress: toggleSettingsDialog,
                        testID: 'configure-button',
                    },
                ] }),
            react_1.default.createElement(components_1.Dialog, { testID: "settings-dialog", visible: settingsDialogVisible, dismissable: true, onDismiss: toggleSettingsDialog },
                react_1.default.createElement(AutoPilotSettingsView_1.AutoPilotSettingsView, { data: data, onStateChange: onStateChange, entity: entity })))) : null));
};
exports.AutoPilotStateView = AutoPilotStateView;
exports.AutoPilotStateView.displayName = 'AutoPilotStateView';
