"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MevrisWifiSmartDimmerSwitchControlUI = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const sounds_1 = require("../../../sounds");
const constants_1 = require("../../constants");
const parts_1 = require("../../parts");
const defaultStyles = {
    root: {
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'center',
        flexDirection: 'row',
    },
    button: {},
};
const MevrisWifiSmartDimmerSwitchControlUI = (props) => {
    const { style, data, onStateChange } = props;
    const styles = (0, core_1.useStyles)('MevrisWifiSmartDimmerSwitchControlUI', props, defaultStyles);
    const playSwitchSound = (0, sounds_1.useSwitchSound)();
    const { on: on, brightness } = data;
    const [dimmer, SetDimmer] = react_1.default.useState(brightness.value);
    const onValueChange = (0, react_1.useCallback)(() => {
        playSwitchSound(true);
        onStateChange({ on: !on.value });
    }, [on]);
    const onValueChangeDimmer = (0, react_1.useCallback)((value) => {
        SetDimmer(value);
    }, []);
    const onSlidingComplete = (0, react_1.useCallback)(() => {
        playSwitchSound(true);
        onStateChange({ [brightness.key]: dimmer });
    }, [dimmer]);
    return (react_1.default.createElement(components_1.View, { style: [styles.root, style] }, on.value ? (react_1.default.createElement(parts_1.TankSlider, { value: dimmer, 
        // switch={on.value}
        // onSwitchChange={onValueChange}
        vertical: true, 
        // onColor="#ffff4c"
        onSlidingComplete: onSlidingComplete, onValueChange: onValueChangeDimmer, height: 250, width: 119 },
        react_1.default.createElement(components_1.Icon, { name: "white-balance-sunny", color: "#FFF", style: { position: 'absolute', bottom: 8, userSelect: 'none', cursor: 'pointer' } }))) : (react_1.default.createElement(parts_1.SwitchButton, Object.assign({ height: constants_1.BUTTON_HEIGHT * 2 + 18, width: constants_1.BUTTON_WIDTH + 50, value: on.value, onValueChange: onValueChange }, on.props, { styles: styles.button })))));
};
exports.MevrisWifiSmartDimmerSwitchControlUI = MevrisWifiSmartDimmerSwitchControlUI;
exports.MevrisWifiSmartDimmerSwitchControlUI.displayName = 'MevrisWifiSmartDimmerSwitchControlUI';
exports.MevrisWifiSmartDimmerSwitchControlUI.defaultProps = {
    onColor: '#52D938',
    offColor: '#919EAB',
};
