"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DarkModeSetting = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const ModeName = {
    auto: 'Auto',
    dark: 'Dark',
    light: 'Light',
};
const DarkModeSetting = () => {
    const { __ } = (0, core_1.useIntl)();
    const { changeMode } = (0, core_1.useTheme)();
    const [themeMode] = (0, core_1.useConfig)('theme.mode');
    const [visible, setVisible] = (0, react_1.useState)(false);
    const toggle = () => setVisible(!visible);
    const changeModeSetting = (mode) => () => changeMode(mode);
    let title = 'User Preferences';
    let description = 'Appearance is detected automatically based on your device settings.';
    if (themeMode === 'dark') {
        title = 'Dark Mode';
        description = 'Dark mode is always on.';
    }
    if (themeMode === 'light') {
        title = 'Light Mode';
        description = 'Light mode is always on.';
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.List.Item, { left: react_1.default.createElement(components_1.List.Icon, { name: "brightness-3" }), title: __(title), description: __(description), onPress: toggle }),
        react_1.default.createElement(components_1.Dialog, { dismissable: true, visible: visible, onDismiss: toggle },
            react_1.default.createElement(components_1.List.Subheader, null, __('Mode')),
            react_1.default.createElement(components_1.Divider, null),
            react_1.default.createElement(components_1.List.Item, { title: __(ModeName.auto), onPress: changeModeSetting('auto'), selected: themeMode === 'auto' }),
            react_1.default.createElement(components_1.List.Item, { title: __(ModeName.light), onPress: changeModeSetting('light'), selected: themeMode === 'light' }),
            react_1.default.createElement(components_1.List.Item, { title: __(ModeName.dark), onPress: changeModeSetting('dark'), selected: themeMode === 'dark' }))));
};
exports.DarkModeSetting = DarkModeSetting;
exports.DarkModeSetting.displayName = 'DarkModeSetting';
