"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LevelSelectorWithSwitch = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const LevelSelector_1 = require("../LevelSelector/LevelSelector");
const LevelSelectorWithSwitch = (props) => {
    const { onSwitchChange, onValueChange, onColor, offColor } = props;
    const onPressHandler = () => {
        onSwitchChange(!props.switch);
    };
    const onValueChangeCustom = (0, react_1.useCallback)((value) => {
        // If switch is off, turn it on
        if (!props.switch && value > 0) {
            onSwitchChange(true);
        }
        // If value is 0, and switch if on, then we turn it off
        if (props.switch && value === 0) {
            onSwitchChange(false);
        }
        onValueChange(value);
    }, []);
    const filterItems = (0, react_1.useCallback)(({ items }) => {
        return [
            ...items,
            {
                onPress: onPressHandler,
                icon: 'power',
                // iconSize: 16,
                color: props.switch ? onColor : offColor,
                testID: 'level-switch',
            },
        ];
    }, [props.switch]);
    return (react_1.default.createElement(LevelSelector_1.LevelSelector, Object.assign({}, props, { value: !props.switch ? 0 : props.value, onValueChange: onValueChangeCustom, filterItems: filterItems })));
};
exports.LevelSelectorWithSwitch = LevelSelectorWithSwitch;
exports.LevelSelectorWithSwitch.defaultProps = {
    levels: 4,
    onColor: '#52D938',
    offColor: '#919EAB',
};
