"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductListItem = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const imports_1 = require("../../imports");
/**
 * ProductListItem used for listing of available products.
 */
const ProductListItem = (props) => {
    var _a;
    const { brand, id, avatar, link, name, placeholder, style, right, onPress, loading } = props;
    const { push } = (0, core_1.useNavigation)();
    if (loading === true) {
        return react_1.default.createElement(imports_1.PlaceholderListItem, { avatar: true, description: true, variant: "rounded" });
    }
    return (react_1.default.createElement(components_1.List.Item, { title: name ? name : 'Untitled', description: (_a = brand === null || brand === void 0 ? void 0 : brand.name) !== null && _a !== void 0 ? _a : 'Unknown Brand', left: react_1.default.createElement(components_1.List.Avatar, { variant: "rounded", type: "image", image: avatar ? avatar : placeholder }), right: right, style: style, onPress: link === true
            ? (0, react_1.useCallback)(() => push('Install', { id }), [id])
            : onPress }));
};
exports.ProductListItem = ProductListItem;
/**
 *  default props of ProductListItem
 * if no props are given then
 * ProductListItem will render with
 * these props
 */
exports.ProductListItem.defaultProps = {
    link: true,
    placeholder: 'ProductPlaceholder',
    width: 150,
};
exports.ProductListItem.displayName = 'ProductListItem';
