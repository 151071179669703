"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stepper = void 0;
const tslib_1 = require("tslib");
/* eslint-disable @typescript-eslint/typedef */
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const rn_placeholder_1 = require("rn-placeholder");
const getInRangeValue_1 = require("./getInRangeValue");
const defaultStyles = (theme) => ({
    root: {},
    fieldWrapper: {
        borderColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        borderWidth: 1,
        flexDirection: 'row',
    },
    buttonWrapper: {},
    button: {
        minWidth: theme.spacing.unit * (react_native_1.Platform.OS === 'web' ? 5 : 5),
        padding: theme.spacing.unit,
    },
    buttonIcon: {},
    buttonIconDisabled: {
        color: theme.palette.text.disabled,
    },
    textInputWrapper: {
        alignItems: 'stretch',
        flexDirection: 'row',
    },
    textInput: {
        justifyContent: 'center',
        width: 50,
        textAlign: 'center',
    },
    error: {
        color: theme.palette.error.main,
        paddingVertical: theme.spacing.unit,
    },
    errorBorderColor: {
        borderColor: theme.palette.error.main,
    },
    skeleton: { width: 130, borderRadius: theme.spacing.unit / 2 },
});
const defaultProps = {
    step: 1,
};
// Funcitonal version of Stepper component
const Stepper = (props) => {
    const { error, disabled, max, min, step, helperText, value, onChangeValue, loading } = props;
    const styles = (0, core_1.useStyles)('Stepper', props, defaultStyles);
    const valueString = (0, react_1.useMemo)(() => {
        var _a;
        return (value !== undefined
            ? (_a = (0, getInRangeValue_1.getInRangeValue)(value, { min, max })) === null || _a === void 0 ? void 0 : _a.toString()
            : '');
    }, [value, min, max]);
    const canDecrease = (0, react_1.useMemo)(() => {
        if (value === undefined) {
            return false;
        }
        if (min === undefined) {
            return true;
        }
        return value > min;
    }, [min, value, step]);
    const canIncrease = (0, react_1.useMemo)(() => {
        if (value === undefined) {
            return false;
        }
        if (max === undefined) {
            return true;
        }
        return value < max;
    }, [max, value, step]);
    const increment = (0, react_1.useCallback)(() => onChangeValue === null || onChangeValue === void 0 ? void 0 : onChangeValue(value + step), [step, value, onChangeValue]);
    const decrement = (0, react_1.useCallback)(() => onChangeValue === null || onChangeValue === void 0 ? void 0 : onChangeValue(value - step), [step, value, onChangeValue]);
    const isDecrementDisabled = disabled || !canDecrease;
    const isIncrementDisabled = disabled || !canIncrease;
    const setValue = (0, react_1.useCallback)((input) => {
        const v = (0, getInRangeValue_1.getInRangeValue)(input, { max, min });
        onChangeValue === null || onChangeValue === void 0 ? void 0 : onChangeValue(v);
    }, [max, min, onChangeValue]);
    if (loading) {
        return (react_1.default.createElement(rn_placeholder_1.Placeholder, { Animation: rn_placeholder_1.Fade },
            react_1.default.createElement(rn_placeholder_1.PlaceholderLine, { style: styles.skeleton, noMargin: true, height: 36 })));
    }
    return (react_1.default.createElement(components_1.View, { style: styles.root },
        react_1.default.createElement(components_1.View, { testID: "field-wrapper-view", style: Object.assign(Object.assign({}, styles.fieldWrapper), (error && styles.errorBorderColor)) },
            react_1.default.createElement(components_1.View, { style: styles.buttonWrapper },
                react_1.default.createElement(react_native_1.Pressable, { style: styles.button, onPress: isDecrementDisabled ? undefined : decrement, disabled: isDecrementDisabled, testID: "decrement-button" },
                    react_1.default.createElement(components_1.Icon, { name: "minus", style: Object.assign(Object.assign({}, styles.buttonIcon), (isDecrementDisabled && styles.buttonIconDisabled)) }))),
            react_1.default.createElement(components_1.View, { style: styles.textInputWrapper },
                react_1.default.createElement(react_native_1.TextInput, { keyboardType: "numeric", style: styles.textInput, onChangeText: setValue, value: valueString, editable: !disabled, min, max, step })),
            react_1.default.createElement(components_1.View, { style: styles.buttonWrapper },
                react_1.default.createElement(react_native_1.Pressable, { style: styles.button, disabled: isIncrementDisabled, onPress: isIncrementDisabled ? undefined : increment, testID: "increment-button" },
                    react_1.default.createElement(components_1.Icon, { name: "plus", style: Object.assign(Object.assign({}, styles.buttonIcon), (isIncrementDisabled && styles.buttonIconDisabled)) })))),
        helperText && react_1.default.createElement(components_1.Body2, { style: error && styles.error }, helperText)));
};
exports.Stepper = Stepper;
exports.Stepper.defaultProps = defaultProps;
