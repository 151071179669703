"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceProvider = exports.DeviceContext = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const hooks_1 = require("../../hooks");
exports.DeviceContext = (0, react_1.createContext)(undefined);
const DeviceProvider = (props) => {
    const { children, id } = props;
    const ctx = (0, hooks_1.useDeviceBuilder)(id);
    return react_1.default.createElement(exports.DeviceContext.Provider, { value: ctx }, children);
};
exports.DeviceProvider = DeviceProvider;
exports.DeviceProvider.defaultProps = {};
exports.DeviceProvider.displayName = 'DeviceProvider';
