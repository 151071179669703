"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Power = void 0;
const helpers_1 = require("../helpers");
const types_1 = require("../types");
// tslint:disable-next-line: variable-name
exports.Power = {
    key: 'Power',
    extends: 'Number',
    unit: 'W',
    unitPosition: 'after',
    availableUnits: (0, helpers_1.convert)().possibilities('power'),
    converters: [
        {
            type: types_1.DataSchemaConverterType.RULE,
            value: {
                'convert.toBest.value': [{ var: 'value' }, { var: 'schema.unit' }, { var: 'opts' }],
            },
            data: {
                opts: { exclude: ['PS'] },
            }
        },
    ],
    printer: {
        type: types_1.DataSchemaPrinterType.RULE,
        value: {
            cat: [
                {
                    'convert.value': [{ var: 'value' }, { var: 'schema' }],
                },
                ' ',
                {
                    'convert.toBest.unit': [{ var: 'value' }, { var: 'schema.unit' }, { var: 'opts' }],
                },
            ],
        },
        data: {
            opts: { exclude: ['PS'] },
        }
    },
};
