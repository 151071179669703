"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityMultiLevelSelector = void 0;
const tslib_1 = require("tslib");
const client_1 = require("@apollo/client");
const client_graphql_1 = require("@mevris/client-graphql");
const react_1 = tslib_1.__importStar(require("react"));
const helpers_1 = require("../../helpers");
const imports_1 = require("../../imports");
function entityToMultiLevelSelectorItem(entity) {
    if (!entity) {
        return;
    }
    return {
        id: entity.id,
        title: entity.name,
        description: entity.description,
        parentId: entity.parentId,
        hasChildren: !!entity.childrenCount,
        count: entity.childrenCount,
        data: (0, client_graphql_1.removeTypeName)(entity),
        avatar: {
            type: 'image',
            variant: 'rounded',
            image: entity.avatar ? { uri: entity.avatar } : (0, helpers_1.getEntityPlaceholder)(entity.category),
        },
    };
}
const EntityMultiLevelSelector = (props) => {
    const [getTree] = (0, client_1.useLazyQuery)(client_graphql_1.EntityTreeQuery, {
        variables: {
            filter: props.filter,
            orderBy: props.orderBy,
        }
    });
    const getItems = (0, react_1.useCallback)((id, done) => {
        getTree({ variables: { id } })
            .then(({ data }) => {
            var _a, _b, _c;
            const node = entityToMultiLevelSelectorItem((_a = data === null || data === void 0 ? void 0 : data.entityTree) === null || _a === void 0 ? void 0 : _a.node);
            const children = (((_b = data === null || data === void 0 ? void 0 : data.entityTree) === null || _b === void 0 ? void 0 : _b.children) || [])
                .map(entityToMultiLevelSelectorItem)
                .filter(x => x);
            const ancestors = (((_c = data === null || data === void 0 ? void 0 : data.entityTree) === null || _c === void 0 ? void 0 : _c.ancestors) || [])
                .map(entityToMultiLevelSelectorItem)
                .filter(x => x);
            done({
                node,
                children,
                ancestors,
            });
        })
            .catch(err => {
            // console.error(err);
            done(undefined, err);
        });
    }, []);
    return (react_1.default.createElement(imports_1.MultiLevelSelector, Object.assign({}, props, { getItems: getItems })));
};
exports.EntityMultiLevelSelector = EntityMultiLevelSelector;
exports.EntityMultiLevelSelector.defaultProps = {};
exports.EntityMultiLevelSelector.displayName = 'EntityMultiLevelSelector';
