"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityUploadAvatarMutation = void 0;
const client_1 = require("@apollo/client");
exports.EntityUploadAvatarMutation = client_1.gql `
	mutation EntityUploadAvatarMutation($file: Upload!, $entityId: ID!, $avatarSize: Int) {
		entity {
			uploadAvatar(file: $file, id: $entityId) {
				id
				avatar(width: $avatarSize, height: $avatarSize)
				thumbnail: avatar(width: 5, height: 5)
			}
		}
	}
`;
