"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityInfoQuery = void 0;
const client_1 = require("@apollo/client");
exports.EntityInfoQuery = client_1.gql `
	query EntityInfoQuery($id: ID!, $avatarSize: Int) {
		entity(id: $id) {
			id
			secondaryId
			name
			avatar(width: $avatarSize, height: $avatarSize)
			thumbnail: avatar(width: 5, height: 5)
			category
			deviceTypeHandle
			parentId
			schemaId

			uiResolutionKeys
		}
	}
`;
