"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAttributeSetter = void 0;
const client_1 = require("@apollo/client");
const core_1 = require("@bluebase/core");
const device_controller_1 = require("@mevris/device-controller");
const react_1 = require("react");
const useDevice_1 = require("../useDevice");
const updateStore_1 = require("../useDeviceBuilder/updateStore");
function useAttributeSetter() {
    const BB = (0, core_1.useBlueBase)();
    const apollo = (0, client_1.useApolloClient)();
    const { device, id } = (0, useDevice_1.useDevice)();
    const [timeoutId, setTimeoutId] = (0, react_1.useState)();
    async function setAttributes(attributesMap) {
        if (!device) {
            const result = {
                status: device_controller_1.RESPONSE_STATUS_ERROR,
                error: new Error('Could not set attribute. Reason: No device found.'),
            };
            BB.Logger.error(result);
            return result;
        }
        // If we have an active timer to get attribute, cancel it
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(undefined);
            BB.Logger.info('Cancelling device attribute sync timeout and setting new attribute', {
                id,
                attributesMap,
            });
        }
        // Optimistically update store
        (0, updateStore_1.updateStore)({ attributesMap, BB, apollo, id });
        // Attempt to set device attribute
        const response = await device.setAttributes({ attributesMap });
        // If setting attribute is a success, we start a timer
        // to get a fresh attribute after 3 seconds, just in case
        // if the attribute sync failed somehow
        if (response.status === device_controller_1.RESPONSE_STATUS_SUCCESS) {
            BB.Logger.info('Device setAttributes complete', { id, attributesMap });
            const timeout = setTimeout(() => {
                device.requestAttributes();
                setTimeoutId(undefined);
                BB.Logger.info('Device attribute synced 3 seconds after successfull command', {
                    id,
                    attributesMap,
                });
            }, 3000);
            setTimeoutId(timeout);
        }
        // If setting attribute fails, we try force get a fresh attribute
        else {
            BB.Logger.error('Device setAttributes error', {
                error: response.error,
                id,
                attributesMap,
            });
            device.requestAttributes();
        }
        return response;
    }
    return setAttributes;
}
exports.useAttributeSetter = useAttributeSetter;
