"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserVerifyAndUpdateEmailMutation = void 0;
const client_1 = require("@apollo/client");
exports.UserVerifyAndUpdateEmailMutation = client_1.gql `
	mutation UserVerifyAndUpdateEmailMutation($email: String!, $verificationCode: String!, $avatarSize: Int) {
		user {
			verifyAndUpdateEmail(email: $email, verificationCode: $verificationCode) {
				id
				username
				name
				customData
				email
				emailVerified
				phoneNumber
				phoneNumberVerified
				firstName
				lastName
				roles
				avatar(width: $avatarSize, height: $avatarSize)
				thumbnail: avatar(width: 5, height: 5)
			}
		}
	}
`;
