"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DATE = void 0;
exports.DATE = {
    transform: (value, _schema) => {
        if (value === undefined || value === null) {
            return value;
        }
        return new Date(value);
    },
    convert: (value, _schema) => {
        return new Date(value);
    },
    validate: (value, schema) => {
        const date = new Date(value);
        if (isNaN(date.getTime())) {
            return false;
        }
        if (schema.values) {
            const exists = schema.values.find((v) => v.value === date.getTime());
            if (!exists) {
                return false;
            }
        }
        return true;
    },
};
