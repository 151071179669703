"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResponsiveListSizes = exports.MAX_CONTAINER_WIDTH = void 0;
const tslib_1 = require("tslib");
const core_1 = require("@bluebase/core");
const hooks_1 = require("@react-native-community/hooks");
// tslint:disable: object-literal-sort-keys
exports.MAX_CONTAINER_WIDTH = {
    xs: '100%',
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1140,
};
const DefaultColumnMap = {
    xs: 2,
    sm: 3,
    md: 4,
    lg: 5,
    xl: 5,
};
/**
 * Calculates the grid item sizes dynamically based on screen size
 */
function useResponsiveListSizes(
// layout: LayoutRectangle,
columnMap = DefaultColumnMap) {
    const { theme } = (0, core_1.useTheme)();
    const _a = (0, hooks_1.useLayout)(), { onLayout } = _a, layout = tslib_1.__rest(_a, ["onLayout"]);
    if (layout.width === 0) {
        return {
            onLayout,
            sizes: {
                containerPadding: 0,
                containerWidth: 0,
                itemPadding: 0,
                itemWidth: 0,
                numColumns: 0,
            },
        };
    }
    const spacing = theme.spacing.unit;
    const componentWidth = layout.width;
    const screenSize = (0, core_1.getScreenSizeFromWidth)(componentWidth);
    const numColumns = columnMap[screenSize];
    const containerWidth = exports.MAX_CONTAINER_WIDTH[screenSize];
    const containerSize = typeof containerWidth === 'string' ? componentWidth : containerWidth;
    const itemWidth = (containerSize - spacing * 2 * (numColumns + 1)) / numColumns;
    const sizes = {
        containerPadding: spacing,
        containerWidth,
        itemPadding: spacing,
        itemWidth,
        numColumns,
    };
    return { sizes, onLayout };
}
exports.useResponsiveListSizes = useResponsiveListSizes;
