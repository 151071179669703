"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const defaultStyles = (theme, { minimumTrackTintColor }) => ({
    root: {
        backgroundColor: minimumTrackTintColor || theme.palette.primary.main,
    },
});
const FilledTrack = (props) => {
    const { position, filledTrackProps, filledTrackStyle, onPress } = props;
    const styles = (0, core_1.useStyles)('SliderFilledTrack', props, defaultStyles);
    const { height, width, x, y } = position;
    return (react_1.default.createElement(react_native_1.TouchableWithoutFeedback, { onPressIn: onPress },
        react_1.default.createElement(components_1.View, Object.assign({}, filledTrackProps, { style: [
                Object.assign({ borderRadius: height / 2, height, left: x, position: 'absolute', top: y, width }, filledTrackStyle),
                styles.root,
            ] }))));
};
exports.default = (0, react_1.memo)(FilledTrack);
FilledTrack.displayName = 'FilledTrack';
