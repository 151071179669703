"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderMedia = exports.PlaceholderOverline = exports.PlaceholderListItem = exports.PlaceholderSubtitle1 = exports.PlaceholderH4 = exports.PlaceholderBody2 = exports.PlaceholderBody1 = exports.Placeholder = void 0;
const components_1 = require("@bluebase/components");
exports.Placeholder = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'Placeholder',
})('Placeholder');
exports.Placeholder.displayName = 'Placeholder';
exports.PlaceholderBody1 = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderBody1',
})('PlaceholderBody1');
exports.PlaceholderBody1.displayName = 'PlaceholderBody1';
exports.PlaceholderBody2 = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderBody2',
})('PlaceholderBody2');
exports.PlaceholderBody2.displayName = 'PlaceholderBody2';
exports.PlaceholderH4 = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderH4',
})('PlaceholderH4');
exports.PlaceholderH4.displayName = 'PlaceholderH4';
exports.PlaceholderSubtitle1 = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderSubtitle1',
})('PlaceholderSubtitle1');
exports.PlaceholderSubtitle1.displayName = 'PlaceholderSubtitle1';
exports.PlaceholderListItem = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderListItem',
})('PlaceholderListItem');
exports.PlaceholderOverline = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderOverline',
})('PlaceholderOverline');
exports.PlaceholderOverline.displayName = 'PlaceholderOverline';
exports.PlaceholderMedia = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderMedia',
})('PlaceholderMedia');
