"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useImagePicker = void 0;
const tslib_1 = require("tslib");
/* istanbul ignore file */
const components_1 = require("@bluebase/components");
const client_plugin_ui_1 = require("@blueeast/client-plugin-ui");
const expo_image_picker_1 = require("expo-image-picker");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
function useImagePicker(options) {
    const { title, message } = Object.assign({ title: 'Select Picture' }, options);
    const { alert } = (0, client_plugin_ui_1.useAlert)();
    const { showActionSheetOrDialogWithOptions } = (0, client_plugin_ui_1.useActionSheet)();
    // ****************** //
    // * Event Handlers * //
    // ****************** //
    const selectFromImageCamera = (0, react_1.useCallback)(async (onImageSelect, opts) => {
        const { cameraOptions } = opts;
        const cameraPermission = await (0, expo_image_picker_1.getCameraPermissionsAsync)();
        if (cameraPermission.status === expo_image_picker_1.PermissionStatus.GRANTED) {
            const result = await (0, expo_image_picker_1.launchCameraAsync)(cameraOptions);
            onImageSelect(result);
            return;
        }
        if (cameraPermission.status === expo_image_picker_1.PermissionStatus.UNDETERMINED || cameraPermission.canAskAgain) {
            await (0, expo_image_picker_1.requestCameraPermissionsAsync)();
            selectFromImageCamera(onImageSelect, opts);
            return;
        }
        alert('Camera Roll Permission denied!');
    }, []);
    const selectFromImageLibrary = (0, react_1.useCallback)(async (onImageSelect, opts) => {
        const { libraryOptions } = opts;
        const libraryPermission = await (0, expo_image_picker_1.getMediaLibraryPermissionsAsync)();
        if (libraryPermission.status === expo_image_picker_1.PermissionStatus.GRANTED) {
            const result = await (0, expo_image_picker_1.launchImageLibraryAsync)(libraryOptions);
            onImageSelect(result);
            return;
        }
        if (libraryPermission.status === expo_image_picker_1.PermissionStatus.UNDETERMINED || libraryPermission.canAskAgain) {
            await (0, expo_image_picker_1.requestMediaLibraryPermissionsAsync)();
            selectFromImageLibrary(onImageSelect, opts);
            return;
        }
        alert('Camera Roll Permission denied!');
    }, []);
    const show = (0, react_1.useCallback)(async (onImageSelect, opts = {}) => {
        const { libraryOptions } = opts;
        // On web we don't need any permissions as such
        if (react_native_1.Platform.OS === 'web') {
            const result = await (0, expo_image_picker_1.launchImageLibraryAsync)(libraryOptions);
            onImageSelect(result);
            return;
        }
        showActionSheetOrDialogWithOptions({
            cancelButtonIndex: 2,
            icons: [
                react_1.default.createElement(components_1.Icon, { name: "image", key: "library" }),
                react_1.default.createElement(components_1.Icon, { name: "camera", key: "camera" }),
                react_1.default.createElement(components_1.Icon, { name: "cancel", key: "cancel" }),
            ],
            message,
            options: ['Select from Library', 'Select from Camera', 'Cancel'],
            showSeparators: true,
            title,
        }, (index) => {
            if (index === 0) {
                selectFromImageLibrary(onImageSelect, opts);
            }
            else if (index === 1) {
                selectFromImageCamera(onImageSelect, opts);
            }
        });
    }, [selectFromImageLibrary, selectFromImageCamera]);
    return show;
}
exports.useImagePicker = useImagePicker;
