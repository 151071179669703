"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEntityPlaceholder = void 0;
const client_graphql_1 = require("@mevris/client-graphql");
function getEntityPlaceholder(category) {
    switch (category) {
        case client_graphql_1.DeviceTypeCategory.Person:
            return ['PersonPlaceholder', 'Placeholder'];
        case client_graphql_1.DeviceTypeCategory.Place:
            return ['PlacePlaceholder', 'Placeholder'];
        case client_graphql_1.DeviceTypeCategory.Thing:
            return ['ThingPlaceholder', 'Placeholder'];
        default:
            return ['Placeholder'];
    }
}
exports.getEntityPlaceholder = getEntityPlaceholder;
