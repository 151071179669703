"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LightDimmableControlUIView = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const universal_data_schema_1 = require("@mevris/universal-data-schema");
const react_1 = tslib_1.__importStar(require("react"));
const constants_1 = require("../../constants");
const parts_1 = require("../../parts");
const defaultStyles = (theme) => ({
    root: {
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'center',
        // padding: 8,
        // backgroundColor: '#fff',
        // width: BUTTON_WIDTH + 16,
        // height: BUTTON_HEIGHT + 16,
        // borderRadius: theme.shape.borderRadius * 4,
    },
    slider: Object.assign({ backgroundColor: '#ccc', overflow: 'hidden', padding: theme.spacing.unit / 4, borderColor: theme.palette.background.card, borderRadius: theme.shape.borderRadius * 4, borderWidth: theme.spacing.unit / 2 }, theme.elevation(4)),
    tank: {
        margin: 1.5,
        borderRadius: theme.shape.borderRadius * 4,
        borderColor: theme.palette.background.card,
        padding: 8,
        // backgroundColor: '#fff',
    },
});
const LightDimmableControlUIView = (props) => {
    const { styles: _s, style, data, onStateChange } = props, rest = tslib_1.__rest(props, ["styles", "style", "data", "onStateChange"]);
    const styles = (0, core_1.useStyles)('LightDimmableControlUIView', props, defaultStyles);
    const { on, brightness } = data;
    const { maximumValue, minimumValue, step } = (0, universal_data_schema_1.resolveDataSchema)(brightness.schema);
    const onValueChange = (0, react_1.useCallback)((value) => {
        onStateChange({
            brightness: value,
            on: value !== minimumValue,
        });
    }, []);
    const icon = brightness.value === minimumValue ? 'lightbulb-outline' : 'lightbulb-on';
    return (react_1.default.createElement(components_1.View, { style: [styles.root] },
        react_1.default.createElement(parts_1.SliderManager, Object.assign({ Slider: parts_1.TankSlider, style: styles.tank, height: constants_1.BUTTON_HEIGHT * 1.5, width: constants_1.BUTTON_WIDTH * 1.5, minimumTrackTintColor: "#FFD54F", vertical: true }, rest, { onValueChange: onValueChange, sliderStyle: styles.slider, minimumValue: minimumValue, maximumValue: maximumValue, step: step, data: Object.assign(Object.assign({}, data.brightness), { value: !on.value ? 0 : brightness.value }) }),
            react_1.default.createElement(components_1.Icon, { name: icon, color: "#FFF", size: 40, style: { position: 'absolute', bottom: 16, userSelect: 'none', cursor: 'pointer' } }))));
};
exports.LightDimmableControlUIView = LightDimmableControlUIView;
exports.LightDimmableControlUIView.displayName = 'LightDimmableControlUIView';
exports.LightDimmableControlUIView.defaultProps = {};
