"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SliderControlItem = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const universal_data_schema_1 = require("@mevris/universal-data-schema");
const react_1 = tslib_1.__importStar(require("react"));
const SliderControlItem = (props) => {
    const { title, value, style, onValueChange } = props;
    const { theme } = (0, core_1.useTheme)();
    const [tempValue, setTempValue] = (0, react_1.useState)(value);
    const [sliding, setSliding] = (0, react_1.useState)(false);
    const schema = (0, universal_data_schema_1.resolveDataSchema)(props.schema);
    const value1 = sliding ? tempValue : value;
    const onSlidingStart = (0, react_1.useCallback)((v) => {
        setSliding(true);
        setTempValue(v);
    }, []);
    const onChangeValue = (0, react_1.useCallback)((v) => {
        setTempValue(v);
    }, []);
    const onSlidingComplete = (0, react_1.useCallback)((v) => {
        setTempValue(v);
        onValueChange(v);
    }, []);
    return (react_1.default.createElement(components_1.View, { style: Object.assign({ padding: theme.spacing.unit * 2 }, style) },
        react_1.default.createElement(components_1.Slider, { label: title, onSlidingComplete: onSlidingComplete, onSlidingStart: onSlidingStart, onValueChange: onChangeValue, value: value1, maximumValue: schema.maximumValue, minimumValue: schema.minimumValue, step: schema.step })));
};
exports.SliderControlItem = SliderControlItem;
exports.SliderControlItem.displayName = 'SliderControlItem';
