"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TraitUploadAvatarMutation = void 0;
const client_1 = require("@apollo/client");
exports.TraitUploadAvatarMutation = client_1.gql `
	mutation TraitUploadAvatarMutation($file: Upload!, $traitId: ID!, $avatarSize: Int) {
		trait {
			uploadAvatar(file: $file, id: $traitId) {
				id
				avatar(width: $avatarSize, height: $avatarSize)
				thumbnail: avatar(width: 5, height: 5)
			}
		}
	}
`;
