"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceTypeListItem = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const imports_1 = require("../../imports");
const DeviceTypeListItem = (props) => {
    const { id, name, loading, link, onPress } = props;
    const { push } = (0, core_1.useNavigation)();
    if (loading === true) {
        return react_1.default.createElement(imports_1.PlaceholderListItem, null);
    }
    return (react_1.default.createElement(components_1.List.Item, Object.assign({ title: name ? name : 'Untitled' }, props, { onPress: link === true
            ? (0, react_1.useCallback)(() => push('DeviceTypeProfile', { deviceTypeId: id }), [])
            : onPress })));
};
exports.DeviceTypeListItem = DeviceTypeListItem;
exports.DeviceTypeListItem.defaultProps = {
    placeholder: 'DeviceTypePlaceholder',
};
exports.DeviceTypeListItem.displayName = 'DeviceTypeListItem';
