"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandCard = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const imports_1 = require("../../imports");
const defaultStyles = (theme) => ({
    root: {},
    avatarContainer: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        overflow: 'hidden',
    },
    avatar: {
        backgroundColor: theme.palette.background.dark,
    },
    content: {
        padding: theme.spacing.unit,
    },
    brand: {
        color: theme.palette.text.disabled,
    },
    name: {
    // paddingTop: theme.spacing.unit / 2,
    },
});
/**
 * BrandCard used for listing of available brands.
 */
const BrandCard = (props) => {
    const { id, avatar, thumbnail, link, name, placeholder, width, loading, onPress, } = props;
    const styles = (0, core_1.useStyles)('BrandCard', props, defaultStyles);
    const { push, route } = (0, core_1.useNavigation)();
    const params = route.params;
    if (loading === true) {
        return react_1.default.createElement(imports_1.PlaceholderCard, { width: width });
    }
    return (react_1.default.createElement(components_1.Card, { style: Object.assign(Object.assign({}, styles.root), { width }), onPress: link === true
            ? (0, react_1.useCallback)(() => push('BrandProfile', Object.assign({ id }, params)), [])
            : onPress },
        react_1.default.createElement(components_1.View, { style: styles.avatarContainer },
            react_1.default.createElement(imports_1.ProgressiveImage, { placeholder: placeholder, thumbnail: thumbnail, source: avatar, style: Object.assign(Object.assign({}, styles.avatar), { height: width, width }) })),
        react_1.default.createElement(components_1.View, { style: styles.content },
            react_1.default.createElement(components_1.Subtitle2, { testID: "brandcard-name-text", ellipsizeMode: "tail", numberOfLines: 1, style: styles.name }, name ? name : 'Untitled'))));
};
exports.BrandCard = BrandCard;
exports.BrandCard.defaultProps = {
    link: true,
    placeholder: 'BrandPlaceholder',
    width: 150,
};
exports.BrandCard.displayName = 'BrandCard';
