"use strict";
/* istanbul ignore file */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.settings = void 0;
exports.settings = {
    CallSupportSetting: Promise.resolve().then(() => __importStar(require('./CallSupportSetting'))),
    DarkModeSetting: Promise.resolve().then(() => __importStar(require('./DarkModeSetting'))),
    DeveloperSetting: Promise.resolve().then(() => __importStar(require('./DeveloperSetting'))),
    EmailSupportSetting: Promise.resolve().then(() => __importStar(require('./EmailSupportSetting'))),
    LanguageSetting: Promise.resolve().then(() => __importStar(require('./LanguageSetting'))),
    PrivacyPolicySetting: Promise.resolve().then(() => __importStar(require('./PrivacyPolicySetting'))),
    TermsOfServiceSetting: Promise.resolve().then(() => __importStar(require('./TermsOfServiceSetting'))),
    TextDirectionSetting: Promise.resolve().then(() => __importStar(require('./TextDirectionSetting'))),
    ThemeSelectionSetting: Promise.resolve().then(() => __importStar(require('./ThemeSelectionSetting'))),
    VersionSetting: Promise.resolve().then(() => __importStar(require('./VersionSetting'))),
};
