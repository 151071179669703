"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandGrid = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const client_plugin_ui_1 = require("@blueeast/client-plugin-ui");
const client_graphql_1 = require("@mevris/client-graphql");
const react_1 = tslib_1.__importStar(require("react"));
const BrandListEmptyState_1 = require("../BrandListEmptyState");
const BrandImage = (0, core_1.getComponent)('BrandImage');
const GraphqlList = (0, core_1.getComponent)('GraphqlList');
/**
 * Maps Query result to data. This data is then send to the FlatList and
 * its items to use for UI rendering
 */
function mapQueryResultToListData(result) {
    var _a, _b;
    // Should we add skeleton items?
    let appendSkeletons = 0;
    // If there is no data, show multiple to fill the screen
    if (result.networkStatus === 1) {
        appendSkeletons = 4;
    }
    // If there is already content, and we're fetching next page
    // add one skeleton item to show loading
    else if (result.networkStatus === 3) {
        appendSkeletons = 1;
    }
    // Extract Data
    const edges = (_b = (_a = mapQueryResultToConnection(result)) === null || _a === void 0 ? void 0 : _a.edges) !== null && _b !== void 0 ? _b : [];
    const data = edges.map(edge => (Object.assign(Object.assign({}, edge.node), { avatar: edge.node.avatar ? { uri: edge.node.avatar } : undefined, thumbnail: edge.node.thumbnail ? { uri: edge.node.thumbnail } : undefined })));
    // Append skeletons to data
    return [...data, ...new Array(appendSkeletons).fill({ loading: true })];
}
function mapQueryResultToConnection(result) {
    var _a;
    return (_a = result.data) === null || _a === void 0 ? void 0 : _a.brands;
}
const BrandGrid = (props) => {
    const { contentContainerStyle, queryOptions, loading, columnMap, horizontal, onPress } = props;
    const { sizes, onLayout } = (0, client_plugin_ui_1.useResponsiveListSizes)(columnMap);
    const onPressFunction = (0, react_1.useCallback)((item) => {
        return () => onPress(item);
    }, []);
    const renderItem = (0, react_1.useCallback)(({ item }) => {
        const onPressFn = onPress ? onPressFunction(item) : undefined;
        return (react_1.default.createElement(components_1.View, { testID: "list-item", style: { padding: sizes.itemPadding } },
            react_1.default.createElement(BrandImage, Object.assign({ onPress: onPressFn, width: !horizontal ? sizes.itemWidth / 2 : (sizes.itemWidth / 2) - 16 }, item))));
    }, [onPress, sizes, onPressFunction]);
    return (react_1.default.createElement(components_1.View, { style: { flex: 1 }, onLayout: onLayout, testID: "brand-grid-root" }, sizes.itemWidth > 0 ? (react_1.default.createElement(GraphqlList, Object.assign({ loading: loading, query: client_graphql_1.BrandListQuery, updateQueryInfinitePagination: client_graphql_1.BrandListQueryUpdateQueryFn, numColumns: props.horizontal ? undefined : sizes.numColumns, mapQueryResultToListData: mapQueryResultToListData, mapQueryResultToConnection: mapQueryResultToConnection, renderItem: renderItem, ListEmptyComponent: BrandListEmptyState_1.BrandListEmptyState }, props, { queryOptions: Object.assign(Object.assign({}, queryOptions), { variables: Object.assign({ avatarSize: Math.ceil(sizes.itemWidth) }, queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.variables) }), contentContainerStyle: [
            {
                // alignItems: 'center',
                alignSelf: (0, core_1.isMobile)() ? undefined : 'center',
                padding: sizes.containerPadding,
                width: sizes.containerWidth,
            },
            contentContainerStyle,
        ] }))) : null));
};
exports.BrandGrid = BrandGrid;
exports.BrandGrid.displayName = 'BrandGrid';
