"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Time = void 0;
const types_1 = require("../types");
exports.Time = {
    key: 'Time',
    dataType: 'DATE',
    printer: {
        type: types_1.DataSchemaPrinterType.TEMPLATE,
        value: '{{ new Date(value).toLocaleTimeString() }}',
    },
};
