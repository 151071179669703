"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSwitchSound = exports.useSwitchOffSound = exports.useSwitchOnSound = exports.useSound = void 0;
const core_1 = require("@bluebase/core");
const expo_av_1 = require("expo-av");
function useSound(source) {
    const BB = (0, core_1.useBlueBase)();
    async function play() {
        var _a;
        const asset = typeof source === 'string' && BB.Assets.has(source)
            ? (_a = BB.Assets.resolve(source)) === null || _a === void 0 ? void 0 : _a.value
            : source;
        try {
            await expo_av_1.Audio.Sound.createAsync(asset, { shouldPlay: true });
        }
        catch (e) {
            BB.Logger.error('Sound error', e);
        }
    }
    return play;
}
exports.useSound = useSound;
function useSwitchOnSound() {
    return useSound('SwitchOnSound');
}
exports.useSwitchOnSound = useSwitchOnSound;
function useSwitchOffSound() {
    return useSound('SwitchOffSound');
}
exports.useSwitchOffSound = useSwitchOffSound;
function useSwitchSound() {
    const playSwitchOnSound = useSwitchOnSound();
    const playSwitchOffSound = useSwitchOffSound();
    return (value) => {
        if (value) {
            playSwitchOnSound();
        }
        else {
            playSwitchOffSound();
        }
    };
}
exports.useSwitchSound = useSwitchSound;
