"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
// import { TextInput } from 'react-native';
const core_1 = require("@bluebase/core");
const JsonForm_1 = require("./JsonForm");
const JsonLayout_1 = require("./JsonLayout");
const version_1 = require("./version");
tslib_1.__exportStar(require("./JsonForm"), exports);
tslib_1.__exportStar(require("./JsonLayout"), exports);
tslib_1.__exportStar(require("./JsonSchemaParser"), exports);
exports.default = (0, core_1.createPlugin)({
    categories: ['ui'],
    description: 'A utility to build pluggable components based on JSON schema!',
    key: 'plugin-json-schema-components',
    name: 'Json Schema Components',
    version: version_1.VERSION,
    components: {
        BaseFormField: JsonForm_1.BaseFormField,
        FormArrayField: JsonForm_1.FormArrayField,
        FormCheckboxInput: JsonForm_1.FormCheckboxInput,
        FormEmailInput: JsonForm_1.FormEmailInput,
        FormGroupField: JsonForm_1.FormGroupField,
        FormNumberInput: JsonForm_1.FormNumberInput,
        FormPasswordInput: JsonForm_1.FormPasswordInput,
        FormPickerInput: JsonForm_1.FormPickerInput,
        FormRadioGroupInput: JsonForm_1.FormRadioGroupInput,
        FormRangeInput: JsonForm_1.FormRangeInput,
        FormResetButton: JsonForm_1.FormResetButton,
        FormSimpleArrayField: JsonForm_1.FormSimpleArrayField,
        FormStatus: JsonForm_1.FormStatus,
        FormStatusList: JsonForm_1.FormStatusList,
        FormStatusListItem: JsonForm_1.FormStatusListItem,
        FormSubmitButton: JsonForm_1.FormSubmitButton,
        FormSwitchInput: JsonForm_1.FormSwitchInput,
        FormTextInput: JsonForm_1.FormTextInput,
        FormUrlInput: JsonForm_1.FormUrlInput,
        InlineFields: JsonForm_1.InlineFields,
        JsonForm: JsonForm_1.JsonForm,
        JsonLayout: JsonLayout_1.JsonLayout,
    },
});
