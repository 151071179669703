"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const defaultStyles = (theme, { thumbTintColor, thumbSize }) => ({
    root: Object.assign({ backgroundColor: thumbTintColor || theme.palette.background.card, borderRadius: thumbSize !== undefined ? thumbSize / 2 : theme.shape.borderRadius }, theme.elevation(5)),
});
const Thumb = (0, react_1.memo)((props) => {
    const { thumbSize, thumbProps, thumbStyle, position } = props;
    const styles = (0, core_1.useStyles)('SliderThumb', props, defaultStyles);
    const { x, y } = position;
    return (react_1.default.createElement(react_native_1.TouchableWithoutFeedback, null,
        react_1.default.createElement(components_1.View, Object.assign({}, thumbProps, { style: [
                {
                    height: thumbSize,
                    left: x !== undefined ? x - thumbSize / 2 : undefined,
                    position: 'absolute',
                    top: y !== undefined ? y - thumbSize / 2 : undefined,
                    width: thumbSize,
                },
                styles.root,
                thumbProps ? thumbProps.style : {},
                thumbStyle,
            ] }))));
});
Thumb.displayName = 'Thumb';
exports.default = Thumb;
