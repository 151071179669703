"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Currency = void 0;
const types_1 = require("../types");
exports.Currency = {
    key: 'Currency',
    extends: 'Number',
    // Example: 26 => Rs. 26
    printer: {
        type: types_1.DataSchemaPrinterType.TEMPLATE,
        value: 'Rs. {{ value }}',
    },
};
