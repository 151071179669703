"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CircularSlider = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const BaseCircularSlider_1 = require("./BaseCircularSlider");
// const defaultStyles = (): CircularSliderStyles => ({
// 	sliderContainer: {
// 		flex: 1,
// 	},
// });
exports.CircularSlider = (0, react_1.memo)((props) => {
    const { size } = props;
    // const styles = useStyles('CircularSlider', props, defaultStyles);
    const sliderRef = (0, react_1.useRef)(null);
    const [layout, setLayout] = (0, react_1.useState)(props.layout);
    /* istanbul ignore next */
    const onLayout = (0, react_1.useCallback)(() => {
        if (!sliderRef || !sliderRef.current) {
            return;
        }
        sliderRef.current.measure((_x, _y, _width, _height, pageX, pageY) => {
            setLayout({ x: pageX, y: pageY, height: _height, width: _width });
        });
    }, [sliderRef]);
    return (react_1.default.createElement(react_native_1.View, { testID: "slider-container", onLayout: onLayout, ref: sliderRef, style: [{ width: size, height: size }, props.sliderStyle] }, layout ? react_1.default.createElement(BaseCircularSlider_1.BaseCircularSlider, Object.assign({}, props, { layout: layout })) : null));
});
// CircularSlider.defaultProps = {};
exports.CircularSlider.displayName = 'CircularSlider';
