"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardStepUI = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const hooks_1 = require("@react-native-community/hooks");
const elements_1 = require("@react-navigation/elements");
const react_1 = tslib_1.__importStar(require("react"));
const defaultStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    header: {
        backgroundColor: theme.palette.secondary.dark,
        paddingHorizontal: theme.spacing.unit * 2,
        paddingVertical: theme.spacing.unit * 1.5,
    },
    titleWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    backButtonWrapper: {
        marginRight: theme.spacing.unit,
    },
    backButtonIconColor: theme.palette.primary.contrastText,
    title: {
        color: theme.palette.secondary.contrastText,
        fontWeight: 'bold',
        marginVertical: theme.spacing.unit / 2,
    },
    description: {
        color: theme.palette.secondary.contrastText,
        marginVertical: theme.spacing.unit / 2,
    },
    body: {
        padding: theme.spacing.unit,
        flexGrow: 1
    },
});
const WizardStepUI = (props) => {
    const { title, description, children, onBack } = props;
    const { setOptions } = (0, core_1.useNavigation)();
    const styles = (0, core_1.useStyles)('WizardStepUI', props, defaultStyles);
    const mobile = (0, core_1.isMobile)();
    (0, hooks_1.useBackHandler)(() => {
        if (onBack) {
            onBack();
            return true;
        }
        // let the default thing happen
        return false;
    });
    (0, react_1.useLayoutEffect)(() => {
        if (mobile) {
            setOptions({
                title,
                headerLeft: onBack ?
                    (props) => (react_1.default.createElement(elements_1.HeaderBackButton, Object.assign({}, props, { label: "Back", labelVisible: true, onPress: onBack })))
                    : undefined
            });
        }
    }, [title, onBack, mobile]);
    if (mobile) {
        return children;
    }
    return (react_1.default.createElement(components_1.View, { style: styles.root },
        (title || description || onBack) && (react_1.default.createElement(components_1.View, { style: styles.header },
            react_1.default.createElement(components_1.View, { style: styles.titleWrapper },
                onBack && (react_1.default.createElement(components_1.View, { style: styles.backButtonWrapper },
                    react_1.default.createElement(components_1.IconButton, { name: "arrow-left-circle", onPress: onBack, color: styles.backButtonIconColor }))),
                title && (react_1.default.createElement(components_1.H6, { style: styles.title }, title))),
            description && (react_1.default.createElement(components_1.Body1, { style: styles.description }, description)))),
        react_1.default.createElement(components_1.View, { style: styles.body }, children)));
};
exports.WizardStepUI = WizardStepUI;
exports.WizardStepUI.displayName = 'WizardStepUI';
