"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SliderManager = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
// export interface SliderManagerState {
// 	sliding: boolean;
// 	slidingValue: number;
// }
/**
 * Does not call "onValueChange" until sliding is complete,
 * but maintains sliding state in UI
 */
// export class SliderManager extends React.PureComponent<SliderManagerProps, SliderManagerState> {
// 	state: SliderManagerState = {
// 		sliding: false,
// 		slidingValue: this.props.data.value,
// 	};
// 	constructor(props: SliderManagerProps) {
// 		super(props);
// 		this.onSlidingStart = this.onSlidingStart.bind(this);
// 		this.onSlidingComplete = this.onSlidingComplete.bind(this);
// 		this.onValueChange = this.onValueChange.bind(this);
// 	}
// 	onSlidingStart(value: number) {
// 		console.log('onSlidingStart', value);
// 		this.setState({
// 			sliding: true,
// 			slidingValue: value,
// 		});
// 		if (this.props.setScrollEnabled){
// 			this.props.setScrollEnabled(false);
// 		}
// 		if (this.props.onSlidingStart) {
// 			this.props.onSlidingStart(value);
// 		}
// 	}
// 	onValueChange(value: number) {
// 		console.log('onValueChange', value);
// 		if (this.state.sliding) {
// 			this.setState({
// 				slidingValue: value,
// 			});
// 		} else {
// 			this.props.onValueChange(value);
// 		}
// 		if (this.props.onValueChangeInternal) {
// 			this.props.onValueChangeInternal(value);
// 		}
// 	}
// 	onSlidingComplete(value: number) {
// 		console.log('onSlidingComplete', value);
// 		this.setState({
// 			sliding: false,
// 			slidingValue: value,
// 		});
// 		if (this.props.setScrollEnabled){
// 			this.props.setScrollEnabled(true);
// 		}
// 		if (this.props.onSlidingComplete) {
// 			this.props.onSlidingComplete(value);
// 		}
// 		this.props.onValueChange(value);
// 	}
// 	render() {
// 		const {
// 			Slider,
// 			data,
// 			onSlidingStart,
// 			onSlidingComplete,
// 			onValueChange,
// 			...sliderProps
// 		} = this.props;
// 		const { sliding, slidingValue } = this.state;
// 		return (
// 			<Slider
// 				{...sliderProps}
// 				data={{
// 					...data,
// 					value: sliding ? slidingValue : data.value,
// 				}}
// 				onSlidingStart={this.onSlidingStart}
// 				onSlidingComplete={this.onSlidingComplete}
// 				onValueChange={this.onValueChange}
// 			/>
// 		);
// 	}
// }
const SliderManager = (props) => {
    const { Slider, data, onSlidingStart, onSlidingComplete, onValueChange } = props, sliderProps = tslib_1.__rest(props, ["Slider", "data", "onSlidingStart", "onSlidingComplete", "onValueChange"]);
    const [sliding, setSliding] = (0, react_1.useState)(false);
    const [slidingValue, setSlidingValue] = (0, react_1.useState)(data.value);
    const onSlidingStartCustom = (0, react_1.useCallback)((value) => {
        setSliding(true);
        setSlidingValue(value);
        if (props.setScrollEnabled) {
            props.setScrollEnabled(false);
        }
        if (props.onSlidingStart) {
            props.onSlidingStart(value);
        }
    }, [props.setScrollEnabled, props.onSlidingStart]);
    const onValueChangeCustom = (0, react_1.useCallback)((value) => {
        if (sliding) {
            setSlidingValue(value);
        }
        else {
            props.onValueChange(value);
        }
        if (props.onValueChangeInternal) {
            props.onValueChangeInternal(value);
        }
    }, [sliding, props.onValueChangeInternal, props.onValueChange]);
    const onSlidingCompleteCustom = (0, react_1.useCallback)((value) => {
        setSliding(false);
        setSlidingValue(value);
        if (props.setScrollEnabled) {
            props.setScrollEnabled(true);
        }
        if (props.onSlidingComplete) {
            props.onSlidingComplete(value);
        }
        props.onValueChange(value);
    }, [props.setScrollEnabled, props.onSlidingComplete, props.onValueChange]);
    const value = sliding ? slidingValue : data.value;
    return (react_1.default.createElement(Slider, Object.assign({}, sliderProps, { data: Object.assign(Object.assign({}, data), { value: value }), value: value, onSlidingStart: onSlidingStartCustom, onSlidingComplete: onSlidingCompleteCustom, onValueChange: onValueChangeCustom })));
};
exports.SliderManager = SliderManager;
