"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const ThumbTouchArea = (0, react_1.memo)((props) => {
    const { thumbTouchSize, position } = props;
    const { x, y } = position;
    return (react_1.default.createElement(react_native_1.TouchableWithoutFeedback, null,
        react_1.default.createElement(react_native_1.View, { style: {
                height: thumbTouchSize.height,
                left: x !== undefined ? x - thumbTouchSize.height / 2 : undefined,
                position: 'absolute',
                top: y !== undefined ? y - thumbTouchSize.width / 2 : undefined,
                width: thumbTouchSize.width,
                // backgroundColor: 'rgba(255,0,0,1)',
            } })));
});
ThumbTouchArea.displayName = 'ThumbTouchArea';
exports.default = ThumbTouchArea;
