"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCard = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const imports_1 = require("../../imports");
const defaultStyles = (theme) => ({
    root: {},
    avatarContainer: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        overflow: 'hidden',
    },
    avatar: {
        backgroundColor: theme.palette.background.dark,
    },
    content: {
        padding: theme.spacing.unit,
    },
    brand: {
        color: theme.palette.text.disabled,
    },
    name: {
        paddingTop: theme.spacing.unit / 2,
    },
});
/**
 * ProductCard used for listing of available products.
 */
const ProductCard = (props) => {
    const { brand, id, avatar, thumbnail, link, name, placeholder, width, loading, onPress, } = props;
    const styles = (0, core_1.useStyles)('ProductCard', props, defaultStyles);
    const { push, route } = (0, core_1.useNavigation)();
    const params = route.params;
    if (loading === true) {
        return react_1.default.createElement(imports_1.PlaceholderCard, { width: width });
    }
    return (react_1.default.createElement(components_1.Card, { style: Object.assign(Object.assign({}, styles.root), { width }), onPress: link === true
            ? (0, react_1.useCallback)(() => push('Install', Object.assign({ id }, params)), [])
            : onPress },
        react_1.default.createElement(components_1.View, { style: styles.avatarContainer },
            react_1.default.createElement(imports_1.ProgressiveImage, { placeholder: placeholder, thumbnail: thumbnail, source: avatar, style: Object.assign(Object.assign({}, styles.avatar), { height: width, width }) })),
        react_1.default.createElement(components_1.View, { style: styles.content },
            react_1.default.createElement(components_1.Overline, { testID: "productcard-brand-name-text", ellipsizeMode: "tail", numberOfLines: 1, style: styles.brand }, (brand === null || brand === void 0 ? void 0 : brand.name) || 'Unknown Brand'),
            react_1.default.createElement(components_1.Subtitle1, { testID: "productcard-name-text", ellipsizeMode: "tail", numberOfLines: 1, style: styles.name }, name ? name : 'Untitled'))));
};
exports.ProductCard = ProductCard;
/**
 *  default props of ProductCard
 * if no props are given then
 * ProductCard will render with
 * these props
 */
exports.ProductCard.defaultProps = {
    link: true,
    placeholder: 'ProductPlaceholder',
    width: 150,
};
exports.ProductCard.displayName = 'ProductCard';
