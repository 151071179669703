"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSupportOpen = void 0;
function isSupportOpen(date, days, opens, closes) {
    if (!Array.isArray(days)) {
        return false;
    }
    const currentDay = date.getDay();
    if (days.find((day) => day === currentDay) === undefined) {
        return false;
    }
    const currentHour = date.getUTCHours();
    if (currentHour < opens) {
        return false;
    }
    if (currentHour >= closes) {
        return false;
    }
    return true;
}
exports.isSupportOpen = isSupportOpen;
