"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStateSetter = void 0;
const client_1 = require("@apollo/client");
const core_1 = require("@bluebase/core");
const device_controller_1 = require("@mevris/device-controller");
const react_1 = require("react");
const useDevice_1 = require("../useDevice");
const updateStore_1 = require("../useDeviceBuilder/updateStore");
function useStateSetter() {
    const BB = (0, core_1.useBlueBase)();
    const apollo = (0, client_1.useApolloClient)();
    const { device, id } = (0, useDevice_1.useDevice)();
    const [timeoutId, setTimeoutId] = (0, react_1.useState)();
    async function setState(stateMap) {
        if (!device) {
            const result = {
                status: device_controller_1.RESPONSE_STATUS_ERROR,
                error: new Error('Could not set state. Reason: No device found.'),
            };
            BB.Logger.error(result);
            return result;
        }
        // If we have an active timer to get state, cancel it
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(undefined);
            BB.Logger.info('Cancelling device state sync timeout and setting new state', {
                id,
                stateMap,
            });
        }
        // Optimistically update store
        (0, updateStore_1.updateStore)({ stateMap, BB, apollo, id });
        // Attempt to set device state
        const response = await device.setState({ stateMap });
        // If setting state is a success, we start a timer
        // to get a fresh state after 3 seconds, just in case
        // if the state sync failed somehow
        if (response.status === device_controller_1.RESPONSE_STATUS_SUCCESS) {
            BB.Logger.info('Device setState complete', { id, stateMap });
            const timeout = setTimeout(() => {
                device.requestState();
                setTimeoutId(undefined);
                BB.Logger.info('Device state synced 3 seconds after successfull command', { id, stateMap });
            }, 3000);
            setTimeoutId(timeout);
        }
        // If setting state fails, we try force get a fresh state
        else {
            BB.Logger.error('Device setState error', {
                error: response.error,
                id,
                state: stateMap,
            });
            device.requestState();
        }
        return response;
    }
    return setState;
}
exports.useStateSetter = useStateSetter;
