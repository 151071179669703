"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMevrisUI = void 0;
const client_1 = require("@apollo/client");
const client_graphql_1 = require("@mevris/client-graphql");
const react_1 = require("react");
const helpers_1 = require("../../helpers");
const useDevice_1 = require("../useDevice");
function useMevrisUI(ui, options) {
    var _a;
    const { id, entity, error: errorDevice, loading: loadingDevice } = (0, useDevice_1.useDevice)();
    const { data, error: errorQuery, loading: loadingQuery, } = (0, client_1.useQuery)(client_graphql_1.EntityUIQuery, {
        // fetchPolicy: 'cache-and-network',
        variables: { id, ui },
    });
    const error = errorDevice || errorQuery;
    const loading = loadingDevice || loadingQuery;
    // Extract ui items, these are basically Json Layout items with addition data
    const response = ((_a = data === null || data === void 0 ? void 0 : data.entity) === null || _a === void 0 ? void 0 : _a.ui) || [];
    const uiSchema = response.filter(({ type }) => type === ui);
    // Put "On" button at the top
    const on = uiSchema.find((i) => { var _a; return ((_a = i === null || i === void 0 ? void 0 : i.definition) === null || _a === void 0 ? void 0 : _a.key) === 'on'; });
    const everthingElse = uiSchema.filter((i) => { var _a; return ((_a = i === null || i === void 0 ? void 0 : i.definition) === null || _a === void 0 ? void 0 : _a.key) !== 'on'; });
    const sortedUiSchema = [on, ...everthingElse].filter((x) => !!x);
    const items = (0, react_1.useMemo)(() => (0, helpers_1.mapUISchemaToJsonComponentNodes)(sortedUiSchema, entity, Object.assign({ ignoreItemsWithoutValue: false }, options)), [sortedUiSchema, entity, options]);
    return { uiSchema: sortedUiSchema, items, entity, error, loading };
}
exports.useMevrisUI = useMevrisUI;
