"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceTypeCreateMutation = void 0;
const client_1 = require("@apollo/client");
exports.DeviceTypeCreateMutation = client_1.gql `
	mutation DeviceTypeCreateMutation($input: DeviceTypeInput!, $avatarSize: Int) {
		deviceType {
			create(input: $input) {
				id
				handle
				name
				avatar(width: $avatarSize, height: $avatarSize)
				thumbnail: avatar(width: 5, height: 5)
				category
				description
			}
		}
	}
`;
