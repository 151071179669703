"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
tslib_1.__exportStar(require("./AppearanceSettingList"), exports);
tslib_1.__exportStar(require("./InformationSettingList"), exports);
tslib_1.__exportStar(require("./LanguageSettingList"), exports);
tslib_1.__exportStar(require("./LegalSettingList"), exports);
tslib_1.__exportStar(require("./LinkingSettingItem"), exports);
tslib_1.__exportStar(require("./PrivacyPolicy"), exports);
tslib_1.__exportStar(require("./SettingList"), exports);
tslib_1.__exportStar(require("./SettingsAppIcon"), exports);
tslib_1.__exportStar(require("./SupportSettingList"), exports);
tslib_1.__exportStar(require("./TermsOfService"), exports);
