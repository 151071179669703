"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtraOptionsWizardPage = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const defaultStyles = (theme) => ({
    root: {
        flex: 1,
    },
    container: {
        alignItems: 'center',
        flexDirection: 'row',
        padding: theme.spacing.unit,
    },
});
/**
 * This screen enables you to login by entering your name and password or you
 * can also login by using your google or facebook account.
 */
const ExtraOptionsWizardPage = (props) => {
    const { children, goBack, title, hideBackButton } = props;
    const styles = (0, core_1.useStyles)('ExtraOptionsWizardPage', props, defaultStyles);
    const { __ } = (0, core_1.useIntl)();
    const onBackPress = (0, react_1.useCallback)(() => goBack('home'), [goBack]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.View, { style: styles.container },
            !hideBackButton ? (react_1.default.createElement(components_1.IconButton, { name: "chevron-left", size: 32, onPress: onBackPress, style: { padding: 0, marginRight: 16 } })) : null,
            react_1.default.createElement(components_1.H6, null, __(title))),
        react_1.default.createElement(components_1.Divider, null),
        react_1.default.createElement(components_1.ScrollView, { style: Object.assign(Object.assign({}, styles.root), styles) }, children)));
};
exports.ExtraOptionsWizardPage = ExtraOptionsWizardPage;
