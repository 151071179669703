"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Iso8601Date = void 0;
const types_1 = require("../types");
exports.Iso8601Date = {
    key: 'Iso8601Date',
    extends: 'Date',
    // Example: Mon Jul 08 2019 11:45:40 GMT+0500 (Pakistan Standard Time) => 2019-07-08T06:42:09.971Z
    printer: {
        type: types_1.DataSchemaPrinterType.TEMPLATE,
        value: '{{ new Date(value).toISOString() }}',
    },
};
