"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TraitListEmptyState = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const TraitListEmptyState = (props) => {
    const { navigate } = (0, core_1.useNavigation)();
    const goToCreate = (0, react_1.useCallback)(() => navigate('TraitAdd'), []);
    return (react_1.default.createElement(components_1.ComponentState, Object.assign({ title: "No traits", description: "Start by creating a new trait", actionTitle: "Create Trait", actionOnPress: goToCreate, actionProps: { size: 'small', color: 'success', variant: 'outlined' } }, props)));
};
exports.TraitListEmptyState = TraitListEmptyState;
exports.TraitListEmptyState.displayName = 'TraitListEmptyState';
