"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wizard = exports.Widget = exports.Stepper = exports.LocationMapView = exports.JsonLayout = exports.BaseSlider = exports.BaseKelvinCircularSlider = exports.BaseHueCircularSlider = exports.BaseCircularSlider = void 0;
const components_1 = require("@bluebase/components");
// Sliders Start
exports.BaseCircularSlider = (0, components_1.getComponentWithFallback)({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'CircularSlider',
})('CircularSlider');
exports.BaseCircularSlider.displayName = 'CircularSlider';
exports.BaseHueCircularSlider = (0, components_1.getComponentWithFallback)({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'HueCircularSlider',
})('HueCircularSlider');
exports.BaseHueCircularSlider.displayName = 'HueCircularSlider';
exports.BaseKelvinCircularSlider = (0, components_1.getComponentWithFallback)({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'KelvinCircularSlider',
})('KelvinCircularSlider');
exports.BaseKelvinCircularSlider.displayName = 'KelvinCircularSlider';
exports.BaseSlider = (0, components_1.getComponentWithFallback)({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'Slider',
})('Slider');
exports.BaseSlider.displayName = 'BasicSlider';
// Sliders End
exports.JsonLayout = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-json-schema-components',
    name: 'JsonLayout',
})('JsonLayout');
exports.JsonLayout.displayName = 'JsonLayout';
exports.LocationMapView = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-json-schema-components',
    name: 'LocationMapView',
})('LocationMapView');
exports.LocationMapView.displayName = 'LocationMapView';
exports.Stepper = (0, components_1.getComponentWithFallback)({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'Stepper',
})('Stepper');
exports.Stepper.displayName = 'Stepper';
exports.Widget = (0, components_1.getComponentWithFallback)({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'Widget',
})('Widget', 'View');
exports.Widget.displayName = 'Widget';
exports.Wizard = (0, components_1.getComponentWithFallback)({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'Wizard',
})('Wizard');
exports.Wizard.displayName = 'Wizard';
