"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeviceState = void 0;
const tslib_1 = require("tslib");
const client_1 = require("@apollo/client");
const client_graphql_1 = require("@mevris/client-graphql");
const useDevice_1 = require("../useDevice");
const useStateGetter_1 = require("./useStateGetter");
const useStateSetter_1 = require("./useStateSetter");
/**
 * Query Device State from cache.
 * We'll let device controllers manage real time state through
 * their own subcription/polling mechanism.
 */
const useDeviceState = () => {
    var _a;
    const { id, entity } = (0, useDevice_1.useDevice)();
    // Run Graphql query
    const _b = (0, client_1.useQuery)(client_graphql_1.EntityQuery, {
        fetchPolicy: 'cache-first',
        variables: { id },
    }), { loading, error, data } = _b, rest = tslib_1.__rest(_b, ["loading", "error", "data"]);
    const setState = (0, useStateSetter_1.useStateSetter)();
    const getState = (0, useStateGetter_1.useStateGetter)();
    const stateMap = ((_a = data === null || data === void 0 ? void 0 : data.entity) === null || _a === void 0 ? void 0 : _a.stateMap) || {};
    return Object.assign({ id,
        entity,
        stateMap,
        setState,
        getState,
        error,
        loading }, rest);
};
exports.useDeviceState = useDeviceState;
