"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getListPaginationType = void 0;
const core_1 = require("@bluebase/core");
const react_native_1 = require("react-native");
function getListPaginationType() {
    if ((0, core_1.isMobile)()) {
        return 'infinite';
    }
    return react_native_1.Platform.select({
        default: 'next-previous',
        web: 'numbered',
    });
}
exports.getListPaginationType = getListPaginationType;
