"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaListItem = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const imports_1 = require("../../imports");
const SchemaListItem = (props) => {
    const { id, name, description, loading, link, onPress, right, style, } = props;
    const { push } = (0, core_1.useNavigation)();
    if (loading === true) {
        return react_1.default.createElement(imports_1.PlaceholderListItem, { title: true });
    }
    return (react_1.default.createElement(components_1.List.Item, { title: name, description: description, right: right, style: style, onPress: link === true
            ? (0, react_1.useCallback)(() => push('SchemaProfile', { schemaId: id }), [id])
            : onPress }));
};
exports.SchemaListItem = SchemaListItem;
exports.SchemaListItem.defaultProps = {};
exports.SchemaListItem.displayName = 'SchemaListItem';
