"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
/* eslint-disable camelcase */
const core_1 = require("@bluebase/core");
const components_1 = require("./components");
const lang_1 = require("./lang");
const version_1 = require("./version");
tslib_1.__exportStar(require("./exports"), exports);
exports.default = (0, core_1.createPlugin)({
    description: 'Mevris UI',
    key: '@mevris/client-plugin-ui',
    name: 'Mevris UI',
    version: version_1.VERSION,
    assets: {
        // Placeholders
        BrandPlaceholder: require('../assets/common/ProductPlaceholder.png'),
        BrandPlaceholder_dark: require('../assets/common/ProductPlaceholder_dark.png'),
        EntityListEmptyImage_light: require('../assets/common/EntityListEmptyImage_light.png'),
        EntityListEmptyImage_dark: require('../assets/common/EntityListEmptyImage_dark.png'),
        PersonPlaceholder: require('../assets/common/PersonPlaceholder.png'),
        PersonPlaceholder_dark: require('../assets/common/PersonPlaceholder_dark.png'),
        Placeholder: require('../assets/common/Placeholder.png'),
        Placeholder_dark: require('../assets/common/Placeholder_dark.png'),
        PlacePlaceholder: require('../assets/common/PlacePlaceholder.png'),
        PlacePlaceholder_dark: require('../assets/common/PlacePlaceholder_dark.png'),
        ProductPlaceholder: require('../assets/common/ProductPlaceholder.png'),
        ProductPlaceholder_dark: require('../assets/common/ProductPlaceholder_dark.png'),
        ThingPlaceholder: require('../assets/common/ThingPlaceholder.png'),
        ThingPlaceholder_dark: require('../assets/common/ThingPlaceholder_dark.png'),
    },
    components: Object.assign({}, components_1.components),
    filters: Object.assign({}, lang_1.lang),
});
