"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiLevelSelectorBreadcrumb = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const defaultStyles = (theme) => ({
    wrapper: {
        alignItems: 'center',
        flexDirection: 'row',
        padding: theme.spacing.unit / 2,
    },
    divider: {
        color: theme.palette.text.disabled,
    },
});
const MultiLevelSelectorBreadcrumb = (props) => {
    const { node, ancestors, onItemPress } = props;
    const styles = (0, core_1.useStyles)('MultiLevelSelectorBreadcrumb', props, defaultStyles);
    const breadcrumbScrollView = (0, react_1.useRef)(null);
    const onContentSizeChange = (0, react_1.useCallback)(() => {
        breadcrumbScrollView.current.scrollToEnd();
    }, [breadcrumbScrollView]);
    function onPress(parentId) {
        return () => onItemPress(parentId);
    }
    return (react_1.default.createElement(react_native_1.ScrollView, { testID: "on-content-size-change", horizontal: true, ref: breadcrumbScrollView, onContentSizeChange: onContentSizeChange },
        react_1.default.createElement(react_native_1.View, { style: styles.wrapper },
            react_1.default.createElement(components_1.Button, { testID: "on-Press-test", 
                // title="/"
                icon: { name: 'home', type: 'icon', style: { marginRight: 0 } }, variant: "text", size: "small", onPress: onPress() }),
            ancestors.map((value) => (react_1.default.createElement(react_1.default.Fragment, { key: value.id },
                react_1.default.createElement(components_1.Icon, { name: "chevron-right", style: styles.divider }),
                react_1.default.createElement(components_1.Button, { testID: "on-Press-test", title: value.title, variant: "text", size: "small", onPress: onPress(value === null || value === void 0 ? void 0 : value.parentId) })))),
            node && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(components_1.Icon, { name: "chevron-right", style: styles.divider }),
                react_1.default.createElement(components_1.Button, { testID: "on-Press-test", title: node.title, variant: "text", size: "small", onPress: onPress(node.id) }))),
            !node && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(components_1.Icon, { name: "chevron-right", style: styles.divider }),
                react_1.default.createElement(components_1.Button, { title: "Select an Item", variant: "text", size: "small", disabled: true }))))));
};
exports.MultiLevelSelectorBreadcrumb = MultiLevelSelectorBreadcrumb;
const defaultProps = {};
exports.MultiLevelSelectorBreadcrumb.defaultProps = defaultProps;
exports.MultiLevelSelectorBreadcrumb.displayName = 'MultiLevelSelectorBreadcrumb';
