"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderListItem = exports.PlaceholderBody1 = exports.Placeholder = void 0;
const components_1 = require("@bluebase/components");
exports.Placeholder = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'Placeholder',
})('Placeholder');
exports.Placeholder.displayName = 'Placeholder';
exports.PlaceholderBody1 = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderBody1',
})('PlaceholderBody1');
exports.PlaceholderBody1.displayName = 'PlaceholderBody1';
exports.PlaceholderListItem = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderListItem',
})('PlaceholderListItem');
exports.PlaceholderListItem.displayName = 'PlaceholderListItem';
