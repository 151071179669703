"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStateGetter = void 0;
const core_1 = require("@bluebase/core");
const device_controller_1 = require("@mevris/device-controller");
const useDevice_1 = require("../useDevice");
function useStateGetter() {
    const BB = (0, core_1.useBlueBase)();
    const { device, id } = (0, useDevice_1.useDevice)();
    async function getState(options) {
        if (!device) {
            const result = {
                status: device_controller_1.RESPONSE_STATUS_ERROR,
                error: new Error('Could not get state. Reason: No device found.'),
            };
            BB.Logger.error(result);
            return result;
        }
        try {
            const state = await device.requestState(options);
            return state;
        }
        catch (error) {
            BB.Logger.error('Device getState error', {
                error,
                id,
            });
            return {
                status: device_controller_1.RESPONSE_STATUS_ERROR,
                error,
            };
        }
    }
    return getState;
}
exports.useStateGetter = useStateGetter;
