"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityTypeSelector = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const defaultStyles = (theme) => ({
    root: {
        margin: theme.spacing.unit,
        borderWidth: 1,
        borderColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadius * 2
    },
    placeAvatar: {
        backgroundColor: theme.palette.success.light,
    },
    thingAvatar: {
        backgroundColor: theme.palette.warning.light,
    },
    listItemIconColor: theme.palette.text.icon,
    footer: {
        alignItems: 'flex-start',
        paddingHorizontal: theme.spacing.unit,
    },
});
const EntityTypeSelector = (props) => {
    const { onSelect } = props;
    const { rtl } = (0, core_1.useIntl)();
    const styles = (0, core_1.useStyles)('EntityTypeSelector', props, defaultStyles);
    const onPress = (0, react_1.useCallback)((title) => () => onSelect(title), [onSelect]);
    return (react_1.default.createElement(components_1.View, { style: styles.root },
        react_1.default.createElement(components_1.List.Item, { title: "Place", description: "Sync data with a place", left: react_1.default.createElement(components_1.List.Avatar, { type: "icon", icon: "home", style: styles.placeAvatar }), onPress: onPress('place'), right: react_1.default.createElement(components_1.Icon, { name: rtl ? 'chevron-left' : 'chevron-right', color: styles.listItemIconColor }) }),
        react_1.default.createElement(components_1.Divider, null),
        react_1.default.createElement(components_1.List.Item, { title: "Thing", description: "Sync data with a thing", left: react_1.default.createElement(components_1.List.Avatar, { type: "icon", icon: "lightbulb", style: styles.thingAvatar }), onPress: onPress('thing'), right: react_1.default.createElement(components_1.Icon, { name: rtl ? 'chevron-left' : 'chevron-right', color: styles.listItemIconColor }) })));
};
exports.EntityTypeSelector = EntityTypeSelector;
exports.EntityTypeSelector.displayName = 'EntityTypeSelector';
