"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.routes = void 0;
const lib_1 = require("../lib");
const pages_1 = require("./pages");
const routes = async ({ BB, intl }) => {
    return [
        ...(0, lib_1.createSettingsRoutes)({
            filter: 'bluebase.plugin.setting-app',
            pages: await BB.Filters.run('bluebase.plugin.setting-app.pages', pages_1.pages),
            mainRoute: {
                name: 'SettingsApp',
                path: '',
                options: {
                    title: intl.__('Settings'),
                },
            },
        }),
    ];
};
exports.routes = routes;
