"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderControlSettings = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const defaultStyles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        paddingVertical: 20,
    },
    icon: {
        paddingHorizontal: 10,
    },
};
const HeaderControlSettings = (props) => {
    const { theme } = (0, core_1.useTheme)();
    const { navigate } = (0, core_1.useNavigation)();
    const gotoConditionerControlUI = (0, react_1.useCallback)(() => navigate('ThingProfile'), []);
    const { title } = props;
    const styles = (0, core_1.useStyles)('SwitchThreeGangControlUIView', props, defaultStyles);
    return (react_1.default.createElement(components_1.View, { style: styles.root },
        react_1.default.createElement(components_1.IconButton, { name: "chevron-left", color: theme.palette.text.secondary, onPress: gotoConditionerControlUI, style: styles.icon }),
        react_1.default.createElement(components_1.H5, null, title)));
};
exports.HeaderControlSettings = HeaderControlSettings;
exports.HeaderControlSettings.displayName = 'HeaderControlSettings';
