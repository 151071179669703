"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesktopView = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const client_graphql_1 = require("@mevris/client-graphql");
const react_1 = tslib_1.__importStar(require("react"));
const hooks_1 = require("../../../../hooks");
const generics_1 = require("../../../generics");
const parts_1 = require("../../../parts");
const schemas_1 = require("../../../schemas");
const AutoPilotSettingsView_1 = require("../views/AutoPilotSettingsView");
const AutoPilotStateView_1 = require("../views/AutoPilotStateView");
const PredictionsView_1 = require("../views/PredictionsView");
const SmartCleanSettingsView_1 = require("../views/SmartCleanSettingsView");
const SmartCleanStateView_1 = require("../views/SmartCleanStateView");
const ExtraOptionScreens = {
    'airConditioner.autoPilot': AutoPilotSettingsView_1.AutoPilotSettingsView,
    predictions: PredictionsView_1.PredictionsView,
    'cleaning': SmartCleanSettingsView_1.SmartCleanSettingsView,
};
const DesktopView = (props) => {
    const { stateMap, setState, entity } = (0, hooks_1.useDeviceState)();
    const { items } = (0, hooks_1.useMevrisUI)(client_graphql_1.TraitUiType.Controls);
    const prediction = (0, hooks_1.useThermostatEtaPrediction)();
    const [extraOptionScreen, /* setExtraOptionScreen */] = (0, react_1.useState)();
    const data = {};
    items.forEach((item) => {
        const key = item.props.key;
        data[key] = item;
    });
    // Dialog
    const [dialogVisible, setDialogVisibility] = (0, react_1.useState)(false);
    const toggleDialogVisibility = (0, react_1.useCallback)(() => setDialogVisibility(!dialogVisible), [
        dialogVisible,
        setDialogVisibility,
    ]);
    // const onNavigate = useCallback(
    // 	(screen: string) => {
    // 		setExtraOptionScreen(screen);
    // 		toggleDialogVisibility();
    // 	},
    // 	[dialogVisible, setDialogVisibility]
    // );
    // Find required nodes by key
    function getJsonNode(key) {
        return items.find((i) => i.props.key === key);
    }
    // Find required data itemss by key
    function getDataItem(key) {
        const result = getJsonNode(key);
        return result === null || result === void 0 ? void 0 : result.props;
    }
    const onItem = getDataItem('on');
    const autoCleanItem = getDataItem('cleaning');
    const autoPilotItem = getDataItem('airConditioner.autoPilot');
    let node;
    // If there is no stateMap, or switch or setpoint states then stop here
    if (!stateMap || !entity || !getJsonNode('on') || !getJsonNode('thermostat.setpoint')) {
        node = null;
    }
    // If auto clean is active, show that state
    else if (autoCleanItem && autoCleanItem.value) {
        node = react_1.default.createElement(SmartCleanStateView_1.SmartCleanStateView, { data: data, onStateChange: setState });
    }
    // If auto pilot is active, show that state
    else if (autoPilotItem && autoPilotItem.value) {
        node = react_1.default.createElement(AutoPilotStateView_1.AutoPilotStateView, { data: data, onStateChange: setState, entity: entity });
    }
    // If the device is off, show off state
    else if (onItem && !onItem.value) {
        node = react_1.default.createElement(parts_1.OffStateView, { on: onItem, onStateChange: setState });
    }
    // Render main controls
    else {
        node = (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(schemas_1.AirConditionerControlUIView, { onStateChange: setState, data: {
                    'thermostat.setpoint': Object.assign(Object.assign({}, getDataItem('thermostat.setpoint')), { description: prediction }),
                } }),
            react_1.default.createElement(components_1.Divider, null),
            react_1.default.createElement(generics_1.BaseUIView, Object.assign({ items: items, onStateChange: setState, exclude: [
                    'thermostat.setpoint',
                    'cleaning',
                    'airConditioner.autoPilot',
                    'airConditioner.autoPilot.mode',
                    'airConditioner.autoPilot.temperature',
                ] }, props))));
    }
    let extraScreenNode = null;
    const ExtraOptionScreenComponent = ExtraOptionScreens[extraOptionScreen];
    if (ExtraOptionScreenComponent) {
        extraScreenNode = react_1.default.createElement(ExtraOptionScreenComponent, {
            thing: entity,
            onStateChange: setState,
            data,
            dismiss: toggleDialogVisibility,
        });
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        node,
        react_1.default.createElement(components_1.Dialog, { testID: "extra-options-dialog", visible: dialogVisible, dismissable: true, onDismiss: toggleDialogVisibility },
            react_1.default.createElement(components_1.View, { style: { minHeight: 525 } }, extraScreenNode))));
};
exports.DesktopView = DesktopView;
exports.DesktopView.displayName = 'DesktopView';
