"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeSelectionSetting = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const ThemeSelectionSetting = () => {
    const BB = (0, core_1.useBlueBase)();
    const { __ } = (0, core_1.useIntl)();
    const { theme, changeTheme } = (0, core_1.useTheme)();
    const themes = [...BB.Themes.entries()];
    const [visible, setVisible] = (0, react_1.useState)(false);
    const toggle = () => setVisible(!visible);
    const select = (slug) => () => {
        changeTheme(slug);
        toggle();
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.List.Item, { left: react_1.default.createElement(components_1.List.Icon, { name: "brush" }), title: __('Themes'), description: __(theme.name), onPress: toggle }),
        react_1.default.createElement(components_1.Dialog, { dismissable: true, visible: visible, onDismiss: toggle },
            react_1.default.createElement(components_1.List.Subheader, null, __('Available Themes')),
            react_1.default.createElement(components_1.Divider, null),
            themes.map(([key, item]) => {
                return (react_1.default.createElement(components_1.List.Item, { title: __(item.value.name), onPress: select(key), key: key, selected: theme.name === key }));
            }))));
};
exports.ThemeSelectionSetting = ThemeSelectionSetting;
