"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PredictionsView = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const HeaderControlSettings_1 = require("./HeaderControlSettings");
const ThermostatETAPredictionReport = (0, core_1.getComponent)('ThermostatETAPredictionReport', 'Noop');
ThermostatETAPredictionReport.displayName = 'ThermostatETAPredictionReport';
const defaultStyles = (_theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        paddingHorizontal: _theme.spacing.unit,
    },
});
const PredictionsView = (props) => {
    const { entity, style } = props;
    const styles = (0, core_1.useStyles)('PredictionsView', props, defaultStyles);
    const EntityPlaceSelector = (0, core_1.getComponent)('EntityParentSelector');
    const PlaceLocationSettings = (0, core_1.getComponent)('PlaceLocationSettings');
    const { navigate } = (0, core_1.useNavigation)();
    const { __ } = (0, core_1.useIntl)();
    const entityId = props.entity.id;
    const placeId = props.entity.parentId;
    // const { 'thermostat.setpoint': thermostatSetpoint } = data;
    const goToEntitysSettings = (0, react_1.useCallback)(() => navigate('EntitySettingsGeneral', { thingId: entity.id }), [navigate, entity]);
    const goToPlaceLocationSettings = (0, react_1.useCallback)(() => navigate('PlaceLocationSettings', { placeId: entity.parentId }), [navigate, entity]);
    if (!entity.parentId) {
        if (react_native_1.Platform.OS === 'web')
            return (react_1.default.createElement(components_1.ComponentState, { title: __('Looks like you have not added the place for the device'), actionTitle: __('Add Place'), imageSource: "EmptyPlaceIllustration", actionOnPress: goToEntitysSettings }));
        else
            return (react_1.default.createElement(components_1.View, { style: styles.container },
                react_1.default.createElement(components_1.List.Item, { title: __('Add place'), description: __('Add place to view predictions.') }),
                react_1.default.createElement(components_1.Divider, null),
                react_1.default.createElement(EntityPlaceSelector, { entityId: entityId, picker: true }),
                react_1.default.createElement(components_1.Divider, null)));
    }
    if (!(entity === null || entity === void 0 ? void 0 : entity.location)) {
        if (react_native_1.Platform.OS === 'web')
            return (react_1.default.createElement(components_1.ComponentState, { title: __('Looks like you have not added the location of the device'), actionTitle: __('Add Location'), imageSource: "EmptyPlaceIllustration", actionOnPress: goToPlaceLocationSettings }));
        else
            return react_1.default.createElement(PlaceLocationSettings, { placeId: placeId });
    }
    return (react_1.default.createElement(components_1.View, { style: [styles.root, style] },
        react_native_1.Platform.OS === 'web' && react_1.default.createElement(HeaderControlSettings_1.HeaderControlSettings, { title: "Predictions" }),
        react_1.default.createElement(ThermostatETAPredictionReport, { thingId: entityId })));
};
exports.PredictionsView = PredictionsView;
exports.PredictionsView.displayName = 'PredictionsView';
