"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntitySetParentMutation = void 0;
const client_1 = require("@apollo/client");
exports.EntitySetParentMutation = client_1.gql `
	mutation EntitySetParentMutation($id: ID!, $parentId: ID!, $avatarSize: Int) {
		entity {
			setParent(id: $id, parentId: $parentId) {
				id
				secondaryId
				name
				avatar(width: $avatarSize, height: $avatarSize)
				thumbnail: avatar(width: 5, height: 5)
				category
				deviceTypeHandle
				parentId

				uiResolutionKeys

				attributesMap
				stateMap

				location: stateMap(keys: ["geolocation.latitude", "geolocation.longitude"])

				schema {
					id
					handle
					mainState
					mainCommand

					localDeviceController {
						key
						options
					}
				}

				features {
					controls
					diagnostics
					quickCharts
					reports
					sensors
					settings
					wifi
				}
			}
		}
	}
`;
