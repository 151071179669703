"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityListItem = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const client_graphql_1 = require("@mevris/client-graphql");
const react_1 = tslib_1.__importStar(require("react"));
const helpers_1 = require("../../helpers");
const imports_1 = require("../../imports");
const defaultStyles = (theme) => ({
    root: {},
    statusIcon: {
        paddingLeft: theme.spacing.unit,
    },
    lastActivityTime: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
    },
    lastActivityTimeIcon: {
        color: theme.palette.text.disabled,
        paddingRight: theme.spacing.unit / 2,
    },
    lastActivityTimeText: {
        color: theme.palette.text.disabled,
    },
    importantState: {
        color: theme.palette.text.disabled,
        paddingLeft: theme.spacing.unit,
    },
});
/**
 * EntityListItem used for listing of available entities.
 * Is shows name of entities with StatusIcon, last activity time and important state.
 */
const EntityListItem = (props) => {
    const { id, avatar, link, name, category, stateMap, onPress, loading, disabled, right, style, } = props;
    if (loading === true) {
        return react_1.default.createElement(imports_1.PlaceholderListItem, { avatar: true, description: true, variant: "rounded" });
    }
    const { push } = (0, core_1.useNavigation)();
    const styles = (0, core_1.useStyles)('EntityListItem', props, defaultStyles);
    const online = stateMap === null || stateMap === void 0 ? void 0 : stateMap.online;
    const status = online === true ? 'green' : 'red';
    const placeholder = (0, helpers_1.getEntityPlaceholder)(category);
    return (react_1.default.createElement(components_1.List.Item, { left: react_1.default.createElement(components_1.List.Avatar, { type: "image", variant: "rounded", image: avatar ? avatar : placeholder }), title: name ? name : 'Untitled', 
        // description={
        // 	<React.Fragment>
        // 		{lastActivityTime ? (
        // 			<Text>
        // 				<DynamicIcon
        // 					testID="entitycard-activity-icon"
        // 					type="icon"
        // 					name="clock-outline"
        // 					style={styles.lastActivityTimeIcon}
        // 					size={13}
        // 				/>
        // 				<RelativeTime
        // 					testID="entitycard-relative-time-text"
        // 					component={Caption}
        // 					ellipsizeMode="tail"
        // 					numberOfLines={1}
        // 					style={styles.lastActivityTimeText}
        // 					time={lastActivityTime}
        // 				/>
        // 			</Text>
        // 		) : (
        // 			<Text style={styles.lastActivityTime}>
        // 				<Caption
        // 					testID="entitycard-no-activity-text"
        // 					ellipsizeMode="tail"
        // 					numberOfLines={1}
        // 					style={styles.lastActivityTimeText}
        // 				>
        // 					No activity yet
        // 				</Caption>
        // 			</Text>
        // 		)}
        // 	</React.Fragment>
        // }
        right: right ? right :
            react_1.default.createElement(react_1.default.Fragment, null, category === client_graphql_1.DeviceTypeCategory.Thing ? (react_1.default.createElement(components_1.View, { testID: "entitycard-status-icon", style: styles.statusIcon },
                react_1.default.createElement(imports_1.StatusIcon, { color: status }))) : null), onPress: link === true
            ? (0, react_1.useCallback)(() => push('EntityProfile', { entityId: id }), [])
            : onPress, disabled: disabled, style: style }));
};
exports.EntityListItem = EntityListItem;
exports.EntityListItem.defaultProps = {
    link: true,
    width: 150,
};
exports.EntityListItem.displayName = 'EntityListItem';
