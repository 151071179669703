"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STRING = void 0;
exports.STRING = {
    transform: (value, _schema) => {
        if (value === undefined || value === null) {
            return value;
        }
        return String(value);
    },
    convert: (value, _schema) => {
        return String(value);
    },
    validate: (value, schema) => {
        if (schema.values) {
            const exists = schema.values.find((v) => v.value === value);
            if (!exists) {
                return false;
            }
        }
        return typeof value === 'string';
    },
};
