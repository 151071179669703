"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntitySyncableDataDefinitionSelector = void 0;
const tslib_1 = require("tslib");
const client_1 = require("@apollo/client");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const client_graphql_1 = require("@mevris/client-graphql");
const universal_data_schema_1 = require("@mevris/universal-data-schema");
const react_1 = tslib_1.__importStar(require("react"));
const imports_1 = require("../../imports");
const EntitySyncableDataDefinitionSelectorEmptyState_1 = require("./EntitySyncableDataDefinitionSelectorEmptyState");
const defaultStyles = (theme) => ({
    root: {
        padding: theme.spacing.unit,
    },
    list: {
        margin: theme.spacing.unit,
        borderWidth: 1,
        borderColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadius * 2
    },
    listItemIconColor: theme.palette.text.icon,
});
const EntitySyncableDataDefinitionSelector = (props) => {
    var _a, _b, _c, _d;
    const { entityId, onSelect, mutating, dataType, filterUsedKeys, showMeasurements, showStates } = props;
    const { theme } = (0, core_1.useTheme)();
    const { rtl } = (0, core_1.useIntl)();
    const styles = (0, core_1.useStyles)('EntitySyncableDataDefinitionSelector', props, defaultStyles);
    const { data, loading, error } = (0, client_1.useQuery)(client_graphql_1.EntitySyncableDataDefinitionsQuery, {
        variables: { id: entityId, filterUsedKeys }
    });
    const onPress = (0, react_1.useCallback)((title, item) => () => onSelect(title, item), [onSelect]);
    const states = ((_b = (_a = data === null || data === void 0 ? void 0 : data.entity.syncableDataDefinitions) === null || _a === void 0 ? void 0 : _a.states) !== null && _b !== void 0 ? _b : []).filter(state => {
        if (!dataType) {
            return true;
        }
        const schema = (0, universal_data_schema_1.resolveDataSchema)(state.schema);
        return schema.dataType === dataType;
    });
    const measurements = ((_d = (_c = data === null || data === void 0 ? void 0 : data.entity.syncableDataDefinitions) === null || _c === void 0 ? void 0 : _c.measurements) !== null && _d !== void 0 ? _d : []).filter(measurement => {
        if (!dataType) {
            return true;
        }
        const schema = (0, universal_data_schema_1.resolveDataSchema)(measurement.schema);
        return schema.dataType === dataType;
    });
    if (loading || props.loading) {
        return (react_1.default.createElement(imports_1.Placeholder, null,
            react_1.default.createElement(components_1.View, { style: styles.root },
                react_1.default.createElement(components_1.View, { style: { padding: theme.spacing.unit * 2 } },
                    react_1.default.createElement(imports_1.PlaceholderCaption, { width: 20 })),
                react_1.default.createElement(components_1.View, { style: styles.list },
                    react_1.default.createElement(imports_1.PlaceholderListItem, null),
                    react_1.default.createElement(components_1.Divider, null),
                    react_1.default.createElement(imports_1.PlaceholderListItem, null),
                    react_1.default.createElement(components_1.Divider, null),
                    react_1.default.createElement(imports_1.PlaceholderListItem, null)))));
    }
    return (react_1.default.createElement(components_1.StatefulComponent, { data: [...states, ...measurements], loading: loading, error: error, emptyComponent: EntitySyncableDataDefinitionSelectorEmptyState_1.EntitySyncableDataDefinitionSelectorEmptyState },
        react_1.default.createElement(components_1.View, { style: styles.root },
            showStates !== false && (states === null || states === void 0 ? void 0 : states.length) > 0 && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(components_1.List.Subheader, null, "States"),
                react_1.default.createElement(components_1.View, { style: styles.list }, states.map((state, index) => {
                    var _a;
                    return (react_1.default.createElement(react_1.default.Fragment, { key: state.key },
                        react_1.default.createElement(components_1.List.Item, { title: (_a = state.name) !== null && _a !== void 0 ? _a : state.key, right: react_1.default.createElement(components_1.Icon, { name: rtl ? 'chevron-left' : 'chevron-right', color: styles.listItemIconColor }), onPress: onPress(client_graphql_1.SyncLinkType.State, state), disabled: mutating }),
                        index !== states.length - 1 && react_1.default.createElement(components_1.Divider, null)));
                })))),
            showMeasurements !== false && (measurements === null || measurements === void 0 ? void 0 : measurements.length) > 0 && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(components_1.List.Subheader, null, "Measurements"),
                react_1.default.createElement(components_1.View, { style: styles.list }, measurements.map((measurement, index) => {
                    var _a;
                    return (react_1.default.createElement(react_1.default.Fragment, { key: measurement.key },
                        react_1.default.createElement(components_1.List.Item, { title: (_a = measurement.name) !== null && _a !== void 0 ? _a : measurement.key, right: react_1.default.createElement(components_1.Icon, { name: "chevron-right", color: styles.listItemIconColor }), onPress: onPress(client_graphql_1.SyncLinkType.Measurement, measurement), disabled: mutating }),
                        index !== measurements.length - 1 && react_1.default.createElement(components_1.Divider, null)));
                })))))));
};
exports.EntitySyncableDataDefinitionSelector = EntitySyncableDataDefinitionSelector;
exports.EntitySyncableDataDefinitionSelector.displayName = 'EntitySyncableDataDefinitionSelector';
