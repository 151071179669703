"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemoizedSlider = exports.Slider = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const BaseSlider_1 = require("./BaseSlider");
const defaultStyles = (theme, { trackWidth, vertical }) => ({
    root: {
        alignItems: vertical ? 'center' : undefined,
        flex: 1,
        width: vertical ? trackWidth : undefined,
    },
    sliderContainer: {
        flexGrow: 1,
        width: vertical ? trackWidth : undefined,
    },
    slider: {},
    labelRow: {
        flexDirection: 'row',
        paddingBottom: theme.spacing.unit,
    },
    label: {
        color: theme.palette.text.secondary,
        flex: 1,
    },
    value: {
        color: theme.palette.text.secondary,
        textAlign: 'right',
    },
    helperTextRow: {
        paddingTop: theme.spacing.unit,
    },
    helperText: {
        color: theme.palette.text.secondary,
    },
});
const Slider = (props) => {
    const { helperText, label, showValue, style, value, minimumValue } = props;
    const styles = (0, core_1.useStyles)('Slider', props, defaultStyles);
    const sliderRef = (0, react_1.useRef)(null);
    const [layout, setLayout] = (0, react_1.useState)(props.layout);
    /* istanbul ignore next */
    const onLayout = (0, react_1.useCallback)(() => {
        if (!sliderRef || !sliderRef.current) {
            return;
        }
        sliderRef.current.measure((_x, _y, _width, _height, pageX, pageY) => {
            setLayout({ x: pageX, y: pageY, height: _height, width: _width });
        });
    }, [sliderRef]);
    return (react_1.default.createElement(react_native_1.View, { style: [styles.root, style] },
        label || showValue ? (react_1.default.createElement(react_native_1.View, { style: styles.labelRow, testID: "slider-label" },
            react_1.default.createElement(components_1.Body1, { testID: "label", style: styles.label }, label),
            react_1.default.createElement(components_1.Body1, { testID: "value", style: styles.value }, value !== undefined ? value : minimumValue))) : null,
        react_1.default.createElement(react_native_1.View, { testID: "slider-container", onLayout: onLayout, ref: sliderRef, style: styles.sliderContainer }, layout ? (react_1.default.createElement(BaseSlider_1.BaseSlider, Object.assign({}, props, { value: value !== undefined ? value : minimumValue, layout: layout }))) : null),
        helperText ? (react_1.default.createElement(react_native_1.View, { style: styles.helperTextRow, testID: "slider-helper-text" },
            react_1.default.createElement(components_1.Caption, { style: styles.helperText }, helperText))) : null));
};
exports.Slider = Slider;
exports.Slider.defaultProps = BaseSlider_1.SliderDefaultProps;
exports.Slider.displayName = 'Slider';
exports.MemoizedSlider = (0, react_1.memo)(exports.Slider);
