"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSchemaTransformerType = void 0;
var DataSchemaTransformerType;
(function (DataSchemaTransformerType) {
    DataSchemaTransformerType["REGEX"] = "REGEX";
    DataSchemaTransformerType["FUNCTION"] = "FUNCTION";
    DataSchemaTransformerType["TEMPLATE"] = "TEMPLATE";
    DataSchemaTransformerType["RULE"] = "RULE";
})(DataSchemaTransformerType = exports.DataSchemaTransformerType || (exports.DataSchemaTransformerType = {}));
