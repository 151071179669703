"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityListEmptyState = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const EntityListEmptyState = (props) => {
    const { __ } = (0, core_1.useIntl)();
    const { push, route } = (0, core_1.useNavigation)();
    const params = route.params;
    const goToEntityInstall = (0, react_1.useCallback)(() => push('ProductsApp', Object.assign({}, params)), []);
    return (react_1.default.createElement(components_1.ComponentState, Object.assign({ title: __('No entities'), imageProps: { resizeMode: 'contain' }, description: __('Start by installing a new entity'), imageSource: "EntityListEmptyImage", actionTitle: __('Install a new Entity'), actionOnPress: goToEntityInstall }, props)));
};
exports.EntityListEmptyState = EntityListEmptyState;
exports.EntityListEmptyState.displayName = 'EntityListEmptyState';
