"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkingSettingItem = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const LinkingSettingItem = (_a) => {
    var { url } = _a, rest = tslib_1.__rest(_a, ["url"]);
    const [canOpen, setCanOpen] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        let cancelled = false;
        (async () => {
            const result = await react_native_1.Linking.canOpenURL(url);
            if (!cancelled) {
                setCanOpen(result);
            }
        })();
        return () => {
            cancelled = true;
        };
    });
    const onPress = canOpen ? () => react_native_1.Linking.openURL(url) : undefined;
    return react_1.default.createElement(components_1.List.Item, Object.assign({ onPress: onPress, disabled: !canOpen || !!rest.disabled }, rest));
};
exports.LinkingSettingItem = LinkingSettingItem;
