"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BOOLEAN = void 0;
exports.BOOLEAN = {
    transform: (value, _schema) => {
        if (value === undefined || value === null) {
            return value;
        }
        if (value === true ||
            (typeof value === 'string' && value.toLowerCase() === 'true') ||
            value === '1' ||
            value === 1) {
            return true;
        }
        if (value === false ||
            (typeof value === 'string' && value.toLowerCase() === 'false') ||
            value === '0' ||
            value === 0) {
            return false;
        }
        // We return value as is, expecting it to fail in validation step
        return value;
    },
    convert: (value, _schema) => {
        return value;
    },
    validate: (value, schema) => {
        if (schema.values) {
            const exists = schema.values.find((v) => v.value === value);
            if (!exists) {
                return false;
            }
        }
        return typeof value === 'boolean';
    },
};
