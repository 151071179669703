"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDevice = void 0;
const react_1 = require("react");
const context_1 = require("../../context");
/**
 * Access device controller and state.
 * Needs to have DeviceProvider as a parent.
 */
const useDevice = () => {
    const ctx = (0, react_1.useContext)(context_1.DeviceContext);
    if (!ctx) {
        throw Error('Illegal use of useDevice hook. "useDevice" can only be used with DeviceProvider.');
    }
    return ctx;
};
exports.useDevice = useDevice;
