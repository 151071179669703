"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const circularGeometry_1 = require("./circularGeometry");
const defaultStyles = (theme, { thumbTintColor, thumbSize }) => ({
    root: Object.assign({ backgroundColor: thumbTintColor || theme.palette.background.card, borderRadius: thumbSize !== undefined ? thumbSize / 2 : theme.shape.borderRadius }, theme.elevation(5)),
});
const Thumb = (props) => {
    const { size, startAngle, endAngle, direction, axis, maximumValue, minimumValue, trackWidth, padding, value, thumbSize, thumbProps, thumbStyle, } = props;
    const styles = (0, core_1.useStyles)('SliderThumb', props, defaultStyles);
    const handle1Angle = (0, react_1.useMemo)(() => (0, circularGeometry_1.valueToAngle)({
        endAngle,
        maximumValue,
        minimumValue,
        startAngle,
        value,
    }), [endAngle, maximumValue, minimumValue, startAngle, value]);
    const { x, y } = (0, react_1.useMemo)(() => (0, circularGeometry_1.angleToPosition)({
        axis,
        degree: handle1Angle,
        direction,
        radius: (0, circularGeometry_1.innerRadius)({ size, trackWidth, padding }),
        svgSize: size,
    }), [axis, handle1Angle, direction, size, trackWidth, padding, size]);
    return (react_1.default.createElement(react_native_1.TouchableWithoutFeedback, null,
        react_1.default.createElement(react_native_1.View, Object.assign({}, thumbProps, { style: [
                {
                    // left: x !== undefined ? x - thumbSize! / 2 : undefined,
                    // top: y !== undefined ? y - thumbSize! / 2 : undefined,
                    left: x - thumbSize / 2,
                    top: y - thumbSize / 2,
                    height: thumbSize,
                    position: 'absolute',
                    width: thumbSize,
                },
                styles.root,
                thumbProps ? thumbProps.style : {},
                thumbStyle,
            ] }))));
};
exports.default = (0, react_1.memo)(Thumb);
Thumb.displayName = 'Thumb';
