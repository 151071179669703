"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductUploadAvatarMutation = void 0;
const client_1 = require("@apollo/client");
exports.ProductUploadAvatarMutation = client_1.gql `
	mutation ProductUploadAvatarMutation($file: Upload!, $productId: ID!, $avatarSize: Int) {
		product {
			uploadAvatar(file: $file, id: $productId) {
				id
				avatar(width: $avatarSize, height: $avatarSize)
				thumbnail: avatar(width: 5, height: 5)
			}
		}
	}
`;
