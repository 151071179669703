"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataTypeHelpers = exports.DataTypeKeys = void 0;
const boolean_1 = require("./boolean");
const date_1 = require("./date");
const number_1 = require("./number");
const string_1 = require("./string");
exports.DataTypeKeys = ['BOOLEAN', 'DATE', 'NUMBER', 'STRING'];
exports.DataTypeHelpers = {
    BOOLEAN: boolean_1.BOOLEAN,
    DATE: date_1.DATE,
    NUMBER: number_1.NUMBER,
    STRING: string_1.STRING,
};
