"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LevelSelectorWithStepper = void 0;
const tslib_1 = require("tslib");
const react_1 = tslib_1.__importStar(require("react"));
const LevelSelector_1 = require("../LevelSelector/LevelSelector");
const LevelSelectorWithStepper = (props) => {
    const { onValueChange, value, levels } = props;
    const isDecrementDisabled = value <= 0;
    const isIncrementDisabled = value + 1 > levels;
    const increment = () => {
        onValueChange(value + 1);
    };
    const decrement = () => {
        onValueChange(value - 1);
    };
    const filterItems = (0, react_1.useCallback)(({ items }) => {
        return [
            ...items,
            {
                type: 'group',
                horizontal: true,
                items: [
                    {
                        // color: 'blue',
                        icon: 'chevron-down',
                        iconSize: 24,
                        roundTopLeft: false,
                        onPress: decrement,
                        disabled: isDecrementDisabled,
                        testID: 'decrement',
                    },
                    {
                        // color: 'blue',
                        icon: 'chevron-up',
                        iconSize: 24,
                        roundTopRight: false,
                        onPress: increment,
                        disabled: isIncrementDisabled,
                        testID: 'increment',
                    },
                ],
            },
        ];
    }, []);
    return react_1.default.createElement(LevelSelector_1.LevelSelector, Object.assign({}, props, { filterItems: filterItems }));
};
exports.LevelSelectorWithStepper = LevelSelectorWithStepper;
exports.LevelSelectorWithStepper.defaultProps = {
    levels: 4,
    onColor: '#52D938',
    offColor: '#919EAB',
};
