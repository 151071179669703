"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSchemaConverterType = void 0;
var DataSchemaConverterType;
(function (DataSchemaConverterType) {
    DataSchemaConverterType["REGEX"] = "REGEX";
    DataSchemaConverterType["FUNCTION"] = "FUNCTION";
    DataSchemaConverterType["TEMPLATE"] = "TEMPLATE";
    DataSchemaConverterType["RULE"] = "RULE";
})(DataSchemaConverterType = exports.DataSchemaConverterType || (exports.DataSchemaConverterType = {}));
