"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const react_native_1 = require("react-native");
const defaultStyles = (theme, { maximumTrackTintColor }) => ({
    root: {
        backgroundColor: maximumTrackTintColor || theme.palette.primary.main,
        opacity: 0.24,
    },
});
const Track = (0, react_1.memo)((props) => {
    const { maximumTrackTintColor, vertical, width, height, trackWidth, trackProps, trackStyle, onPress, } = props;
    const styles = (0, core_1.useStyles)('SliderTrack', props, defaultStyles);
    return (react_1.default.createElement(react_native_1.TouchableWithoutFeedback, { onPressIn: onPress },
        react_1.default.createElement(components_1.View, Object.assign({}, trackProps, { style: [
                {
                    backgroundColor: maximumTrackTintColor,
                    borderRadius: height && height / 2,
                    height: !vertical ? trackWidth : height,
                    width: !vertical ? width : trackWidth,
                },
                styles.root,
                trackStyle,
            ] }))));
});
Track.displayName = 'Track';
exports.default = Track;
