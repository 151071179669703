"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OffStateView = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const defaultStyles = (theme) => ({
    offImage: {
        resizeMode: 'contain',
        height: theme.spacing.unit * 23,
    },
});
const OffStateView = (props) => {
    const { on, onStateChange } = props;
    const styles = (0, core_1.useStyles)('OffstateView', props, defaultStyles);
    const { __ } = (0, core_1.useIntl)();
    const turnOn = (0, react_1.useCallback)(() => onStateChange({ [on.key]: true }), [on]);
    return (react_1.default.createElement(components_1.ComponentState
    // title={__('Device is Off')}
    , Object.assign({ 
        // title={__('Device is Off')}
        description: __('Device is switched off'), 
        // imageSource="Device_off"
        styles: { image: styles.offImage }, actionTitle: __('Switch On'), actionProps: { icon: { name: 'power', type: 'icon' }, color: 'success', variant: 'outlined' } }, props, { actionOnPress: turnOn })));
};
exports.OffStateView = OffStateView;
exports.OffStateView.displayName = 'OffStateView';
