"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const version_1 = require("./version");
const components_1 = require("./components");
const core_1 = require("@bluebase/core");
tslib_1.__exportStar(require("./components/exports"), exports);
exports.default = (0, core_1.createPlugin)({
    description: 'Add rn-placeholder in BlueBase apps!',
    key: '@bluebase/plugin-rn-placeholder',
    name: 'rn-placeholder',
    version: version_1.VERSION,
    components: components_1.components,
});
