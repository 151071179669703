"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiLevelSelector = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const react_1 = tslib_1.__importStar(require("react"));
const Breadcrumb_1 = require("./Breadcrumb");
const ListItem_1 = require("./ListItem");
const LoadingState_1 = require("./LoadingState");
const SlidingPane_1 = require("./SlidingPane");
const MultiLevelSelector = (props) => {
    const { value, getItems, style } = props;
    const styles = (0, core_1.useStyles)('MultiLevelSelector', props, {
        root: {},
        scrollView: {},
        scrollViewContentContainer: {},
    });
    // //////////
    // States //
    // //////////
    const [loading, setLoading] = (0, react_1.useState)(true);
    const [error, setError] = (0, react_1.useState)();
    const [selected, setSelected] = (0, react_1.useState)();
    // New
    const [data, setData] = (0, react_1.useState)({
        node: undefined,
        ancestors: [],
        children: [],
    });
    const { node, ancestors, children } = data;
    // ////////////////////////////
    // Sliding Pane Transitions //
    // ////////////////////////////
    /**
     * Refs for the sliding pane
     */
    const slidingPane = (0, react_1.useRef)(null);
    // /**
    //  * Slide In pane from left
    //  */
    //  function slideFromLeft() {
    // 	if (slidingPane.current === null) {
    // 		return;
    // 	}
    // 	slidingPane.current.warpLeft(slidingPane.current.slideCenter, -20);
    // }
    // /**
    //  * Slide In pane from right
    //  */
    // function slideFromRight() {
    // 	if (slidingPane.current === null) {
    // 		return;
    // 	}
    // 	slidingPane.current.warpRight(slidingPane.current.slideCenter, 20);
    // }
    // //////////////
    // Load Items //
    // //////////////
    const loadItems = (0, react_1.useCallback)((id, done) => {
        if (getItems) {
            setLoading(true);
            getItems === null || getItems === void 0 ? void 0 : getItems(id, done);
        }
    }, [getItems]);
    // ////////////////////////
    // Manipulate Selection //
    // ////////////////////////
    /**
     * Goes to a parent slide, removes are it's children from selection
     * @param index
     */
    const goToParentValue = (0, react_1.useCallback)((parentId) => {
        var _a;
        const parent = ancestors.find((item) => item.id === parentId);
        const currentNode = node && parentId === (node === null || node === void 0 ? void 0 : node.id) && node;
        let selected;
        if (parent) {
            selected = parent;
        }
        else if (currentNode) {
            selected = currentNode;
        }
        setSelected(selected);
        // Fire onChangeValue callback to finish selection process
        try {
            (_a = props.onChangeValue) === null || _a === void 0 ? void 0 : _a.call(props, selected);
        }
        catch (e) {
            console.warn(e);
        }
        loadItems(parentId, afterLoad);
        // slideFromLeft();
    }, [ancestors, loadItems, props.onChangeValue]);
    // ///////////////////
    // Event Listeners //
    // ///////////////////
    /**
     * Callback when an item is selected
     * @param value
     */
    const onChangeValue = (0, react_1.useCallback)((value) => () => {
        setSelected(value);
        // Fire onChangeValue callback to finish selection process
        if (props.onChangeValue) {
            props.onChangeValue(value);
        }
        if (value.hasChildren) {
            loadItems(value.id, afterLoad);
            // slideFromRight();
        }
    }, [props.onChangeValue]);
    // ////////////////
    // Load Children //
    // ////////////////
    const afterLoad = (items, error, ignoreSelection) => {
        setLoading(false);
        if (error) {
            setError(error);
            return;
        }
        if (!items) {
            setData({
                node: undefined,
                ancestors: [],
                children: [],
            });
            return;
        }
        setData(items);
        if (items.node && !ignoreSelection) {
            setSelected(items.node);
        }
    };
    (0, react_1.useEffect)(() => {
        loadItems(value, (items, error) => {
            afterLoad(items, error);
            // If selected value has no children, then we want to render
            // the siblings of the selected value
            if (!error && (items === null || items === void 0 ? void 0 : items.node) && !items.node.hasChildren) {
                loadItems(items.node.parentId, (items, error) => afterLoad(items, error, true));
            }
        });
    }, [value]);
    if (error) {
        return react_1.default.createElement(components_1.ErrorState, { error: error });
    }
    return (react_1.default.createElement(components_1.View, { style: [styles.root, style] },
        react_1.default.createElement(components_1.View, null,
            react_1.default.createElement(Breadcrumb_1.MultiLevelSelectorBreadcrumb, { node: node, ancestors: ancestors, onItemPress: goToParentValue })),
        react_1.default.createElement(components_1.Divider, null),
        react_1.default.createElement(components_1.ScrollView, { style: styles.scrollView, contentContainerStyle: styles.scrollViewContentContainer },
            react_1.default.createElement(SlidingPane_1.SlidingPane, { ref: slidingPane },
                react_1.default.createElement(components_1.StatefulComponent, { loading: loading, error: error, data: children, loadingComponent: LoadingState_1.MultiLevelSelectorLoadingState }, children.map((item, index) => (react_1.default.createElement(react_1.default.Fragment, { key: item.id },
                    react_1.default.createElement(ListItem_1.MultiLevelSelectorListItem, Object.assign({ key: `${item.id}-${item.id === (selected === null || selected === void 0 ? void 0 : selected.id)}`, selected: item.id === (selected === null || selected === void 0 ? void 0 : selected.id) }, item, { onPress: onChangeValue(item) })),
                    index !== children.length - 1 && react_1.default.createElement(components_1.Divider, null)))))))));
};
exports.MultiLevelSelector = MultiLevelSelector;
const defaultProps = {};
exports.MultiLevelSelector.defaultProps = defaultProps;
exports.MultiLevelSelector.displayName = 'MultiLevelSelector';
