"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityCard = void 0;
const tslib_1 = require("tslib");
const components_1 = require("@bluebase/components");
const core_1 = require("@bluebase/core");
const client_graphql_1 = require("@mevris/client-graphql");
const react_1 = tslib_1.__importStar(require("react"));
const helpers_1 = require("../../helpers");
const imports_1 = require("../../imports");
const defaultStyles = (theme) => ({
    root: {},
    avatarContainer: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        overflow: 'hidden',
    },
    avatar: {
        backgroundColor: theme.palette.background.dark,
    },
    content: {
        padding: theme.spacing.unit,
    },
    primaryRow: {
        alignItems: 'center',
        flexDirection: 'row',
    },
    secondaryRow: {
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: theme.spacing.unit,
    },
    name: {
        flex: 1,
    },
    statusIcon: {
        paddingLeft: theme.spacing.unit,
    },
    // lastActivityTime: {
    // 	alignItems: 'center',
    // 	flex: 1,
    // 	flexDirection: 'row',
    // },
    // lastActivityTimeIcon: {
    // 	color: theme.palette.text.disabled,
    // 	paddingRight: theme.spacing.unit / 2,
    // },
    // lastActivityTimeText: {
    // 	color: theme.palette.text.disabled,
    // },
    // importantState: {
    // 	color: theme.palette.text.disabled,
    // 	paddingLeft: theme.spacing.unit,
    // },
});
/**
 * EntityCard used for listing of available entities.
 * Is shows name of entities with StatusIcon, last activity time and important state.
 */
const EntityCard = (props) => {
    var _a;
    const { id, avatar, category, thumbnail, link, name, stateMap, width, loading, onPress, } = props;
    if (loading === true) {
        return react_1.default.createElement(imports_1.PlaceholderCard, { width: width });
    }
    const { push } = (0, core_1.useNavigation)();
    const styles = (0, core_1.useStyles)('EntityCard', props, defaultStyles);
    const online = stateMap === null || stateMap === void 0 ? void 0 : stateMap.online;
    // Calculate status
    const status = online === true ? 'green' : 'red';
    const placeholder = (_a = props.placeholder) !== null && _a !== void 0 ? _a : (0, helpers_1.getEntityPlaceholder)(category);
    return (react_1.default.createElement(components_1.Card, { style: Object.assign(Object.assign({}, styles.root), { width }), onPress: link === true
            ? (0, react_1.useCallback)(() => push('EntityProfile', { entityId: id }), [])
            : onPress },
        react_1.default.createElement(components_1.View, { style: styles.avatarContainer },
            react_1.default.createElement(imports_1.ProgressiveImage, { placeholder: placeholder, thumbnail: thumbnail, source: avatar, style: Object.assign(Object.assign({}, styles.avatar), { height: width, width }) })),
        react_1.default.createElement(components_1.View, { style: styles.content },
            react_1.default.createElement(components_1.View, { style: styles.primaryRow },
                react_1.default.createElement(components_1.Subtitle1, { testID: "entitycard-name-text", style: styles.name, ellipsizeMode: "tail", numberOfLines: 1 }, name ? name : 'Untitled'),
                category === client_graphql_1.DeviceTypeCategory.Thing ? (react_1.default.createElement(components_1.View, { testID: "entitycard-status-icon", style: styles.statusIcon },
                    react_1.default.createElement(imports_1.StatusIcon, { color: status }))) : null))));
};
exports.EntityCard = EntityCard;
/**
 *  default props of EntityCard
 * if no props are given then
 * EntityCard will render with
 * these props
 */
exports.EntityCard.defaultProps = {
    link: true,
    width: 150,
};
exports.EntityCard.displayName = 'EntityCard';
