"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Date = void 0;
const types_1 = require("../types");
exports.Date = {
    key: 'Date',
    dataType: 'DATE',
    // Mon Jul 08 2019 11:45:40 GMT+0500 (Pakistan Standard Time) => 08/07/2019
    printer: {
        type: types_1.DataSchemaPrinterType.TEMPLATE,
        value: '{{ new Date(value).toLocaleDateString() }}',
    },
};
