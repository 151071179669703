"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusIcon = exports.RelativeTime = exports.ProgressiveImage = exports.PlaceholderListItem = exports.PlaceholderCard = exports.PlaceholderCaption = exports.Placeholder = exports.MultiLevelSelector = exports.GraphqlListCarousel = void 0;
const components_1 = require("@bluebase/components");
exports.GraphqlListCarousel = (0, components_1.getComponentWithFallback)({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'GraphqlListCarousel',
})('GraphqlListCarousel');
exports.MultiLevelSelector = (0, components_1.getComponentWithFallback)({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'MultiLevelSelector',
})('MultiLevelSelector');
exports.Placeholder = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'Placeholder',
})('Placeholder');
exports.Placeholder.displayName = 'Placeholder';
exports.PlaceholderCaption = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderCaption',
})('PlaceholderCaption');
exports.PlaceholderCaption.displayName = 'PlaceholderCaption';
exports.PlaceholderCard = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderCard',
})('PlaceholderCard', 'LoadingState');
exports.PlaceholderCard.displayName = 'PlaceholderCard';
exports.PlaceholderListItem = (0, components_1.getComponentWithFallback)({
    moduleName: '@bluebase/plugin-rn-placeholder',
    name: 'PlaceholderListItem',
})('PlaceholderListItem');
exports.PlaceholderListItem.displayName = 'PlaceholderListItem';
exports.ProgressiveImage = (0, components_1.getComponentWithFallback)({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'ProgressiveImage',
})('ProgressiveImage');
exports.RelativeTime = (0, components_1.getComponentWithFallback)({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'RelativeTime',
})('RelativeTime');
exports.StatusIcon = (0, components_1.getComponentWithFallback)({
    moduleName: '@blueeast/client-plugin-ui',
    name: 'StatusIcon',
})('StatusIcon');
