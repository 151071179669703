"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFormField = void 0;
const DefaultInputs = ['FormTextInput', 'TextInput', 'Noop'];
const DefaultButtons = ['Button', 'Noop'];
const FieldResolutionMap = {
    array: ['FormArrayField', 'Noop'],
    checkbox: ['FormCheckboxInput', 'Noop'],
    color: ['FormColorInput', ...DefaultInputs],
    component: ['JsonLayout', 'Noop'],
    date: ['FormDateInput', ...DefaultInputs],
    email: ['FormEmailInput', ...DefaultInputs],
    inline: ['InlineFields', 'Noop'],
    'inline-fields': ['InlineFields', 'Noop'],
    group: ['FormGroupField', ...DefaultInputs],
    number: ['FormNumberInput', ...DefaultInputs],
    password: ['FormPasswordInput', ...DefaultInputs],
    picker: ['FormPickerInput', 'Picker', 'Noop'],
    'radio-group': ['FormRadioGroupInput', 'Noop'],
    range: ['FormRangeInput', ...DefaultInputs],
    reset: ['FormResetButton', ...DefaultButtons],
    search: ['FormSearchInput', ...DefaultInputs],
    'simple-array': ['FormSimpleArrayField', ...DefaultInputs],
    status: ['FormStatus'],
    submit: ['FormSubmitButton', ...DefaultButtons],
    switch: ['FormSwitchInput', 'FormCheckboxInput', 'Noop'],
    tel: ['FormTelInput', ...DefaultInputs],
    time: ['FormTimeInput', ...DefaultInputs],
    url: ['FormUrlInput', ...DefaultInputs],
};
const getFormField = (type, map) => {
    const finalMap = Object.assign(Object.assign({}, FieldResolutionMap), map);
    return type && finalMap[type] ? finalMap[type] : DefaultInputs;
};
exports.getFormField = getFormField;
