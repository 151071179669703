"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.distributeItemsInRows = void 0;
const MAX_ITEMS_IN_A_ROW = 3;
function distributeItemsInRows(items) {
    const rows = Math.ceil(items.length / MAX_ITEMS_IN_A_ROW);
    let tempItems = [...items].filter((x) => !!x);
    const result = [];
    // Run loop for number of rows
    for (let index = 0; index < rows; index++) {
        result.push(tempItems.slice(0, 3));
        tempItems = tempItems.slice(3);
    }
    // Adjust last row
    const lastRowIndex = result.length - 1;
    const secondLastRowIndex = lastRowIndex - 1;
    if (result.length > 1 && result[lastRowIndex].length === 1) {
        const itemToMove = result[secondLastRowIndex][MAX_ITEMS_IN_A_ROW - 1];
        result[secondLastRowIndex] = result[secondLastRowIndex].slice(0, MAX_ITEMS_IN_A_ROW - 1);
        result[lastRowIndex] = [itemToMove, ...result[lastRowIndex]];
    }
    return result;
}
exports.distributeItemsInRows = distributeItemsInRows;
